import React from "react";
// import Video1 from "../../assets-new/client-videos/video-1.mp4";
// import { FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
// import { GrNext, GrPrevious } from "react-icons/gr";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import jmcTestimonial from "../../assets-new/images/homepage/jmc-testimonial.jpeg";
import HapagLLoyd from "../../assets-new/images/home-client-carousel/client-section-logo-5.png";
import VersionX from "../../assets-new/images/home-client-carousel/version-x.png";
import Img1 from "../../assets-new/images/homepage/Entropik.png";
import Img2 from "../../assets-new/images/homepage/Mega-Pipes.png";
import Img3 from "../../assets-new/images/homepage/Symbo.png";
import Img4 from "../../assets-new/images/homepage/tata-testimonial.jpg";
import Img5 from "../../assets-new/images/homepage/coats-testimonial.jpg";
import Img6 from "../../assets-new/images/homepage/bpep-testimonial.jpg";
import Img7 from "../../assets-new/images/homepage/cms-testimonial.jpeg";
import Img8 from "../../assets-new/images/homepage/lorel-testimonial.jpeg";

const Section6 = () => {
  // const SwiperButtonNext = ({ children }) => {
  //   const swiper = useSwiper();
  //   return <button onClick={() => swiper.slideNext()}>{children}</button>;
  // };

  // const SwiperButtonPrev = ({ children }) => {
  //   const swiper = useSwiper();
  //   return <button onClick={() => swiper.slidePrev()}>{children}</button>;
  // };
  const testimonialsObj = [
    {
      brandInfo: "Ishita",
      brandinfo2: "HR Manager",
      img: Img1,
      text: "I absolutely loved the team's attitude towards the work assigned. All of them were very cordial and helpful throughout and most importantly, receptive towards the feedback shared. As a point for improvement, I feel the ideation can be better. The suggestions for enhancement were slightly lacking. While the video was a good show, I still feel the photoshoot experience was entirely vice-versa. Nonetheless, keep up the good work, team!",
    },
    {
      brandInfo: "Peenal",
      brandinfo2: "Marketing Manager",
      img: Img2,
      text: "Working with Orange Videos' entire crew was simply too wonderful. Everything was well planned, from the planning stages to the implementation. Each team executed its tasks flawlessly, and the team members' communication was excellent. They gave their finest ideas at every turn, and the result was a fantastic video showcasing our brand-new business. Keep up the wonderful job, folks!",
    },
    {
      brandInfo: "Kartik",
      brandinfo2: "Chief Growth Officer",
      img: Img3,
      text: "We recently collaborated with Orange Videos for a video for our company, Symbo and they did a fabulous job! Everything was conducted timely and in an orderly manner, right from the setup to the post-production. It was a great experience working with them as they helped us achieve our vision with their expertise. They made quite a few revisions to the script and the animation as per our feedback and still delivered the final film as per the deadline decided.",
    },
    {
      brandInfo: "Yannick Brewart ",
      brandinfo2: "Assistant Manager Marketing",
      img: Img4,
      text: "Great turnaround from Orange Videos, considering the stringent deadlines!",
    },
    {
      brandInfo: "Amrita Deb ",
      brandinfo2: "Marketing Manager",
      img: Img5,
      text: "Wanted to share the feedback on the Down Leakage solution video. It’s really got a lot of appreciation from Global teams. You guys have done a fantastic job!",
    },
    {
      brandInfo: "Siddhant Bhandhari ",
      brandinfo2: "Analyst",
      img: Img6,
      text: "Thank you Amey, Prateek and the entire Orange Videos team for conducting the shoot for us. I think everything was done properly. The costumes, the stylist, the makeup and everything was great! We hope to work with them again and for our portfolio companies as well.",
    },
    {
      brandInfo: "Madhava Sripada",
      brandinfo2: "Deputy Manager (Sales)",
      img: jmcTestimonial,
      text: "The team is professional, courteous, and easy to work with. Please feel free to give out my name as a reference, I will happily give you the highest recommendation. I look forward to working with you again soon.",
    },
    {
      brandInfo: "Rovina Adenwalla",
      brandinfo2: "Corporate Communications",
      img: Img7,
      text: "It was a dream working with Orange Videos. They always seemed to be three steps ahead of me, with patience, diligence and excellent punctuality. One of the best product videos that I have created ever, thanks to Orange Videos.",
    },
    {
      brandInfo: "Garima Sharma",
      brandinfo2: "Marketing Manager",
      img: Img8,
      text: "We were really under the gun with the minimal time we had. Orange Videos did a great job without balking at the timeline and we appreciate their efforts.",
    },
    {
      text: "All the three projects have been realized with great reliability and professionalism.",
      brandInfo: "Hanja Rishter, ",
      brandinfo2: "Corporate Communications Manager,",
      img: HapagLLoyd,
      brandinfo3: "Hapag LLoyd",
    },
    {
      text: "Thank you for all your hard work on the video shoot. Your attention to detail and dedication to making sure everything went smoothly did not go unnoticed. Thank you for keeping me informed throughout the process.",
      brandInfo: `Désirée Zenz,`,
      brandinfo2: "Marketing & Communication,",
      brandinfo3: "Social Media Manager",
    },
    {
      text: "I appreciate you being on your word and sending the first cut on time. The video looks great to me.",
      brandInfo: "Oshin,",
      img: VersionX,
      brandinfo2: "Version X",
      width: "60px",
    },
  ];
  return (
    <>
      {/* <div className="commonSection testimonial-component">
        <div
          className="container"
          style={{
            paddingBottom: "0 !important",
          }}
        >
          <p className="text-center p-sub-text">Client Testimonials</p>
          <h2 className="sec_title text-center">CLIENT SUCCESS STORIES</h2>
          <div className="testimonials-container">
            {testimonialsObj.map((data, ind) => (
              <div className="testmonial-content" key={ind}>
                <div className="testimonial-box">
                  <div
                  className="star-container"
                  style={{
                    fontSize: 20,
                  }}
                >
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                  <p className="testimonial-text">{data.text}</p>
                  <div className="testimonial-bottom-brand">
                    <p className="persons" style={{ color: "#000" }}>
                      <b>
                        <span>{data.brandInfo}</span>
                        <br />
                        {data.brandinfo2 && data.brandinfo2}
                        <br />
                        {data.brandinfo3 && data.brandinfo3}
                      </b>
                    </p>
                    <span className="testimonial-quote-icon">
                      <FaQuoteRight />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* just to test */}
      {/* <div className="commonSection testmonial-carousel-container-2">
        <div className="container">
          <div className="testmonial-carousel-2">
            <Swiper
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              loop={true}
            >
              <div className="testmonial-2-heading">
                <h2>Client Testimonials</h2>
                <div className="side-btns">
                  <SwiperButtonPrev>
                    <div className="btn-left">
                      <GrPrevious />
                    </div>
                  </SwiperButtonPrev>
                  <SwiperButtonNext>
                    <div className="btn-right">
                      <GrNext />
                    </div>
                  </SwiperButtonNext>
                </div>
              </div>
              {testimonialsObj.map((data, ind) => (
                <SwiperSlide key={ind}>
                  <div className="content">
                    <p>{data.text}</p>
                    <p className="testmonial-2-brand">
                      <b>{data.brandInfo}</b>
                      <br />
                      {data.brandinfo2}
                      <br />
                      {data.brandinfo3}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div> */}

      <div className="commonSection testimonial-3-container">
        <div className="container">
          <h2 className="sec_title text-center">Client Testimonials</h2>
          <div className="custom-testimonial-3">
            <div className="swiper-container">
              <Swiper
                navigation={true}
                modules={[Pagination, Navigation]}
                // loop={true}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
              >
                {/* <div className="btns">
                  <SwiperButtonPrev>
                    <div className="btn-left">
                      <GrPrevious />
                    </div>
                  </SwiperButtonPrev>
                  <SwiperButtonNext>
                    <div className="btn-right">
                      <GrNext />
                    </div>
                  </SwiperButtonNext>
                </div> */}
                {testimonialsObj.map((data, ind) => (
                  <SwiperSlide key={ind}>
                    <div className="content">
                      <div className="quote-top">
                        <FaQuoteLeft />
                      </div>
                      <p>{data.text}</p>
                      <div className="person-info">
                        {data.img ? (
                          <img
                            src={data.img}
                            alt=""
                            style={{
                              width: data.width && data.width,
                            }}
                          />
                        ) : (
                          <CiUser />
                        )}
                        <p className="testmonial-3-brand">
                          <b>{data.brandInfo}</b>
                          <br />
                          {data.brandinfo2}
                          <br />
                          {data.brandinfo3}
                        </p>
                      </div>
                      <div className="quote-bottom">
                        <FaQuoteRight />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="client-testimonial-videos"> */}
      {/* <iframe
          width="300"
          src="https://www.youtube.com/embed/MVncO-G4AM8?si=zFaVRIIZNXgLMC4A&amp?controls=0"
          title="YouTube Shorts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            aspectRatio: "9/16",
            marginRight: 20,
          }}
          />
          <iframe
          width="300"
          src="https://www.youtube.com/embed/XDSFM41JHRQ?si=r8apat0xaZma0vt3?controls=0"
          title="YouTube Shorts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            aspectRatio: "9/16",
            marginRight: 20,
          }}
          />
        <iframe
          width="300"
          src="https://www.youtube.com/embed/K_jO1DbyK3U?si=8RZZ2n36iT3KWOtp?controls=0"
          title="YouTube Shorts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            aspectRatio: "9/16",
            marginRight: 20,
          }}
        /> */}

      {/* <video
          src={Video1}
          width="300px"
          controls
          style={{
            aspectRatio: "9/16",
          }}
        /> */}
      {/* </div> */}
    </>
  );
};

export default Section6;
