import React, { useEffect, useState } from 'react'
// import { AppUseContext } from "../../AppContext";
import { SiGoogleforms } from "react-icons/si";
import { Link, useLocation } from 'react-router-dom';
import LogoImg from "../../assets-new/images/logo (1).svg";
import { AppUseContext } from '../AppContext';
import NavPopup from '../NavPopup';

const Nav = () => {
    const pathname = useLocation();
    const { pageErr, isLandingPageVisible, isEcommerceLpVisibleChangeColor, isPodcastPageVisible } =
        AppUseContext();
    const [checkLandingPage, setCheckLandingPage] = useState(false);
    const [changeScrollClassName, setChangeScrollClassName] = useState("");
    const [landingScrollClassName, setLandingScrollClassName] = useState("");
    const [scrollPopupFormCondition, setScrollPopupFormCondition] = useState(false)

    // const [checkPathName, setCheckPathName] = useState("");
    useEffect(() => {
        const winScroll = () => {
            if (document.documentElement.scrollTop > 10) {
                setChangeScrollClassName("fixedHeader animated flipInX");
            } else {
                setChangeScrollClassName("");
            }

            if (document.documentElement.scrollTop > 300) {
                setLandingScrollClassName("opaque");
            } else {
                setLandingScrollClassName("");
            }

            if (document.documentElement.scrollTop > 400) {
                setScrollPopupFormCondition(true)
                // console.log(scrollPopupFormCondition)
            } else {
                setScrollPopupFormCondition(false)
            }
        };
        window.addEventListener("scroll", winScroll);
        // eslint-disable-next-line
    }, []);
    const [showPopup, setShowPopup] = useState(false);

    const handlePopup = () => {
        setShowPopup(!showPopup);

        document.body.style.overflow = !showPopup ? "hidden" : "scroll";
    };

    const mobileMediaQuery = window.matchMedia("(max-width: 800px)");

    const [isMenuActive, setIsMenuActive] = useState(false);

    const lpMenuVariiant = {
        open: { height: "100%", opacity: 1, y: 0 },
        close: { height: "0", opacity: 0, y: -1000 },
    };

    const [podcastPopupForm, setPodcastPopupForm] = useState(false)
    const handlePodcastPopupForm = (e) => {
        e.preventDefault()
        setPodcastPopupForm(!podcastPopupForm)
        document.body.style.overflow = !podcastPopupForm ? "hidden" : "auto"
    }

    return (
        <>

            <header
                className={`header_01 ${changeScrollClassName} ${checkLandingPage && "landing-page-active"} ${pathname.pathname === "/thank-you" && "dk-bg"
                    }`}
                // style={{
                //   boxShadow: changeScrollClassName === "" ? "0px 1px #dadada" : "unset",
                // }}
                id="header"
            >
                <div className="container">
                    <div className="row">
                        <div className="mobile-nav tablet-view">
                            <div className="col-lg-2 col-sm-3 col-md-2 left-logo-container">
                                <Link to="/" className="logo-link">
                                    <div className="logo">
                                        <img src={LogoImg} alt="" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div
                            className="col-lg-10 col-sm-7 col-md-10"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <nav className="mainmenu text-center">
                                <ul>
                                    {/* <!--<li className="menu-item-has-children">-->
                                        <!--    <a href="./">home</a>-->
                                            <!--<ul className="sub-menu">
                                        <!--        <li><a href="index.html">Home 01</a></li>-->
                                        <!--        <li><a href="index2.html">Home 02</a></li>-->
                                        <!--        <li><a href="index3.html">Home 03</a></li>-->
                                        <!--    </ul>-->
                                        <!--</li>--> */}
                                    {
                                        scrollPopupFormCondition
                                        &&
                                        <li
                                            style={{
                                                paddingRight: 2,
                                            }}
                                        >
                                            <a href="#get-in-touch" className="contact-btn for-podcast" onClick={handlePodcastPopupForm}>
                                                GET IN TOUCH
                                            </a>

                                        </li>
                                    }
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

            {
                mobileMediaQuery.matches
                &&
                <>
                    {
                        isPodcastPageVisible
                        &&
                        <>
                            {
                                scrollPopupFormCondition
                                &&
                                <a href="/" className="podcast-popup-mobile-form" onClick={handlePodcastPopupForm}>
                                    <SiGoogleforms />
                                </a>
                            }
                        </>
                    }
                </>
            }

            {/* menu on mobile dropdown */}
            <NavPopup clickFunc={handlePopup} showPopup={showPopup} handlePodcastPopupForm={handlePodcastPopupForm} podcastPopupForm={podcastPopupForm} />

        </>
    )
}

export default Nav