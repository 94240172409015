import React, { useEffect, useState } from "react";
// import HubspotContactForm from "../HubspotContactForm";
import HubspotTrackerScript from "../HubshopTrackerScript";
import { useLocation } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

const EnquiryForm = () => {
    const pathname = useLocation();

    const [formId, setFormId] = useState("")

    const [showPopupForm, setShowPopupForm] = useState(false)

    const handleFormPopup = (e) => {
        e.preventDefault()
        setShowPopupForm(!showPopupForm);
    }

    useEffect(() => {
        if (pathname.pathname === "/podcast-mumbai") {
            setFormId("a7cfea0d-54cc-492f-a32d-87d87055ffca")
        }
        else if (pathname.pathname === "/podcast-bangalore") {
            setFormId("b95f8e61-559b-43aa-a05c-5d87bac6d85d")
        }
    }, [pathname])

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/embed/v2.js";
        document.body.appendChild(script);

        // region: "na1",
        script.addEventListener("load", () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: "46497548",
                    formId: formId,
                    target: "#renderForm",
                });
            }
        });
        // eslint-disable-next-line
    }, [pathname, formId, showPopupForm]);

    return (
        <>
            {
                showPopupForm
                &&
                <div className="form-popup-container">
                    <div className="form-media">
                        {/* <!--begin register-form-wrapper--> */}
                        <div className="register-form-wrapper">
                            <div className="close" onClick={handleFormPopup}>
                                <IoMdClose />
                            </div>
                            {/* <h3 className="mb-2">
                Need a professional photoshoot for Ecommerce?
              </h3> */}

                            <p className="mb-4"><b>Get an estimate within 24 hours</b></p>

                            {/* <!--begin form--> */}
                            <div>
                                {/* <!--begin success message --> */}
                                <p
                                    className="register_success_box"
                                    style={{ display: "none" }}
                                >
                                    Just drop a message. We will get back within 24 hours.
                                </p>
                                {/* <!--end success message --> */}

                                {/* <!--begin register form --> */}

                                <div id="renderForm"></div>
                                {/* <!--end register form --> */}
                            </div>
                            {/* <!--end form--> */}
                        </div>
                        {/* <!--end register-form-wrapper--> */}
                    </div>
                </div>
            }

            <HubspotTrackerScript />
            <div className="commonSection podcast-enquire-form" id="get-in-touch">
                <div className="container">
                    <div>
                        <div>
                            <h3 className="sec_title text-center">We are experts in helping businesses create, launch and grow successful podcasts</h3>
                            <div className="dv-container">
                                <a href="/" className="guide-btn" onClick={handleFormPopup}>GET IN TOUCH</a>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center form-parent">
                            {/* <HubspotContactForm
                                portalId="46497548"
                                formId="c1e5f66f-f658-458b-8580-153c97f9e4be"
                                region="na1"
                            /> */}
                            {/* <div id="renderForm"></div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EnquiryForm;