import React from 'react'
import { RiRoadMapLine } from "react-icons/ri";
import img1 from "../../assets-new/images/podcast-page-assets/uat-imgs/why-podcasting/why-pod-img-1.svg"
import img2 from "../../assets-new/images/podcast-page-assets/uat-imgs/why-podcasting/why-pod-img-2.svg"
import img3 from "../../assets-new/images/podcast-page-assets/uat-imgs/why-podcasting/why-pod-img-3.svg"
import img4 from "../../assets-new/images/podcast-page-assets/uat-imgs/why-podcasting/why-pod-img-4.svg"
import WhyPodContent from './WhyPodContent';

const WhyPodcastingNew = () => {
    return (
        <div className='commonSection container why-podcast-new'>
            <div className='text-center'>
                <h2 className='sec_title'>Why Podcasting?</h2>
            </div>
            {/* <div className="content row">
                <div className="col-lg-6 col-sm-12 text-center">
                    <div className="inner-content">
                        <h4>Brand Awareness</h4>
                        <div className="icon">
                            <img src={img1} alt="" />
                        </div>
                        <p>
                            Podcasting enhances brand recognition by sharing valuable insights, expert interviews, and thought leadership. It positions businesses as industry leaders, building trust and credibility while fostering lasting audience connections.
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 text-center">
                    <div className="inner-content">
                        <h4>Targeted Marketing</h4>
                        <div className="icon">
                            <img src={img2} alt="" />
                        </div>
                        <p>
                            Podcasts attract niche audiences, delivering personalized messages aligned with their interests. Businesses can boost marketing impact through focused content, sponsored segments, and collaborations with relevant influencers.
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 text-center">
                    <div className="inner-content">
                        <h4>Community Building</h4>
                        <div className="icon">
                            <img src={img3} alt="" />
                        </div>
                        <p>
                            Podcasts create interactive communities through discussions and feedback. Consistent content helps brands foster loyalty, turning listeners into advocates and building meaningful relationships over time.
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 text-center">
                    <div className="inner-content">
                        <h4>Business Connections</h4>
                        <div className="icon">
                            <img src={img4} alt="" />
                        </div>
                        <p>
                            Podcasts offer networking opportunities with industry leaders and partners. Featuring guests and sharing stories enhances content and strengthens professional networks, opening doors for future collaborations and business growth.
                        </p>
                    </div>
                </div>
            </div> */}

            <WhyPodContent />
        </div>
    )
}

export default WhyPodcastingNew