import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const FormThankyouPage = () => {
  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="commonSection">
      <div
        className="text-center container"
        style={{
          paddingTop: 100,
          //   paddingBottom: 100,
        }}
      >
        <h2 className="sec_title">Thank you</h2>
        <p>YOUR SUBMISSION IS RECEIVED AND WE WILL CONTACT YOU SOON.</p>
        <Link
          to="/"
          style={{
            color: "#000",
          }}
        >
          <FaArrowLeft /> Back to Home
        </Link>
      </div>
    </div>
  );
};

export default FormThankyouPage;
