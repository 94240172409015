import React, { useEffect } from "react";
import Banner from "./Banner";
import MetaData from "../MetaData";
import WeWillTakeUpSection from "./WeWillTakeUpSection";
// import VisualsSection from "./VisualsSection";
import MoreOnOurApproach from "./MoreOnOurApproach";
import EnquiryForm from "./EnquiryForm";
import { useLocation } from "react-router-dom";
import { AppUseContext } from "../AppContext";
// import LpFooter from "../LandingPages/LpFooter";
import HubspotTrackerScript from "../HubshopTrackerScript";
import WhatsAppImg from "../../assets-new/images/whatsapp.png";
import Section3 from "../Home/Section3";
import WhyChooseOv from "./WhyChooseOv";
import Faqs from "./Faqs";
import CaseStudies from "./CaseStudies";
import PodcastFooter from "./PodcastFooter";
import Testimonial from "./Testimonial";
import Nav from "../Podcast-UAT/Nav";

const Podcast = () => {
    const pathname = useLocation()
    const { setDisableFooter, setIsPodcastPageVisible } = AppUseContext()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        setDisableFooter(true)
        setIsPodcastPageVisible(true)
        // eslint-disable-next-line
    }, [pathname.pathname])

    return (
        <>
            <div className="podcast-old">
                <Nav />
                <HubspotTrackerScript />
                <MetaData pageTitle="LAUNCH YOUR VIDEO PODCAST SERIES WITH ORANGE VIDEOS" />
                <Banner />
                <WeWillTakeUpSection />
                <Section3 isPodcast={true} />
                <CaseStudies />
                <EnquiryForm />
                <MoreOnOurApproach />
                <WhyChooseOv />
                <Faqs />
                {/* <Section6 isPodTestimonial={true} /> */}
                <Testimonial />
                {/* <VisualsSection /> */}
                <PodcastFooter />
                <div className="footer lp-footer" style={{ paddingTop: 0 }}>
                    {/* <div className="container-fluid footer-bottom px-0 copy-right-lp">
                    <div className="row no-gutters mx-0">
                        <div className="col-md-12 text-center">
                            <p id="footer-text">
                            © Copyright 2024{" "}
                                <a
                                href="https://www.orangevideos.in/"
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    {" "}
                                    Orange Videos.
                                </a>{" "}
                                A part of 2020 Media Collective.
                            </p>
                        </div>
                    </div>
                </div> */}

                    {/* whatsapp floating img */}
                    <div className="floating_whatsapp whatsapp_landingPages podcast-page">
                        <a href={
                            // isEcommerceLandingPage ? "https://api.whatsapp.com/send/?phone=918356056796&text&type=phone_number&app_absent=0" :
                            "https://wa.me/919867409221"
                        } target="_blank" rel="noreferrer">
                            <img src={WhatsAppImg} className="whatsapp_img" alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Podcast;