import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppUseContext } from "./AppContext";

const HubspotContactForm = ({
  formId = "c1e5f66f-f658-458b-8580-153c97f9e4be",
}) => {

  const { hubspotScriptSrc } = AppUseContext()
  // const { region, portalId, formId } = props;
  const pathname = useLocation();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = hubspotScriptSrc;
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "46497548",
          formId: formId,
          target: "#hubspotForm",
        });
      }
    });
    // console.log(formId);
    // eslint-disable-next-line
  }, [formId, pathname]);

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotContactForm;
