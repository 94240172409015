import React, { useEffect } from "react";
import "./styles/importanceOfRacce.css";
import { useLocation } from "react-router-dom";
import MetaData from "../MetaData";

const HeartwarmingFathersDayBrandCampaigns = () => {
  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <MetaData pageTitle="Smartphone Filmmaking Hacks: Shoot Pro Quality Videos With Just Your Phone - Orange Videos" />
      {/* section 1 */}
      <section className="pageBanner insights-banner-blog HeartwarmingFathersDayBrandCampaigns">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content text-center ">
                {/* <!--<h4><a href="#">home</a> - Blog</h4>-->
                            <!--<h2>Benefits of using stock footage in videos</h2>--> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 2 */}
      <section className="commonSection blogPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="sec_title">
                5 Heartwarming Father’s Day Brand Campaigns
              </h1>
            </div>
          </div>
          <section id="blog-post">
            <article>
              <div className="article-body">
                <div className="post-content">
                  <p>
                    Dads often get overlooked on Father’s Day. Showing them love
                    is one of the most necessary things, and with their amazing
                    campaigns, these brands decided to do just that. From
                    heartwarming tributes to hilarious dad-ads, these videos
                    perfectly capture the bond between fathers and their
                    children.
                  </p>
                  <p>
                    In this blog, we will explore the creative routes brands
                    have taken to appreciate the amazing dads in our lives.
                  </p>

                  <h2>
                    <a
                      href="https://www.instagram.com/reel/C8PL1nZJP1t/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      Zomato - Who says dads don’t talk much?
                    </a>
                  </h2>

                  <p>
                    In an attempt to portray the dads’ silent love towards their
                    children, Zomato has created this gem of an ad for Father's
                    Day. The food delivery platform Zomato reminded us that dads
                    are walking megaphones for their kids' achievements. The
                    wholesome video features a kid proudly announcing his
                    victory to his dad, only to be met with a nonchalant ‘ok’.
                    But fear not. The video cuts to hilarious scenes of the dad
                    proudly informing everyone- from the barber to the grocery
                    store cashier about his son's chess prowess. It's a sweet
                    reminder that dads are our biggest cheerleaders, celebrating
                    our victories in their own unique, and often silent ways.
                  </p>
                  <p>
                    Dad took the phrase ‘actions speak louder than words’ quite
                    too literally.{" "}
                  </p>
                  <h2>
                    <a
                      href="https://www.instagram.com/reel/C8Q2O2LM3aK/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      Indigo India
                    </a>{" "}
                  </h2>
                  <p>
                    Indigo takes us on a touching flight with a pilot's message.
                    The video features First Officer Tejasvi Kalyan celebrating
                    all fathers on board but with a special shout-out to his own
                    hero- his dad. The video concludes with a heartwarming round
                    of applause for all fathers onboard.
                  </p>
                  <p>
                    Captain's announcement transforms into a touching tribute to
                    his dad.
                  </p>

                  <h2>
                    <a
                      href="https://www.youtube.com/watch?v=Ig62GkVJ3eM&pp=ygUXdGFuaXNocSBhdGhlcnMgZGF5IDIwMjQ%3D"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Tanishq - Fathers written by daughters
                    </a>{" "}
                  </h2>
                  <p>
                    Grab some tissues, because Tanishq got a little sentimental
                    with their ad, for all the right reasons. In a simple, yet
                    powerful gesture by the premium jewelry brand, Tanishq took
                    a heartwarming approach with a video showcasing the evolving
                    dynamic between daughters and their dads. We see snippets of
                    playful jabs about mom needing help, open conversations
                    about dates, and several other father-daughter moments until
                    she grows up.
                  </p>

                  <p>
                    But there's also a deeper message. Dads who once worried
                    about scraped knees now beam with pride at their daughters'
                    independence.{" "}
                  </p>
                  <p>A very simple gesture, yet so profound.</p>

                  <h2>
                    <a
                      href="https://www.instagram.com/reel/C8Q0dfFiECq/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      McDonalds - Like father, like kid.
                    </a>
                  </h2>
                  <p>
                    McDonald is reminding us this Father's Day that kids are the
                    carbon copies of their dads. Their adorable video features a
                    dad and kid mirroring each other bite-for-bite through a
                    McDonald's meal. It's a hilarious and heartwarming reminder
                    that we are our dad’s over-enthusiastic copycats.
                  </p>
                  <p>
                    Here’s the undeniable truth- dads are the ultimate cool
                    kids.{" "}
                  </p>

                  <h2>
                    <a
                      href="https://youtu.be/KaF590TDN4Y"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Amazon Prime - Shandaar Father’s Day
                    </a>
                  </h2>
                  <p>
                    This Father’s Day ad video by Amazon Prime strikes some
                    cords as the Bollywood singer- Shaan tunes in for some
                    Father’s Day melodies. The singer perfectly captures Dad’s
                    struggles, endless responsibilities, and the irresistible
                    pull of the couch after a long tiring day. It's a clever way
                    to acknowledge the realities of fatherhood while promoting
                    Amazon Prime as the perfect solution for catching up on
                    shows – even if those naps happen to be unplanned.
                  </p>
                  <p>
                    The video ends with the consoling copy- ‘No hard feelings
                    Dad, we know you’re tired’.
                  </p>
                  <h2>Conclusion </h2>
                  <p>
                    While some brands decided to go classic with Father's Day,
                    there are a few who stepped out of the line and brought
                    their creative heart out. It is evident that dads need some
                    appreciation, and these ads prove there are plenty of
                    creative ways to do it.
                  </p>
                  <p>Loved these campaign videos?</p>
                  <p>
                    Connect with Orange Videos and work with us for your next
                    special project.{" "}
                  </p>
                </div>

                <div id="blog-post-gallery"></div>
              </div>
              {/* <!-- /#blog-post-gallery --> */}
            </article>

            {/* <!-- /#blog-post -->
                <!-- /.col --> */}
          </section>
        </div>
        {/* <!-- /.row --> */}
      </section>
    </>
  );
};

export default HeartwarmingFathersDayBrandCampaigns;
