import { Link, useLocation } from "react-router-dom";
import "./styles/4dsOfVideoProduction.css";
import React, { useEffect } from "react";

const FourDsOfVideoProduction = () => {
  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      {/* section 1 */}
      <section className="pageBanner insights-banner-blog FourDsOfVideoProduction">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content text-center ">
                {/* <!--<h4><a href="#">home</a> - Blog</h4>-->
                            <!--<h2>Benefits of using stock footage in videos</h2>--> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 2 */}
      <section className="commonSection blogPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="sec_title">4Ds of Video Production</h1>
            </div>
          </div>
          <section id="blog-post">
            <article>
              <div className="article-body">
                <div className="post-content">
                  <p>
                    Video production can be tedious and cumbersome at times. The
                    mantra of 4 Ds of
                    <Link to="/professional-corporate-film-video-production-agency-mumbai">
                      {" "}
                      video production
                    </Link>{" "}
                    helps to steer clear of any ambiguity, confusion and
                    indecisiveness. Follow these steps to stay focused and go in
                    the right direction.
                  </p>

                  <p>
                    <strong>Discover:</strong>
                  </p>

                  <p>
                    {" "}
                    This is the first and the most crucial step to begin work on
                    a video. Behind every video requirement there are some goals
                    and objectives. It is imperative to understand them as all
                    ideation and conceptualization begin from here. Give enough
                    time to brainstorming sessions: you might want to consider
                    everyone’s suggestions and ideas important. So let ideas
                    keep pouring in as you never know which one clicks!
                  </p>
                  <p>
                    P.S.: Ensure to keep the discussion on track by sharing
                    relevant ideas and concepts!
                  </p>

                  <p>
                    <strong>Define:</strong>
                  </p>
                  <p>
                    {" "}
                    Defining the{" "}
                    <Link href="https://www.orangevideos.in/video-strategy">
                      video strategy
                    </Link>{" "}
                    is the next step. Here, consider various factors like the
                    video objective, length, budget, and timeline to decide the
                    best suited style of video. Once you freeze on that, outline
                    the creation of the video plan. For every process, put a
                    timeline in place (never forget to add buffer time).{" "}
                  </p>

                  <p>
                    <strong>Develop:</strong>
                  </p>
                  <p>
                    This is a work in progress step. It’s now time to gather all
                    the footage, graphics and other elements to create a
                    seamless video. This stage is a little more time consuming
                    and requires more patience than ever. Here, work on the
                    video till an impressive first cut is ready for delivery.
                    Your clients may request for minor edits, so be ready and
                    share the best possible version as the final output.
                  </p>

                  <p>
                    <strong>Deliver:</strong>
                  </p>
                  <p>
                    {" "}
                    The final output travels across all social media platforms
                    and even the clients’ company website. Feel proud as you see
                    your work going viral and being appreciated.{" "}
                  </p>
                </div>

                <div id="blog-post-gallery"></div>
              </div>
              {/* <!-- /#blog-post-gallery --> */}
            </article>

            {/* <!-- /#blog-post -->
                <!-- /.col --> */}
          </section>
        </div>
        {/* <!-- /.row --> */}
      </section>
    </>
  );
};

export default FourDsOfVideoProduction;
