// firebase link - https://orangevideos.web.app/
import "./assets/css/bootstrap.min.css";
import "./assets-new/css/font-awesome.min.css";
// import "./assets/css/main.css"
// import "./assets-new/css/grid.css"
import "./assets-new/css/preset.css";
import "./assets-new/css/animate.css";
import "./assets-new/css/theme_min.css";
// import "./assets-new/css/theme.css";
import "./assets-new/css/responsive.css";
// import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import AnimatedRoutes from "./components/AnimatedRoutes";
import "./App.css";
import { AppUseContext } from "./components/AppContext";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const { disableFooter } = AppUseContext()
  return (
    <HelmetProvider>
      <div className="App">
        {/* <BrowserRouter> */}
        {/* <AppContextProvider> */}
        <NavBar />
        <AnimatedRoutes />
        {
          !disableFooter
          &&
          <Footer />
        }
        {/* </AppContextProvider> */}
        {/* </BrowserRouter> */}
      </div>
    </HelmetProvider>
  );
}

export default App;
