import React from "react";
// import Video1 from "../../assets-new/client-videos/video-1.mp4";
// import { FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
// import { GrNext, GrPrevious } from "react-icons/gr";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import Img1 from "../../assets-new/images/podcast-page-assets/testimonials/logo-1.png";
import Img2 from "../../assets-new/images/podcast-page-assets/testimonials/logo-2.png";

const Testimonial = () => {
    // const SwiperButtonNext = ({ children }) => {
    //   const swiper = useSwiper();
    //   return <button onClick={() => swiper.slideNext()}>{children}</button>;
    // };

    // const SwiperButtonPrev = ({ children }) => {
    //   const swiper = useSwiper();
    //   return <button onClick={() => swiper.slidePrev()}>{children}</button>;
    // };
    const testimonialsObj = [
        {
            brandInfo: "Shubhangi Madan",
            brandinfo2: "Ennature",
            img: Img1,
            text: "Thank you so much for being so supportive throughout and closing the project smoothly. Ourteam really liked how project was executed.",
        },
        {
            brandInfo: "Nishant Shinde",
            brandinfo2: "Ansell India",
            img: Img2,
            text: "Thank you to the entire team of orange videos for executing the project flawlessly within deadlines. It is the dedication, expertise, and passion of teams like yours that make project a resounding success.",
        },
    ];
    return (
        <>
            {/* <div className="commonSection testimonial-component">
        <div
          className="container"
          style={{
            paddingBottom: "0 !important",
          }}
        >
          <p className="text-center p-sub-text">Client Testimonials</p>
          <h2 className="sec_title text-center">CLIENT SUCCESS STORIES</h2>
          <div className="testimonials-container">
            {testimonialsObj.map((data, ind) => (
              <div className="testmonial-content" key={ind}>
                <div className="testimonial-box">
                  <div
                  className="star-container"
                  style={{
                    fontSize: 20,
                  }}
                >
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                  <p className="testimonial-text">{data.text}</p>
                  <div className="testimonial-bottom-brand">
                    <p className="persons" style={{ color: "#000" }}>
                      <b>
                        <span>{data.brandInfo}</span>
                        <br />
                        {data.brandinfo2 && data.brandinfo2}
                        <br />
                        {data.brandinfo3 && data.brandinfo3}
                      </b>
                    </p>
                    <span className="testimonial-quote-icon">
                      <FaQuoteRight />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

            {/* just to test */}
            {/* <div className="commonSection testmonial-carousel-container-2">
        <div className="container">
          <div className="testmonial-carousel-2">
            <Swiper
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              loop={true}
            >
              <div className="testmonial-2-heading">
                <h2>Client Testimonials</h2>
                <div className="side-btns">
                  <SwiperButtonPrev>
                    <div className="btn-left">
                      <GrPrevious />
                    </div>
                  </SwiperButtonPrev>
                  <SwiperButtonNext>
                    <div className="btn-right">
                      <GrNext />
                    </div>
                  </SwiperButtonNext>
                </div>
              </div>
              {testimonialsObj.map((data, ind) => (
                <SwiperSlide key={ind}>
                  <div className="content">
                    <p>{data.text}</p>
                    <p className="testmonial-2-brand">
                      <b>{data.brandInfo}</b>
                      <br />
                      {data.brandinfo2}
                      <br />
                      {data.brandinfo3}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div> */}

            <div className="commonSection testimonial-3-container">
                <div className="container">
                    <h2 className="sec_title text-center">Client Testimonials</h2>
                    <div className="custom-testimonial-3">
                        <div className="swiper-container">
                            <Swiper
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                // loop={true}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                            >
                                {/* <div className="btns">
                  <SwiperButtonPrev>
                    <div className="btn-left">
                      <GrPrevious />
                    </div>
                  </SwiperButtonPrev>
                  <SwiperButtonNext>
                    <div className="btn-right">
                      <GrNext />
                    </div>
                  </SwiperButtonNext>
                </div> */}
                                {testimonialsObj.map((data, ind) => (
                                    <SwiperSlide key={ind}>
                                        <div className="content">
                                            <div className="quote-top">
                                                <FaQuoteLeft />
                                            </div>
                                            <p>{data.text}</p>
                                            <div className="person-info">
                                                {data.img ? (
                                                    <img
                                                        src={data.img}
                                                        alt=""
                                                        style={{
                                                            width: data.width && data.width,
                                                        }}
                                                    />
                                                ) : (
                                                    <CiUser />
                                                )}
                                                <p className="testmonial-3-brand">
                                                    <b>{data.brandInfo}</b>
                                                    <br />
                                                    {data.brandinfo2}
                                                    <br />
                                                    {data.brandinfo3}
                                                </p>
                                            </div>
                                            <div className="quote-bottom">
                                                <FaQuoteRight />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="client-testimonial-videos"> */}
            {/* <iframe
          width="300"
          src="https://www.youtube.com/embed/MVncO-G4AM8?si=zFaVRIIZNXgLMC4A&amp?controls=0"
          title="YouTube Shorts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            aspectRatio: "9/16",
            marginRight: 20,
          }}
          />
          <iframe
          width="300"
          src="https://www.youtube.com/embed/XDSFM41JHRQ?si=r8apat0xaZma0vt3?controls=0"
          title="YouTube Shorts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            aspectRatio: "9/16",
            marginRight: 20,
          }}
          />
        <iframe
          width="300"
          src="https://www.youtube.com/embed/K_jO1DbyK3U?si=8RZZ2n36iT3KWOtp?controls=0"
          title="YouTube Shorts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            aspectRatio: "9/16",
            marginRight: 20,
          }}
        /> */}

            {/* <video
          src={Video1}
          width="300px"
          controls
          style={{
            aspectRatio: "9/16",
          }}
        /> */}
            {/* </div> */}
        </>
    );
};

export default Testimonial;
