// import { useEffect, useState } from "react";
import Slider from "react-slick";
import teamImg1 from "../../assets-new/images/about/team/prateek_profile.jpg";
import teamImg2 from "../../assets-new/images/about/team/shreyashi.jpg";
// import teamImg3 from "../../assets-new/images/about/team/pradu_profile.jpg";
// import teamImg4 from "../../assets-new/images/about/team/akash_profile.jpg";
import teamImg5 from "../../assets-new/images/about/team/rachana_profile1.jpg";
import teamImg6 from "../../assets-new/images/about/team/amey_profile.jpg";
import { AppUseContext } from "../AppContext";

const AboutTeams = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const [showPopup, setShowPopup] = useState(false);

  // const handleVideoPopup = () => {
  //   setShowPopup(showPopup ? false : true);
  // };

  // const [changeFrameSrc, setChangeFrameSrc] = useState("");

  // const handleChangeFrameVideo = ({ data }) => {
  //   setShowPopup(!showPopup);
  //   // setChangeFrameSrc(`${data.target.href}`);
  //   console.log(data.target.href);
  // };

  const { toggleVideoPopup } = AppUseContext();

  // useEffect(() => {
  //   document.body.style = showPopup ? "overflow: hidden" : "overflow: auto";
  // }, [showPopup]);
  return (
    <>
      {/* <div className="team_slider slick-initialized slick-slider slick-dotted"> */}
      <div className="slick-list draggable" style={{ padding: "0px 40px" }}>
        <Slider {...settings}>
          <div className="singleTM">
            <div className="tm_img singlefolio mb-0">
              <img src={teamImg1} alt="" />
            </div>
            {/* <a
                className="popup-youtube video_popup video_popup_custom"
                href="https://www.youtube.com/watch?v=dyDzTRbokA0"
                tabindex="-1"
              >
                <i className="fa fa-play"></i>
              </a> */}
            <div className="detail_TM">
              <h5>Prateek Tewari</h5>
              <h6>Production Lead</h6>
            </div>
          </div>

          <div className="singleTM">
            <div className="tm_img singlefolio mb-0">
              <img src={teamImg2} alt="" />
            </div>
            {/* <a
                className="popup-youtube video_popup video_popup_custom"
                href="https://www.youtube.com/watch?v=QAVWyUy0jF0"
                tabindex="-1"
              >
                <i className="fa fa-play"></i>
              </a> */}

            <div className="detail_TM">
              <h5>Shreyashi Debnath</h5>
              <h6>Art Director</h6>
            </div>
          </div>

          <div className="singleTM">
            <div className="tm_img singlefolio mb-0">
              <img src={teamImg5} alt="" />
              <a
                className="popup-youtube video_popup video_popup_custom"
                href="https://www.youtube.com/watch?v=QAVWyUy0jF0"
                onClick={(e) => {
                  // handleChangeFrameVideo({ data: e });
                  // e.preventDefault();
                  // setChangeFrameSrc(
                  //   "https://www.youtube.com/embed/QAVWyUy0jF0?si=gCCpGiIgJB3YFhA8"
                  // );

                  toggleVideoPopup({
                    videoSrc:
                      "https://www.youtube.com/embed/QAVWyUy0jF0?si=gCCpGiIgJB3YFhA8",
                    param: e,
                  });
                }}
              >
                <i className="fa fa-play"></i>
              </a>
            </div>
            <div className="detail_TM">
              <h5>Rachna Dadlani</h5>
              <h6>Content Head</h6>
            </div>
          </div>

          <div className="singleTM">
            <div className="tm_img singlefolio mb-0">
              <img src={teamImg6} alt="" />
              <a
                className="popup-youtube video_popup video_popup_custom"
                href="https://www.youtube.com/watch?v=GalyeiTohOI"
                onClick={(e) => {
                  // handleChangeFrameVideo({ data: e });
                  // e.preventDefault();
                  // setChangeFrameSrc(
                  //   "https://www.youtube.com/embed/GalyeiTohOI?si=6sXOxuTGpflgdJCU"
                  // );

                  toggleVideoPopup({
                    videoSrc:
                      "https://www.youtube.com/embed/GalyeiTohOI?si=6sXOxuTGpflgdJCU",
                    param: e,
                  });
                }}
              >
                <i className="fa fa-play"></i>
              </a>
            </div>
            <div className="detail_TM">
              <h5>Amey Asuti</h5>
              <h6>Founder</h6>
            </div>
          </div>

          {/* <div className="singleTM">
            <div className="tm_img singlefolio mb-0">
              <img
                alt=""
              />
            </div>
            <div className="detail_TM">
              <h5>Abhishek Chaturvedi</h5>
              <h6>Video Editor</h6>
            </div>
          </div> */}
        </Slider>
      </div>
      {/* </div> */}

      {/* {showPopup ? (
        <div className="popup-vid-cotainer">
          <a href="https://www.youtube.com/watch?v=JGV05ck7U0g"></a>
          <div className="dv-popup-vid">
            <iframe
              width="800"
              height="500"
              src={changeFrameSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <div className="dv-close">
              <i
                className="fa-solid fa-xmark close-ico"
                onClick={handleVideoPopup}
              ></i>
            </div>
          </div>
        </div>
      ) : null} */}
    </>
  );
};

export default AboutTeams;
