import React, { useState } from "react";
import Icon1 from "../../assets-new/images/icons/video-styles/live-shoot-1-v1.svg";
import Icon2 from "../../assets-new/images/icons/video-styles/aerial-videography-2-v1.svg";
import Icon3 from "../../assets-new/images/icons/video-styles/2d-3d-animation-3-v1.svg";
import Icon4 from "../../assets-new/images/icons/video-styles/montage-video-using-stock-image-4-v1.svg";
// import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
// import { Pagination } from "swiper/modules";
import { GrNext, GrPrevious } from "react-icons/gr";
// import animateGif from "../../assets-new/images/TVCOMMERCIALS.gif";
import RightImg from "../../assets-new/images/homepage/video-strategy-2.png";
import corporate from "../../assets-new/images/gif/video-categories/corporate.gif";
import testimonial from "../../assets-new/images/gif/video-categories/testimonial.gif";
import product from "../../assets-new/images/gif/video-categories/product.gif";
import explainer from "../../assets-new/images/gif/video-categories/explainer.gif";
import digitalADFilm from "../../assets-new/images/gif/video-categories/digital-ad-flim.gif";
import podcast from "../../assets-new/images/gif/video-categories/podcast.gif";
import staticCoporateImg from "../../assets-new/images/video-strategy/corporate-v1.jpg";
import staticTestimonialImg from "../../assets-new/images/video-strategy/new/testimonial.jpg";
import staticPodcastImg from "../../assets-new/images/video-strategy/podcast.jpg";
import staticExplainerImg from "../../assets-new/images/video-strategy/explainer.jpg";
import staticDigitalAdFilmImg from "../../assets-new/images/video-strategy/new/digital-ad-film.jpg";
import staticProductImg from "../../assets-new/images/video-strategy/new/product.jpg";
import vidCorporate from "../../assets-new/videos/video-categories/compressed/corporate.mp4";
import vidTestimonial from "../../assets-new/videos/video-categories/compressed/testimonial-video.mp4";
import vidPodcast from "../../assets-new/videos/video-categories/compressed/podcast.mp4";
import vidProduct from "../../assets-new/videos/video-categories/compressed/product.mp4";
import vidExplainer from "../../assets-new/videos/video-categories/compressed/explainer.mp4";
import vidDigitalAdFilm from "../../assets-new/videos/video-categories/compressed/digital-ad-film.mp4";
import { sanitize } from "dompurify";
import Slider from "react-slick";
import { isIOS, isMacOs } from "react-device-detect";

const Section4 = () => {
  const videoCategoryCarousel = [
    {
      imgSrc: staticCoporateImg,
      heading: "Corporate videos",
      text: "Whether it's showcasing cutting-edge technology on the factory floor or conveying trust and reliability in the financial sector, our corporate videos seamlessly blend creativity and professionalism to promote your brand's message and identity.",
      hoverGif: corporate,
      className: "hover-img-1",
      hoverVideo:
        isMacOs || isIOS
          ? "https://orangevideos.in/static/media/corporate.5601e4b6d930c3c6835a.mp4"
          : vidCorporate,
    },
    {
      imgSrc: staticTestimonialImg,
      heading: "Testimonial Videos / Interview- style videos",
      text: "We excel in interview-style videos by capturing an interesting mix of up close shots and B-rolls that enhance storytelling and viewer engagement. We leverage dual-camera setups to capture the speaker from more than one angle. This breaks the monotony while putting speakers at ease.",
      hoverGif: testimonial,
      className: "hover-img-2",
      hoverVideo:
        isMacOs || isIOS
          ? "https://orangevideos.in/static/media/testimonial-video.8983e56dafd02cc6fc35.mp4"
          : vidTestimonial,
    },
    {
      imgSrc: staticPodcastImg,
      heading: "Video Podcasts",
      text: "At our agency, we shoot video podcasts. From content planning , branding of the podcast to post-production, distribution, promotion, we do it all. We bring your podcast vision to life with flair and finesse.",
      hoverGif: podcast,
      className: "hover-img-3",
      hoverVideo:
        isMacOs || isIOS
          ? "https://orangevideos.in/static/media/podcast.97289d4132805f1933b2.mp4"
          : vidPodcast,
    },
    {
      imgSrc: staticProductImg,
      heading: "Product Videos",
      text: "Be it your company website, social media, E-commerce platforms, whatsapp / email marketing or events / exhibitions, these videos are the showstoppers. Our team of experts are well equipped to create as many versions as you would like to suit the requirement of each of the platforms to showcase your product.",
      hoverGif: product,
      className: "hover-img-4",
      hoverVideo:
        isMacOs || isIOS
          ? "https://orangevideos.in/static/media/product.9aca7168fc25e3eebf80.mp4"
          : vidProduct,
    },
    {
      imgSrc: staticExplainerImg,
      heading: "Explainer Videos",
      text: "Explainer videos are most sought after videos to explain a new concept, service or product in a clear and compelling way. We opt for live shoot or animation or mixed media to explain novel ideas to the audience. We help you with everything- scripting, storyboarding, design.You just name it!",
      hoverGif: explainer,
      className: "hover-img-5",
      hoverVideo:
        isMacOs || isIOS
          ? "https://orangevideos.in/static/media/explainer.2202aa91dbed8ac4de65.mp4"
          : vidExplainer,
    },
    {
      imgSrc: staticDigitalAdFilmImg,
      heading: "Digital Ad Films",
      text: "Digital ad films are short, captivating videos specifically crafted for online advertising campaigns. These films often incorporate storytelling, animation, or special effects to deliver memorable messages and drive engagement.",
      hoverGif: digitalADFilm,
      className: "hover-img-6",
      hoverVideo:
        isMacOs || isIOS
          ? "https://orangevideos.in/static/media/digital-ad-film.64bcf0eb48538c30de34.mp4"
          : vidDigitalAdFilm,
    },
    // {
    //   imgSrc: staticPromoVideosImg,
    //   heading: "Promo Videos",
    //   text: "A promo video is a short, attention-grabbing video designed to promote a product, service, event, or brand. Promo videos created by Orange Videos generate interest and attract the attention of the audience.",
    //   hoverGif: promoVideos,
    //   className: "hover-img-7",
    //   hoverVideo: promoVideos,
    // },
  ];

  const videoConfig = {
    ALLOWED_TAGS: ["video", "source"],
    ALLOWED_ATTRS: {
      video: ["autoplay", "loop", "muted", "poster"],
      source: ["src", "type"],
    },
  };

  const CategorySlideContent = ({
    imgSrc,
    heading,
    text,
    changeGif,
    videoOnHover,
  }) => {
    const [readBtn, setReadBtn] = useState(false);
    const [changeHoverImg, setChangeHoverImg] = useState(false);
    return (
      <div
        className="category-box"
        onMouseEnter={() => setChangeHoverImg(true)}
        onMouseLeave={() => setChangeHoverImg(false)}
      >
        {changeHoverImg ? (
          <div
            className="vid-continer-onhover"
            dangerouslySetInnerHTML={{
              __html: sanitize(`
          <video autoplay loop muted poster=${imgSrc}>
            <source src=${videoOnHover} type="video/mp4" />
          </video>
          `),
              videoConfig,
            }}
          ></div>
        ) : (
          <img src={imgSrc} alt="" />
        )}
        <div className="category-content">
          <h3>{heading}</h3>
          <p>
            {readBtn ? text : `${text.substring(0, 50)}...`}
            <br />
            <span
              onClick={() => setReadBtn(!readBtn)}
              className="read-more-btn"
            >
              {readBtn ? "Read less" : "Read more"}
            </span>
          </p>
        </div>
      </div>
    );
  };

  function NextArrowBtn(props) {
    const { onClick } = props;
    return (
      <div
        className="nxt-btn"
        // style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      >
        <GrNext />
      </div>
    );
  }

  function PrevArrowBtn(props) {
    const { onClick } = props;
    return (
      <div className="pre-btn" onClick={onClick}>
        <GrPrevious />
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrowBtn />,
    prevArrow: <PrevArrowBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section
        className="commonSection ready"
        // initial="hide"
        // whileInView="view"
        // viewport={{ once: true }}
        // variants={{
        //   view: { opacity: 1, y: 0 },
        //   hide: { opacity: 0, y: 100 },
        // }}
        // transition={{ duration: 1 }}
      >
        <div className="container">
          <div className="text-center">
            {/* <div className="col-lg-8 col-sm-8 col-md-8"> */}
            <p
              className="sub_title red_color text-center"
              style={{ marginBottom: "30px" }}
            >
              We are your one-stop agency for all things video
            </p>
            <h2 className="sec_title white">DISCOVER OUR EXPERTISE</h2>
            {/* <p></p>
            <h3 className="white">Your questions answered.</h3> */}
            {/* </div> */}
            {/* <div className="col-lg-4 col-sm-4 col-md-4 text-right">
              <a
                className="common_btn bg-white-button"
                href="https://www.orangevideos.in/credentials-deck.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <span>DOWNLOAD CREDENTIALS DECK</span>
              </a>
            </div> */}
          </div>
        </div>
      </section>
      <div className="commonSection expertise-section-container">
        <div
          className="container"
          // style={{
          //   paddingRight: 0,
          // }}
        >
          {/* <h2 className="sec_title text-center">DISCOVER OUR EXPERTISE</h2> */}
          <h3
            style={
              {
                // marginTop: 20,
                // marginBottom: 15,
              }
            }
            className="sec_title text-center"
          >
            VIDEO STRATEGY
          </h3>
          <p
            className="psub_heading sec_desc text-center"
            style={
              {
                // marginBottom: 40,
              }
            }
          >
            Create videos that truly resonate with your target audience
          </p>
          <div className="expertise-content row align-items-center d-flex">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p>
                Our video strategy is your roadmap to success. A good video
                strategy is about delivering clear messaging aligned with client
                goals, while resonating with the audience. Through compelling
                storytelling, visual appeal, and audience understanding, we
                evoke powerful connections that drive engagement and action.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img src={RightImg} alt="" width="100%" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="commonSection expertise-section-container"
        style={{
          backgroundColor: "#f4f4f4",
        }}
      >
        <div className="container">
          <h3 className="sec_title text-center">VIDEO STYLES</h3>
          <p
            className="sec_desc text-center"
            style={{
              marginBottom: 30,
            }}
          >
            Videos encompass a diverse range of styles and formats
          </p>
          <div className="video-styles-container">
            <div className="box-container">
              <div className="video-styles-content">
                <div className="icon">
                  <img src={Icon1} alt="Live Shoot" />
                </div>
                <div className="text-content">
                  <h5>Live Shoot</h5>
                  <p>
                    Our experienced and enthusiastic crew is ever ready to hit
                    the set Be it scorching heat or monsoon flooding, nothing
                    can deter them. Equipped with DSLR cameras and a range of
                    lenses our crew has expertise in shooting indoors, outdoors,
                    studio settings, events etc.
                  </p>
                </div>
              </div>
              <div className="video-styles-content">
                <div className="icon">
                  <img src={Icon2} alt="Aerial Videography" />
                </div>
                <div className="text-content">
                  <h5>Aerial Videography</h5>
                  <p>
                    Aerial videography allows you to capture stunning aerial
                    views of landscapes, cityscapes, and events. We have
                    deployed drone shooting for events, real estate, logistics
                    and many other manufacturing set ups across sectors.
                  </p>
                </div>
              </div>
            </div>
            <div className="box-container">
              <div className="video-styles-content">
                <div className="icon">
                  <img src={Icon3} alt="2D / 3D / 2.5 D Animation" />
                </div>
                <div className="text-content">
                  <h5>2D / 3D / 2.5D Animation</h5>
                  <p>
                    Here, our expertise lies in breaking down complex briefs
                    into easily consumable animated video. An animation video is
                    a digital masterpiece that brings computer-generated
                    graphics to life, telling stories and conveying messages
                    through captivating motion.
                  </p>
                </div>
              </div>
              <div className="video-styles-content">
                <div className="icon">
                  <img src={Icon4} alt="Live Shoot" />
                </div>
                <div className="text-content">
                  <h5>Montage Video using Stock Image / Footage</h5>
                  <p>
                    For those pressed for time or have budget constraints, stock
                    footage montage video is the answer to your problem.
                    Encashing on the multiple subscription access, our editors
                    craft engaging videos by piecing together relevant stock
                    footage. Each video is carefully curated, blending different
                    clips to tell compelling stories that capture attention.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="commonSection">
        <div className="container">
          <h2 className="sec_title text-center">VIDEO CATEGORIES</h2>
          <p
            className="sec_desc text-center"
            style={{
              marginBottom: 30,
            }}
          >
            Proven track record in crafting various video styles effectively
          </p>
          <div className="video-categories-container">
            <Slider {...settings}>
              {videoCategoryCarousel.map((data, ind) => (
                <div className="swiper-content" key={ind}>
                  <div>
                    {/* <div className="category-box">
                  <img src={data.imgSrc} alt="" />
                  <div className="category-content">
                    <h3>{data.heading}</h3>
                    <p>{data.text}</p>
                  </div>
                </div> */}
                    <CategorySlideContent
                      heading={data.heading}
                      text={data.text}
                      imgSrc={data.imgSrc}
                      changeGif={data.hoverGif}
                      videoOnHover={data.hoverVideo}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section4;
