import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const DigitalAdsContactForm = () => {
  const [contactUsFormId, setContactUsFormId] = useState(null);

  const pathname = useLocation();
  useEffect(() => {
    const scriptForContactUs = document.createElement("script");
    scriptForContactUs.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(scriptForContactUs);

    scriptForContactUs.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "46497548",
          formId: contactUsFormId,
          target: "#contact-us-form",
        });
      }
    });
  }, [contactUsFormId, pathname]);

  useEffect(() => {
    if (pathname.pathname === "/digital-ads-mumbai") {
      setContactUsFormId(
        process.env.REACT_APP_DIGITAL_ADS_MUMBAI_CONTACT_US_FORM_ENV
      );
    } else if (pathname.pathname === "/digital-ads-bangalore") {
      setContactUsFormId(
        process.env.REACT_APP_DIGITAL_ADS_BANGALORE_CONTACT_US_FORM_ENV
      );
    }
  }, [pathname]);

  return <div id="contact-us-form"></div>;
};

export default DigitalAdsContactForm;
