import React, { memo, useEffect, useState } from "react";
import "./styles/styles.scss";
import { useLocation } from "react-router-dom";
import icon1 from "../../assets-new/images/digital-ads-lp/what-we-do/pre-production.svg";
import icon2 from "../../assets-new/images/digital-ads-lp/what-we-do/production.svg";
import icon3 from "../../assets-new/images/digital-ads-lp/what-we-do/post-production.svg";
// import ScrollSpy from "react-ui-scrollspy";
import LpFooter from "./LpFooter";
import { AppUseContext } from "../AppContext";
import VideoPopup from "../VideoPopup";
import { FaPlay } from "react-icons/fa";
// import TopForm from "./TopForm";
// import Sideform from "./SideForm";
import MetaData from "../MetaData";
// import HubspotContactForm from "../HubspotContactForm";
import TestSideForm from "./TestSideForm";
import img1 from "../../assets-new/images/digital-ads-lp/our-portfolio/img-1.png";
import img2 from "../../assets-new/images/digital-ads-lp/our-portfolio/img-2.png";
import img3 from "../../assets-new/images/digital-ads-lp/our-portfolio/img-3.png";
import img4 from "../../assets-new/images/digital-ads-lp/our-portfolio/img-4.png";
import img5 from "../../assets-new/images/digital-ads-lp/our-portfolio/img-5.png";
import img6 from "../../assets-new/images/digital-ads-lp/our-portfolio/img-6.png";
import img7 from "../../assets-new/images/digital-ads-lp/our-portfolio/img-7.png";
import img8 from "../../assets-new/images/digital-ads-lp/our-portfolio/img-8.png";
import img9 from "../../assets-new/images/digital-ads-lp/our-portfolio/img-9.png";
// import { FaCirclePlay } from "react-icons/fa6";
import whyOvIcon1 from "../../assets-new/images/digital-ads-lp/why-ov/icon-1.svg";
import whyOvIcon2 from "../../assets-new/images/digital-ads-lp/why-ov/icon-2.svg";
import whyOvIcon3 from "../../assets-new/images/digital-ads-lp/why-ov/icon-3.svg";
import whyOvIcon4 from "../../assets-new/images/digital-ads-lp/why-ov/icon-4.svg";
import whyOvIcon5 from "../../assets-new/images/digital-ads-lp/why-ov/icon-5.svg";
import whyOvIcon6 from "../../assets-new/images/digital-ads-lp/why-ov/icon-6.svg";
import teamImg1 from "../../assets-new/images/digital-ads-lp/meet-our-team/team-1.png";
import teamImg2 from "../../assets-new/images/digital-ads-lp/meet-our-team/team-2.png";
import teamImg3 from "../../assets-new/images/digital-ads-lp/meet-our-team/team-3.png";
import portfolioVideo1 from "../../assets-new/images/digital-ads-lp/our-portfolio/videos/vid-1.mp4";
import portfolioVideo2 from "../../assets-new/images/digital-ads-lp/our-portfolio/videos/vid-2.mp4";
import portfolioVideo3 from "../../assets-new/images/digital-ads-lp/our-portfolio/videos/vid-3.mp4";
import portfolioVideo4 from "../../assets-new/images/digital-ads-lp/our-portfolio/videos/vid-4.mp4";
import portfolioVideo5 from "../../assets-new/images/digital-ads-lp/our-portfolio/videos/vid-5.mp4";
import portfolioVideo7 from "../../assets-new/images/digital-ads-lp/our-portfolio/videos/vid-7.mp4";
import portfolioVideo8 from "../../assets-new/images/digital-ads-lp/our-portfolio/videos/vid-8.mp4";
import portfolioVideo6 from "../../assets-new/images/digital-ads-lp/our-portfolio/videos/vid-6.mp4";
import DigitalAdsContactForm from "./DigitalAdsContactForm";

const DigitalAdsLandingPage = () => {
  const { setIsLandingPageVisible } = AppUseContext();
  const { toggleVideoPopup } = AppUseContext();

  const [hubspotFormId, setHubspotFormId] = useState("")

  const pathname = useLocation();

  useEffect(() => {
    if (pathname.pathname === "/digital-ads-bangalore") {
      setHubspotFormId(process.env.REACT_APP_DIGITAL_ADS_BANGALORE_FORM_BANNER_ENV)
    } else if (pathname.pathname === "/digital-ads-mumbai") {
      setHubspotFormId(process.env.REACT_APP_DIGITAL_ADS_MUMBAI_FORM_BANNER_ENV)
    }
  }, [pathname])

  // const navigate = useNavigate();

  // const [locationName, setLocationName] = useState("");

  // const [hubspotFormId, setHubspotFormId] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "46497548",
          formId: hubspotFormId,
          target: "#hubspotForm",
        });
      }
    });
  }, [pathname, hubspotFormId]);

  useEffect(() => {
    setIsLandingPageVisible(true);
    // eslint-disable-next-line
  }, []);

  const OurPortfolioVideos = memo(({
    currImg,
    vidSrc,
    decreaseHgUsingCss = false,
  }) => {
    const [changeHoverVid, setChangeHoverVid] = useState(false);
    return (
      <div
        onMouseEnter={() => setChangeHoverVid(true)}
        onMouseLeave={() => setChangeHoverVid(false)}
      >
        {changeHoverVid ? (
          <video
            src={vidSrc}
            muted
            autoPlay
            loop
            poster={currImg}
            preload="auto"
            className={decreaseHgUsingCss && "reduceVidHeight"}
          ></video>
        ) : (
          <img src={currImg} alt="portfolio videos" />
        )}
      </div>
    );
  });

  const WhyOvTextContent = ({ fstContent, rmSecContent }) => {
    const [rmContent, setRmContent] = useState(false);

    return (
      <div>
        <p>
          {rmContent ? rmSecContent : fstContent}
          <span onClick={() => setRmContent(!rmContent)}>
            {rmContent ? "read less" : "read more"}
          </span>
        </p>
      </div>
    );
  };
  return (
    <>
      <MetaData
        pageTitle="Ad Production Agency, Based in Mumbai / Bangalore"
        metaDdescription="Orange videos is a leading top video production company based in Mumbai. From corporate to explainer Videos, we create high class videos."
      />
      <TestSideForm hbsptFormId={hubspotFormId} />
      {/* <ScrollSpy> */}
      <header className="header">
        {/* <section className="top-navbar">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <div className="left-topbar d-flex align-items-center w-100 h-100 d-none d-lg-block">
                  <p>
                    Professional yet Affordable Ecommerce Photography Studio
                    in Mumbai | Bangalore
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 align-items-center">
                <div
                  className="right-topbar d-flex align-items-center justify-content-end w-100 h-100"
                  style={{
                    justifyContent: "flex-end",
                  }}
                >
                  <ul className="d-flex align-items-center h-100">
                    <li>
                      <a
                        href="mailto:sales@orangevideos.in"
                        className="hvr-icon-pop"
                      >
                        sales@orangevideos.in
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <!--begin navbar-fixed-top --> */}
        {/* <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="#">
            <img src="images/logo.png" />
            </a>
            <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                >
                <span className="navbar-toggler-icon">
                  <i className="fas fa-bars"></i>
                  </span>
                  </button>
                  </nav>
                  </div>
                </nav> */}
      </header>

      <section
        className="home-section landing-home-page digital-lp"
        id="home"
      >
        <div className="home-section-overlay"></div>

        {/* <!--begin container --> */}
        <div className="container digi-ads">
          {/* <!--begin row --> */}
          <div className="row d-flex align-items-center">
            {/* <!--begin col-md-5--> */}
            <div className="col-md-7 header-contain">
              <h1>Award - Winning Digital Ad Production Agency,</h1>
              <h3 className="mb-2">Based in Mumbai / Bangalore</h3>
              <hr />
              <p className="banner_para">
                From concept to editing, your reliable partner for all
                creative video content
              </p>
              {/* <!--<div className="singlefolio" style="width: 250px!important;">--> */}
              {/* <!--<input value="Watch Showreel" className="home-btn" type="submit" style="width:100%!important;">--> */}
              {/* <!--<a className="home-btn plus-btn">Watch Showreel</a>--> */}
              {/* <!--<a className="video_popup video_popup_custom " href="https://www.youtube.com/watch?v=JGV05ck7U0g" style="top:0%!important;width:100%!important;height:53px!important;"><input value="Watch Showreel" className="home-btn" type="submit" style="width:100%!important;"></a>--> */}
              <a
                className="show-btn mt-4 video_popup diff-lp-video-btn"
                href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                onClick={(e) => {
                  toggleVideoPopup({
                    videoSrc:
                      "https://www.youtube.com/embed/JGV05ck7U0g?si=CeF2FuVJbZ8s4yvc",
                    param: e,
                  });
                }}
              >
                {/* <i className="button-effect fas fa-play-circle"></i> */}
                <FaPlay />
                <span>Watch our showreel </span>
              </a>
              {/* <!--</div>--> */}
            </div>

            <div className="col-md-5 text-center form-media">
              {/* <!--begin register-form-wrapper--> */}
              <div className="register-form-wrapper form-container-parent">
                <h1 className="mb-2">Looking for a cool Digital Ad?</h1>

                <p className="mb-4">Fill in your details, now. </p>

                {/* <!--begin form--> */}
                <div>
                  {/* <!--begin success message --> */}
                  <p
                    className="register_success_box"
                    style={{ display: "none" }}
                  >
                    Just drop a message. We will get back within 24 hours.
                  </p>
                  {/* <!--end success message --> */}

                  {/* <!--begin register form --> */}

                  {/* <TopForm /> */}
                  {/* hubspot form */}
                  {/* <HubspotContactForm formId={hubspotFormId} /> */}
                  <div id="hubspotForm"></div>
                  {/* <!--end register form --> */}
                </div>
                {/* <!--end form--> */}
              </div>
              {/* <!--end register-form-wrapper--> */}
            </div>
            {/* <!--end col-md-5--> */}
          </div>
          {/* <!--end row --> */}
        </div>
        {/* <!--end container --> */}
      </section>

      <section
        className="section-white landing-page-hire-ov commonSection"
        id="why-hire-us"
      >
        {/* <!--begin container --> */}
        <div className="container">
          {/* <!--begin row --> */}
          <div className="row">
            {/* <!--begin col-md-12 --> */}
            <div className="col-md-12 text-center margin-bottom-30">
              <h2 className="sec_title">What We Do</h2>
              {/* <h4 className="sub_title red_color">many reasons</h4> */}

              <p className="sec_desc">
                Discover how our amazing team creates a Digital Ad Film.
              </p>
            </div>
            {/* <!--end col-md-12 --> */}
          </div>
          {/* <!--end row --> */}
        </div>
        {/* <!--end container --> */}

        {/* <!--begin services-wrapper --> */}
        <div className="services-wrapper">
          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-4 --> */}
              <div className="col-md-4">
                <div
                  className="main-services wow fadeIn"
                  data-wow-delay="0.25s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.25s",
                    animationName: "fadeIn",
                  }}
                >
                  {/* <!--<i className="fas fa-rocket"></i>--> */}
                  <img src={icon1} className="hire_icon" alt="" />

                  <h4>Pre-production</h4>

                  <p className="sec_desc">
                    This involves concept development, scriptwriting,
                    storyboarding, casting, location scouting, and scheduling.
                    It sets the foundation and prepares all elements required
                    for the production phase.
                  </p>
                </div>
              </div>
              {/* <!--end col-md-4 --> */}

              {/* <!--begin col-md-4 --> */}
              <div className="col-md-4">
                <div
                  className="main-services wow fadeIn"
                  data-wow-delay="0.50s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.5s",
                    animationName: "fadeIn",
                  }}
                >
                  {/* <!--<i className="fas fa-chart-line"></i>--> */}
                  <img src={icon2} className="hire_icon" alt="" />

                  <h4>Production </h4>

                  <p className="sec_desc">
                    This includes directing, shooting, lighting, and capturing
                    audio. It translates the storyboard and script into raw
                    visual and audio content.
                  </p>
                </div>
              </div>
              {/* <!--end col-md-4 --> */}

              {/* <!--begin col-md-4 --> */}
              <div className="col-md-4">
                <div
                  className="main-services wow fadeIn"
                  data-wow-delay="0.75s"
                >
                  {/* <!--<i className="fas fa-chart-pie"></i>--> */}
                  <img src={icon3} className="hire_icon" alt="" />

                  <h4>Post-production</h4>

                  <p className="sec_desc">
                    Editing phase where the captured footage is assembled,
                    refined, and enhanced. This includes video editing,sound
                    design, visual effects, color correction, and finalizing
                    the ad for distribution
                  </p>
                </div>
              </div>
              {/* <!--end col-md-4 --> */}
              {/* <!--end col-md-4 --> */}
            </div>
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}
        </div>
        {/* <!--end services-wrapper --> */}
      </section>

      <section className="commonSection gray-bg digi-ads-lp-our-portfolio">
        <div className="col-md-12 text-center margin-bottom-30">
          <h2 className="sec_title">Our Portfolio</h2>
          <p className="sec_desc">
            Dicover the latest projects accomplished for our clients.
          </p>
        </div>
        <div className="container img-boxes">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 boxes-container">
              <div className="inner-box">
                <OurPortfolioVideos currImg={img8} vidSrc={portfolioVideo8} />
                {/* <img src={img8} alt="our portfolio imgs" width="100%" />
                  <div className="box-play-container">
                  <a
                  className="show-btn mt-4 video_popup diff-lp-video-btn"
                  href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                  onClick={(e) => {
                    toggleVideoPopup({
                      videoSrc:
                      "https://www.youtube.com/embed/eUFim1Hg1zU?si=TgARAN0KE2hyP5nK",
                          param: e,
                          });
                      }}
                    >
                    <FaCirclePlay />
                    </a>
                    </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 boxes-container">
              <div className="inner-box">
                <OurPortfolioVideos currImg={img6} vidSrc={portfolioVideo6} />
                {/* <img src={img6} alt="our portfolio imgs" width="100%" />
                  <div className="box-play-container">
                  <a
                      className="show-btn mt-4 video_popup diff-lp-video-btn"
                      href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                      onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                          "https://www.youtube.com/embed/eUFim1Hg1zU?si=TgARAN0KE2hyP5nK",
                          param: e,
                          });
                          }}
                          >
                          <FaCirclePlay />
                          </a>
                          </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 boxes-container">
              <div className="inner-box">
                <OurPortfolioVideos
                  currImg={img9}
                  vidSrc="https://www.orangevideos.in/static/media/Bnk.6ed17b5818ddcb613b1a.mp4"
                  decreaseHgUsingCss={true}
                />
                {/* <img src={img9} alt="our portfolio imgs" width="100%" />
                  <div className="box-play-container">
                    <a
                      className="show-btn mt-4 video_popup diff-lp-video-btn"
                      href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                      onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                            "https://www.youtube.com/embed/eUFim1Hg1zU?si=TgARAN0KE2hyP5nK",
                          param: e,
                        });
                      }}
                    >
                      <FaCirclePlay />
                    </a>
                  </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 boxes-container">
              <div className="inner-box">
                <OurPortfolioVideos currImg={img2} vidSrc={portfolioVideo2} />
                {/* <img src={img2} alt="our portfolio imgs" width="100%" />
                  <div className="box-play-container">
                    <a
                      className="show-btn mt-4 video_popup diff-lp-video-btn"
                      href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                      onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                            "https://www.youtube.com/embed/tBoGPShzAt4?si=CgttqMai8l4Mki47",
                          param: e,
                        });
                      }}
                    >
                      <FaCirclePlay />
                    </a>
                  </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 boxes-container">
              <div className="inner-box">
                <OurPortfolioVideos currImg={img3} vidSrc={portfolioVideo3} />
                {/* <img src={img3} alt="our portfolio imgs" width="100%" />
                  <div className="box-play-container">
                  <a
                  className="show-btn mt-4 video_popup diff-lp-video-btn"
                  href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                  onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                          "https://www.youtube.com/embed/JMxudXIB2DM?si=vkmlYBt7Ebsar7V6",
                          param: e,
                          });
                          }}
                          >
                          <FaCirclePlay />
                          </a>
                          </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 boxes-container">
              <div className="inner-box">
                <OurPortfolioVideos currImg={img4} vidSrc={portfolioVideo4} />
                {/* <img src={img4} alt="our portfolio imgs" width="100%" />
                  <div className="box-play-container">
                  <a
                      className="show-btn mt-4 video_popup diff-lp-video-btn"
                      href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                      onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                            "https://www.youtube.com/embed/eUFim1Hg1zU?si=TgARAN0KE2hyP5nK",
                          param: e,
                        });
                      }}
                    >
                      <FaCirclePlay />
                    </a>
                    </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 boxes-container">
              <div className="inner-box">
                <OurPortfolioVideos currImg={img5} vidSrc={portfolioVideo5} />
                {/* <img src={img5} alt="our portfolio imgs" width="100%" />
                  <div className="box-play-container">
                    <a
                      className="show-btn mt-4 video_popup diff-lp-video-btn"
                      href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                      onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                            "https://www.youtube.com/embed/eUFim1Hg1zU?si=TgARAN0KE2hyP5nK",
                          param: e,
                        });
                      }}
                    >
                    <FaCirclePlay />
                    </a>
                    </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 boxes-container">
              <div className="inner-box">
                <OurPortfolioVideos currImg={img7} vidSrc={portfolioVideo7} />
                {/* <img src={img7} alt="our portfolio imgs" width="100%" />
                  <div className="box-play-container">
                    <a
                      className="show-btn mt-4 video_popup diff-lp-video-btn"
                      href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                      onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                            "https://www.youtube.com/embed/eUFim1Hg1zU?si=TgARAN0KE2hyP5nK",
                          param: e,
                        });
                      }}
                    >
                    <FaCirclePlay />
                    </a>
                    </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 boxes-container">
              <div className="inner-box">
                <OurPortfolioVideos currImg={img1} vidSrc={portfolioVideo1} />
                {/* <img src={img1} alt="our portfolio imgs" width="100%" /> */}
                {/* <div className="box-play-container">
                    <a
                    className="show-btn mt-4 video_popup diff-lp-video-btn"
                    href="https://www.youtube.com/watch?v=JGV05ck7U0g"
                    onClick={(e) => {
                        toggleVideoPopup({
                          videoSrc:
                          "https://www.youtube.com/embed/eUFim1Hg1zU?si=TgARAN0KE2hyP5nK",
                          param: e,
                          });
                          }}
                          >
                          <FaCirclePlay />
                          </a>
                          </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="countdown-section small-paddings digi-ads-lp-countdown">
        {/* <!--begin container --> */}
        <div className="container">
          <div className="col-md-12 text-center mb-5">
            <h2 className="sec_title">Fun Facts about our agency</h2>
            <br />
          </div>
          {/* <!--begin row --> */}
          <div className="row">
            {/* <!--begin col-md-12--> */}
            <div className="col-md-12 text-center">
              <div className="row d-flex justify-content-center">
                <div className="col-md-2 countdown-num">
                  <h3>12+</h3>
                  <p>Years Experience</p>
                </div>
                <div className="col-md-2 countdown-num">
                  <h3>750+</h3>
                  <p>Videos</p>
                </div>
                <div className="col-md-2 countdown-num">
                  <h3>500+</h3>
                  <p>happy clients</p>
                </div>
                <div className="col-md-2 countdown-num">
                  <h3>70+</h3>
                  <p>Creative Minds</p>
                </div>
                <div className="col-md-2 countdown-num">
                  <h3>12+</h3>
                  <p>countries & counting</p>
                </div>
              </div>
            </div>
            {/* <!--end col-md-12--> */}
          </div>
          {/* <!--end row --> */}
        </div>
        {/* <!--end container --> */}
      </section>

      <section className="commonSection">
        <div className="container">
          <div className="col-md-12 text-center mb-5">
            <h2 className="sec_title">Why Orange Videos?</h2>
            <p className="sec_desc">
              6 compelling reasons to partner with us
            </p>
          </div>
          <div className="row why-ov-container d-flex">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="why-ov-box">
                <div className="content-icon">
                  <img src={whyOvIcon1} alt="why orangevideos icons" />
                </div>
                <div className="content-text">
                  <h4>Direction </h4>
                  <WhyOvTextContent
                    fstContent="Directors have the vision to bring the story to life. "
                    rmSecContent="Directors have the vision to bring the story to life. They see it all in their heads
and know what they need to deliver for you. With so many personal on set at any
one time, the director is vital for maintaining order and creative focus throughout
a project. "
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="why-ov-box">
                <div className="content-icon">
                  <img src={whyOvIcon2} alt="why orangevideos icons" />
                </div>
                <div className="content-text">
                  <h4>Writing </h4>
                  <WhyOvTextContent
                    fstContent="Writing is more than just clever one-liners, our "
                    rmSecContent="Writing is more than just clever one-liners, our writers are storytellers for any
subject. They have written for our clients across segments such as tech / AI ,
Healthcare, Manufacturing, Real Estate, Banking & Insurance and many more.
They’re born communicators. "
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="why-ov-box">
                <div className="content-icon">
                  <img src={whyOvIcon3} alt="why orangevideos icons" />
                </div>
                <div className="content-text">
                  <h4>Cinematography</h4>
                  <WhyOvTextContent
                    fstContent="A love of light drives cinematographers to "
                    rmSecContent="A love of light drives cinematographers to make sure every shot speaks volumes.
They’re an essential part of the look and feel of everything we do. "
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="why-ov-box">
                <div className="content-icon">
                  <img src={whyOvIcon4} alt="why orangevideos icons" />
                </div>
                <div className="content-text">
                  <h4>Talent Scouting </h4>
                  <WhyOvTextContent
                    fstContent="The right talent can make your project click in a "
                    rmSecContent="The right talent can make your project click in a magical way. We have extensive networks that will ensure the right talent represents your intentions in front of the camera. "
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="why-ov-box">
                <div className="content-icon">
                  <img src={whyOvIcon5} alt="why orangevideos icons" />
                </div>
                <div className="content-text">
                  <h4>Equipment Hire</h4>
                  <WhyOvTextContent
                    fstContent="Orange Videos never compromises on "
                    rmSecContent="Orange Videos never compromises on equipment quality. We hire top of the line
cameras and lenses, lights, production tools as a part of our offering – including a
variety of film cameras, lenses, lights, mics as per the requirement. "
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="why-ov-box">
                <div className="content-icon">
                  <img src={whyOvIcon6} alt="why orangevideos icons" />
                </div>
                <div className="content-text">
                  <h4>Experienced editors</h4>
                  <WhyOvTextContent
                    fstContent="Editing, color grading, special effects, cool "
                    rmSecContent="Editing, color grading, special effects, cool transitions are all done in house by our
team of editors. "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="commonSection gray-bg">
        <div className="container">
          <div className="col-md-12 text-center mb-5">
            <h2 className="sec_title">Meet Our Team</h2>
            <p className="sec_desc">
              Discover more about our high performing team۔
            </p>
          </div>
          <div className="row teams-main-box d-flex">
            <div className="col-lg-4 col-md-4 col-sm-12 teams-ccontainer">
              <div className="teams-card">
                <div className="teams-img">
                  <img src={teamImg1} alt="team images" width="100%" />
                </div>
                <div className="teams-text-content">
                  <h4>
                    Prateek,
                    <br />
                    Head - Production
                  </h4>
                  <div className="head-line">
                    <span className="head-bottom-line"></span>
                  </div>
                  <p>
                    With us since inception, Prateek is most meticulous when
                    it comes to planning and arranging necessary resources.
                    His superpower is combating all contingencies with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 teams-ccontainer">
              <div className="teams-card">
                <div className="teams-img">
                  <img src={teamImg2} alt="team images" width="100%" />
                </div>
                <div className="teams-text-content">
                  <h4>
                    Rachna,
                    <br />
                    Head - Client Servicing
                  </h4>
                  <div className="head-line">
                    <span className="head-bottom-line"></span>
                  </div>
                  <p>
                    Our dedicated client coordinator, ensuring seamless
                    communication and collaboration to deliver exceptional ad
                    film projects on time. Her super power is ‘Patience’; try
                    Her!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 teams-ccontainer">
              <div className="teams-card">
                <div className="teams-img">
                  <img src={teamImg3} alt="team images" width="100%" />
                </div>
                <div className="teams-text-content">
                  <h4>
                    Shreyashi,
                    <br />
                    Art Director
                  </h4>
                  <div className="head-line">
                    <span className="head-bottom-line"></span>
                  </div>
                  <p>
                    Our creative director who crafts innovative ad films,
                    turning brand visions into captivating and memorable
                    stories. Her superpower is Talent and Venue scouting.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="commonSection digi-ads-lp-contact-us-section">
        <div className="container">
          <div className="col-md-12 text-center mb-5">
            <h2 className="sec_title">Contact us</h2>
            <p className="sec_desc">
              If you’ve got a story to tell on video, we want to help you tell
              it. Get in touch to arrange a meeting.
            </p>
          </div>
          <DigitalAdsContactForm />
        </div>
      </section>

      {/* <TheyTrustUs /> */}

      {/* <FaqSection /> */}

      {/* <OvInfo /> */}

      <LpFooter />
      {/* </ScrollSpy> */}

      <VideoPopup />
    </>
  );
};

export default DigitalAdsLandingPage;
