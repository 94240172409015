import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { AppUseContext } from "../../AppContext";
import ReCAPTCHA from "react-google-recaptcha";
import { getStorage, ref, uploadBytes } from "firebase/storage";
// import { storage } from "../../../Firebase/firebase";

const ApplyForm = () => {
  const currentURL = window.location.href;

  const { recaptchaSiteKey } = AppUseContext();

  const recaptcha = useRef();

  // Split the URL by '/'
  const urlSegments = currentURL.split("/");

  // Get the last segment (excluding empty segments)
  const lastSegment = urlSegments.filter((segment) => segment !== "").pop();

  const url = lastSegment.replace("-apply", "");

  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [formData, setFormData] = useState({
    career_f_name: "",
    career_l_name: "",
    career_email: "",
    career_phone: "",
    career_experience: "",
    career_current_ctc: "",
    career_expected_ctc: "",
    career_notice_period: "",
    career_earliest_date: "",
    career_resume: null,
    career_apply_for: url.replace("-", " "),
  });

  const [formErrors, setFormErrors] = useState({});

  // const [showDialog, setShowDialog] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      career_resume: e.target.files[0],
    });
    // console.log(e.target.files[0].name);
  };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple form validation
    const errors = {};
    if (!formData.career_f_name.trim()) {
      errors.career_f_name = "First Name is required";
    }

    if (!formData.career_l_name.trim()) {
      errors.career_l_name = "Last Name is required";
    }

    if (!formData.career_email.trim()) {
      errors.career_email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.career_email)) {
      errors.career_email = "Invalid email format";
    }

    // ... (Validation logic for other fields)

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Clear previous errors if any
    setFormErrors({});

    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      alert("Please varify reCAPTCHA");
    } else {
      try {
        setIsSubmitting(true);
        const response = await axios.post(
          "https://ov-email-server.glitch.me/send-email",
          form
        );
        console.log(response.data);
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `careers/${formData.career_resume.name}`
        );

        uploadBytes(storageRef, formData.career_resume)
          .then((snapshot) => {
            console.log("Uploaded a file!");
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });

        // Show the dialog
        // setShowDialog(true);

        // Reset form data after successful submission
        setFormData({
          career_f_name: "",
          career_l_name: "",
          career_email: "",
          career_phone: "",
          career_experience: "",
          career_current_ctc: "",
          career_expected_ctc: "",
          career_notice_period: "",
          career_earliest_date: "",
          career_resume: null,
        });
        navigate("/thank-you");

        // Hide the dialog after 5 seconds
        // setTimeout(() => {
        //   setShowDialog(false);
        // }, 5000);
      } catch (error) {
        setIsSubmitting(false);
        console.error(
          "Error sending email:",
          error.response ? error.response.data : error.message
        );
      }
    }
  };

  const handlePlaceholder = (e) => {
    e.target.placeholder = "";
  };

  return (
    <>
      <section
        className="pageBanner careers-banner"
        // initial="hide"
        // whileInView="view"
        // viewport={{ once: true }}
        // variants={{
        //   view: { opacity: 1, y: 0 },
        //   hide: { opacity: 0, y: 100 },
        // }}
        // transition={{ duration: 1 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content text-center">
                <h4>
                  <Link to="/">home</Link> - <Link to="/careers">careers</Link>{" "}
                  - {url.replace("-", " ")}
                </h4>
                <h2>{url.replace("-", " ")}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <motion.section
        className="commonSection ContactPage"
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="sec_title">personal information</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-lg-offset-2 col-sm-12 col-md-10 col-md-offset-1">
              <form
                className="contactFrom"
                id="careerForm"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className={`input-form required ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                      type="text"
                      name="career_f_name"
                      id="career_f_name"
                      placeholder="First Name"
                      onChange={handleChange}
                      onFocus={handlePlaceholder}
                      value={formData.career_f_name}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className={`input-form required ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                      type="text"
                      name="career_l_name"
                      id="career_l_name"
                      placeholder="Last Name"
                      onChange={handleChange}
                      onFocus={handlePlaceholder}
                      value={formData.career_l_name}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className={`input-form required ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                      value={formData.career_email}
                      type="email"
                      name="career_email"
                      id="career_email"
                      placeholder="Email Address"
                      onChange={handleChange}
                      onFocus={handlePlaceholder}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      value={formData.career_phone}
                      className={`input-form required ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                      type="text"
                      name="career_phone"
                      id="career_phone"
                      placeholder="Phone Number"
                      onChange={handleChange}
                      onFocus={handlePlaceholder}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      value={formData.career_experience}
                      className={`input-form required ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                      type="text"
                      name="career_experience"
                      id="career_experience"
                      placeholder="Experience (ex: X years X months)"
                      onChange={handleChange}
                      onFocus={handlePlaceholder}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      value={formData.career_current_ctc}
                      className={`input-form required ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                      type="text"
                      name="career_current_ctc"
                      id="career_current_ctc"
                      placeholder="Current CTC (per annum)"
                      onChange={handleChange}
                      onFocus={handlePlaceholder}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      value={formData.career_expected_ctc}
                      className={`input-form required ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                      type="text"
                      name="career_expected_ctc"
                      id="career_expected_ctc"
                      placeholder="Expected CTC (per annum)"
                      onChange={handleChange}
                      onFocus={handlePlaceholder}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      value={formData.career_notice_period}
                      className={`input-form required ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                      type="text"
                      name="career_notice_period"
                      id="career_notice_period"
                      placeholder="Notice Period (in Days)"
                      onChange={handleChange}
                      onFocus={handlePlaceholder}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      value={formData.career_earliest_date}
                      className={`input-form required ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                      type="text"
                      name="career_earliest_date"
                      id="career_earliest_date"
                      placeholder="Earliest Date of Joining (in Days)"
                      onChange={handleChange}
                      onFocus={handlePlaceholder}
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6 type-file-container">
                    <span
                      className={` ${
                        formErrors.career_f_name ? "error-border" : ""
                      }`}
                    >
                      <input
                        className={`input-form required`}
                        type="file"
                        name="career_resume"
                        id="career_resume"
                        onChange={handleFileChange}
                      />
                    </span>
                    <span id="document_upload_error"></span>
                  </div>
                  <input
                    className={`input-form required ${
                      formErrors.career_f_name ? "error-border" : ""
                    }`}
                    type="hidden"
                    name="career_apply_for"
                    id="career_apply_for"
                    value={url}
                  />
                </div>
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={recaptchaSiteKey}
                  style={{
                    marginBottom: "20px",
                  }}
                />
                <button
                  className="guide-btn red_bg"
                  type="submit"
                  id="career_form_button"
                  disabled={isSubmitting}
                >
                  <span>{isSubmitting ? "Submitting..." : "Send Message"}</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </motion.section>
      {/* <AnimatePresence>
        {showDialog && (
          <motion.div
            className="dialog-box"
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <p>Form submitted successfully!</p>
          </motion.div>
        )}
      </AnimatePresence> */}
    </>
  );
};

export default ApplyForm;
