import React, { useEffect } from "react";
import "./styles/importanceOfRacce.css";
import { Link, useLocation } from "react-router-dom";
import MetaData from "../MetaData";
// import { FaYoutube } from "react-icons/fa";
import img1 from "../../assets-new/images/blog/the-joy-and-challenges-of-making-a-school-video/1.png";
import img2 from "../../assets-new/images/blog/the-joy-and-challenges-of-making-a-school-video/2.png";

const TheJoyandChallengesofMakingASchoolVideo = () => {
  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const mobileMediaQuery = window.matchMedia("(max-width: 500px)");
  return (
    <>
      <MetaData pageTitle="The Joy and Challenges of Making a School Video" />
      {/* section 1 */}
      <section className="pageBanner insights-banner-blog TheJoyandChallengesofMakingASchoolVideo">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content text-center ">
                {/* <!--<h4><a href="#">home</a> - Blog</h4>-->
                            <!--<h2>Benefits of using stock footage in videos</h2>--> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 2 */}
      <section className="commonSection blogPage PowerOfCustomerTestimonialVideos-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="sec_title">
                The Joy and Challenges of Making a School Video
              </h1>
            </div>
          </div>
          <section id="blog-post">
            <article>
              <div className="article-body">
                <div className="post-content">
                  {/* <h2>Why They Matter for Your Business</h2> */}
                  <p>
                    At Orange Videos, we believe that every project tells a
                    unique story, and our recent collaboration with Crimson
                    School was no exception. Creating an engaging and memorable
                    video for the school was both a thrilling and rewarding
                    experience. However, like any creative journey, it came with
                    its own set of challenges and moments of fun that we will
                    cherish. Here's a glimpse into our behind-the-scenes
                    adventure, and the lasting impact the project left on both
                    our team and the Crimson community.
                  </p>

                  <div className="row d-flex justify-content-center mb-3 mt-5">
                    <div className="col-lg-6">
                      <img src={img1} width="100%" alt="" />
                    </div>
                  </div>

                  <h2>The Challenges We Faced</h2>
                  <ul>
                    <li>
                      <b>Tight Timelines</b>: With limited hours available
                      during school time, efficient time management was crucial
                      to capture everything needed without rushing.
                    </li>
                    <li>
                      <b>Multiple Locations</b>: Managing shoots across three
                      different sites at once required careful scheduling,
                      transport coordination, and precise execution to avoid
                      delays.
                    </li>
                    <li>
                      <b>Team Coordination</b>: Effective communication and
                      teamwork were key in ensuring smooth transitions between
                      locations, camera setups, and crew tasks.
                    </li>
                    <li>
                      <b>Building Confidence in Kids</b>: Many students were
                      initially nervous, but through gentle encouragement and
                      creating a supportive environment, they blossomed on
                      camera, showcasing their natural selves.
                    </li>
                    <li>
                      <b>Adapting to Challenges</b>: Each day brought new
                      hurdles, but through flexibility and quick
                      problem-solving, the team adapted and kept the shoot on
                      track.
                    </li>
                    <li>
                      <b>Creating a Comfortable Environment</b>: Ensuring the
                      students felt relaxed and comfortable allowed for
                      authentic moments on camera, which were essential to
                      capturing the right footage.
                    </li>
                  </ul>

                  <h2>The Fun We Had: A Team Effort</h2>

                  <p>
                    Despite the challenges, our team at{" "}
                    <Link to="/video-services"> Orange Videos</Link> had a
                    fantastic time working on this project. We laughed,
                    improvised, and collaborated closely to ensure the best
                    results. Our experienced crew made sure that students, too,
                    had their fair share of fun during the shoot. From
                    brainstorming creative ideas to solving on-the-spot issues,
                    every team member played a crucial role in making the
                    project successful. The school extended great support in
                    making sure we get all possible shots as planned, from an
                    auditorium filled with kids to kids engaged in multiple
                    sporting activities.The energy on set was infectious, and we
                    were all fueled by the shared excitement of creating
                    something special for Crimson.
                  </p>

                  <div className="row d-flex justify-content-center mb-3 mt-5">
                    <div className="col-lg-6">
                      <img src={img2} width="100%" alt="" />
                    </div>
                  </div>

                  <h3>The Impact: A Lasting Legacy</h3>

                  <p>
                    We believe this video helps the Crimson Anisha Global School
                    showcase their school’s environment and ethos, making it
                    relatable and inviting to prospective students and parents.
                  </p>

                  <div className="row">
                    <div className="col-lg-6">
                      <iframe
                        width={mobileMediaQuery.matches ? "100%" : "560"}
                        height={mobileMediaQuery.matches ? "290" : "315"}
                        src="https://www.youtube.com/embed/b2pDgOLUrmU?si=SaBmuM7IiIQzcOrA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="col-lg-6">
                      <iframe
                        width={mobileMediaQuery.matches ? "100%" : "560"}
                        height={mobileMediaQuery.matches ? "290" : "315"}
                        src="https://www.youtube.com/embed/WF1UVofnlTY?si=72B_sW-LMtiazNEN"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                  <p>
                    Interested in bringing your school's story to life? We'd
                    love to hear from you. <Link to="/contact">Contact us</Link>{" "}
                    to discuss your video project
                  </p>
                </div>

                {/* <div id="blog-post-gallery"></div> */}
              </div>
              {/* <!-- /#blog-post-gallery --> */}
            </article>

            {/* <!-- /#blog-post -->
                <!-- /.col --> */}
          </section>
        </div>
        {/* <!-- /.row --> */}
      </section>
      {/* <a className="bottom-cta blog-cta" href="https://www.youtube.com/@OrangeVideos" target="_blank" rel="noreferrer">
                <div>
                    <p><FaYoutube /> Check our YouTube channel for more such videos</p>
                </div>
            </a> */}
    </>
  );
};

export default TheJoyandChallengesofMakingASchoolVideo;
