import React, { useEffect } from "react";

const TestHubspotForm = () => {
    useEffect(() => {
        const testScript = document.createElement("script");
        testScript.src = "https://js.hsforms.net/forms/shell.js";
        document.body.appendChild(testScript);

        testScript.addEventListener("load", () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "46497548",
                    // added condition below because if condition is not kept it will give an error
                    formId: "88dee18f-c522-459d-9e42-9f6fe91e9398",
                    target: "#testForm",
                });
            }
        });
        // eslint-disable-next-line
    }, []);
    return (
        <div style={{
            marginTop: "100px"
        }}>
            <div id="testForm"></div>
        </div>
    )
}

export default TestHubspotForm;