import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { AppUseContext } from "../AppContext";
import LogoImg from "../../assets-new/images/logo (1).svg";
// import OvInfo from "./OvInfo";
import LpFooter from "./LpFooter";
import MetaData from "../MetaData";
import iframeThumbnial from "../../assets-new/images/thank-you/video-thumbnail.png"
import { FaPlay } from "react-icons/fa";
import VideoPopup from "../VideoPopup";

const LpThankYouPage = () => {
  const { setPageErr, setIsEcommerceLandingPage, setIsPopupActive, setPopupVideo } = AppUseContext();
  const pathname = useLocation();
  const navigate = useNavigate();
  const [ecommerceLpTitle, setEcommerceLpTitle] = useState("");
  const [hideUsefulLinksSec, setHideUsefulLinkSec] = useState(false);
  const [renderIframe, setRenderIframe] = useState(false)

  const handleIframe = () => {
    console.log(iframeRef.current)
  }

  const iframeRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    setPageErr(true);
    if (
      pathname.pathname === "/ecommerce-mumbai/thank-you" ||
      pathname.pathname === "/ecommerce-bangalore/thank-you"
    ) {
      setIsEcommerceLandingPage(true);
    }

    if (
      pathname.pathname === "/podcast-production-agency-bangalore/thank-you" ||
      pathname.pathname === "/podcast-production-agency-mumbai/thank-you" ||
      pathname.pathname === "/corporate-video-production-mumbai/thank-you" ||
      pathname.pathname === "/corporate-video-production-bangalore/thank-you" ||
      pathname.pathname === "/animation-explainer-video-bangalore/thank-you" ||
      pathname.pathname === "/animation-explainer-video-mumbai/thank-you" ||
      pathname.pathname === "/animation-explainer-video-pune/thank-you" ||
      pathname.pathname === "/corporate-video-production-pune/thank-you" ||
      pathname.pathname === "/podcast-production-agency-pune/thank-you" ||
      pathname.pathname === "/digital-ad-films-mumbai/thank-you" ||
      pathname.pathname === "/digital-ad-films-pune/thank-you" ||
      pathname.pathname === "/digital-ad-films-bangalore/thank-you"
    ) {
      setHideUsefulLinkSec(true);
    }

    if (pathname.pathname === "/ecommerce-mumbai/thank-you") {
      setEcommerceLpTitle("E-commerce Photoshoot Mumbai");
    } else if (pathname.pathname === "/ecommerce-bangalore/thank-you") {
      setEcommerceLpTitle("E-commerce Photoshoot Bangalore");
    }
    // eslint-disable-next-line
  }, [pathname]);
  return (
    <>
      <MetaData
        pageTitle={ecommerceLpTitle}
        elseContent={true}
        isPodcast={hideUsefulLinksSec}
      />
      <div className="commonSection landingPage-thank-you">
        <div className="text-center thank-you-container">
          <div className="logo-content">
            <img
              src={LogoImg}
              alt=""
              style={{
                marginBottom: 20,
              }}
            />
          </div>
          <div
            className={`thank-you-content ${hideUsefulLinksSec && "thank-you-podcast"
              }`}
          >
            {
              hideUsefulLinksSec
                ?
                <h2 className="sec_title lp-thanku-heading">
                  Thank you for contacting Orange videos
                </h2>
                :
                <h2 className="sec_title">Thank you</h2>
            }
            <p>YOUR SUBMISSION IS RECEIVED AND WE WILL CONTACT YOU SOON.</p>

            {
              hideUsefulLinksSec
              &&
              <div style={{
                marginBottom: "30px",
              }}>
                {/* {
                  renderIframe
                    ?
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/sdSs8gFUvR0?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    : */}
                <div
                  onClick={() => {
                    setIsPopupActive(true)
                    setPopupVideo("https://www.youtube.com/embed/sdSs8gFUvR0")
                  }
                  }
                  className="youtube-thumbnail-container">
                  <div className="play-btn">
                    <div className="icon">
                      <FaPlay />
                    </div>
                  </div>
                  <img src={iframeThumbnial} width="560px" alt="" />
                </div>
                {/* // } */}
              </div>
            }
            <Link
              to="/"
              // onClick={() => {
              //   navigate(-1);
              // }}
              style={{
                color: "#000",
                cursor: "pointer",
              }}
            >
              <FaArrowLeft /> Back to Home
            </Link>
          </div>
        </div>
      </div>
      {!hideUsefulLinksSec && (
        <div
          style={{
            backgroundColor: "#fafbfc",
          }}
        >
          <LpFooter />
        </div>
      )}

      <VideoPopup />
    </>
  );
};

export default LpThankYouPage;
