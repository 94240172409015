import React, { useState, memo } from "react";
import { motion } from "framer-motion";
// import { FaPlus, FaMinus } from "react-icons/fa6";

const FaqSection = () => {
  const faqAccordionObj = [
    {
      accName: "What do video production companies do?",
      content:
        "We are a full service video production agency engaged in creative video making & strategic marketing thinking. We specialise in executing digital films, corporate films, product videos and animation films for a variety of brands across industries.",
    },
    {
      accName: "What kind of videos do you make?",
      content:
        "We make all kinds of videos starting from Corporate videos to 2D Animation videos, Explainer videos, Whiteboard animation, Motion Graphics videos, Testimonial videos and many more.",
    },
    {
      accName: "What does the video production process involve?",
      content: (
        <>
          <p>
            The process involves 3 main stages: Pre-production, Production and
            Post production:
          </p>
          <p className="faq-text">For Animated Videos</p>
          <ul className="faq_ul pl-4">
            <li className="sec_desc">
              Pre production involves Scripting, Storyboarding, etc.
            </li>
            <li className="sec_desc">
              Production involves Graphic designing, Characterization &amp;
              Animation
            </li>
            <li className="sec_desc">
              Post production involves Editing, Transitions, Background music,
              Voice overs, Rendering, etc.
            </li>
          </ul>
          <p className="faq-text">For Live Action Videos</p>
          <ul className="faq_ul pl-4">
            <li className="sec_desc">
              Pre production involves Location Recce, Scripting, Storyboarding,
              etc.
            </li>
            <li className="sec_desc">
              Production involves Shooting, Equipment setup, Crew management,
              etc.
            </li>
            <li className="sec_desc">
              Post production involves Editing, Transitions, Audio selection,
              Voice overs, Rendering, etc.
            </li>
          </ul>
        </>
      ),
    },
    {
      accName: "What is the cost to make an animation video?",
      content: (
        <>
          <p>
            The cost depends on various factors which include length of the
            video, the complexity of the animation, whether or not we have
            characters, the no. of characters etc.
          </p>
          <p>
            There will never be a fixed cost attached to any video as these
            factors play a major role in how the final product turns out.
          </p>
        </>
      ),
    },
    {
      accName: "What will be the turnaround time for this project?",
      content:
        "For any project the turnaround time will be 2-3 weeks depending on the scope of work. If you need a faster delivery, we will be happy to do so at a small additional fee.",
    },
    {
      accName: "What are the payment terms?",
      content:
        "Usually, video projects have an advance payment of 50% before beginning the project and the remainder of the amount is to be paid only when the final product is ready to be delivered.",
    },
    {
      accName: "Can you help us with the ideation of the video?",
      content:
        "Yes, we can absolutely do that. You will be assigned a dedicated Project Manager who will work closely with you on the concept, ideation and scripts along with the script writer. In fact, we have an in-house team of script writers, designers, animators & editors to ensure a smooth functionality of the process.",
    },
    {
      accName: "Will you provide voiceover options?",
      content:
        "Yes we will provide at least 3 voiceover options, and if required, we can provide artists in different accents and languages as well.",
    },
  ];

  const [toggleAcc, setToggleAcc] = useState(0);
  const handleAccToggle = (index) => {
    setToggleAcc(index);
  };

  const accAnimate = {
    open: {
      opacity: 1,
      height: "100%",
      top: 0,
    },
    close: {
      opacity: 0,
      height: 0,
      top: -10,
    },
  };

  return (
    <div className="commonSection gray-bg" id="faqs">
      <div className="container">
        <h2 className="text-center mb-5 sec_title">FAQs</h2>

        <div className="faq-accordion-component">
          {faqAccordionObj.map((items, ind) => (
            <FaqItem
              key={ind}
              items={items}
              ind={ind}
              toggleAcc={toggleAcc}
              handleAccToggle={handleAccToggle}
              accAnimate={accAnimate}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const FaqItem = memo(({ items, ind, toggleAcc, handleAccToggle, accAnimate }) => {
  return (
    <div
      className="faq-container"
      style={{
        transition: "all 0.5s ease-in-out",
      }}
    >
      <div
        className={`faq-acc-title ${ind === toggleAcc && `acc-active`}`}
        onClick={() => handleAccToggle(ind)}
      >
        {items.accName}
        <span>{ind === toggleAcc ? "-" : "+"}</span>
      </div>
      {ind === toggleAcc && (
        <motion.div
          className="faq-acc-content"
          animate="open"
          variants={accAnimate}
          exit="close"
          initial="close"
          transition={{ duration: 1, type: "spring", bounce: 0.25 }}
          style={{
            position: "relative",
            borderBottom: '1px solid #e5e5e5',
            overflow: "hidden",
            clear: "both",
          }}
        >
          {items.content}
        </motion.div>
      )}
    </div>
  );
});

export default FaqSection;
