import React, { useEffect, useState } from 'react'
import NumberAnimationCounter from '../Services/NumberAnimationCounter'
// import CircleProgress from './CircleProgress'
// import ProgressValContent from './ProgressValContent'

const WhyChooseOv = () => {
    // const mobileMediaQuery = window.matchMedia("(max-width: 500px)")

    return (
        <div className='stats-section'>
            <div className="container commonSection">
                <h2 className="sec_title text-center">Why choose Orange Videos
                    {/* for Podcasts
                    {
                        !mobileMediaQuery.matches
                        &&
                        <br />
                    }
                    in Mumbai */}
                    </h2>
                {/* <div
                    role="progressbar"
                    aria-valuenow="65"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ '--value': 65 }}
                ></div> */}
                {/* <ProgressValContent progressVal="12" />
                <ProgressValContent progressVal="750" />
                <ProgressValContent progressVal="500" /> */}
                <div className="row">
                    <div className="col-md-2 col-sm-12 stats-content">
                        <h3 className="stats-heading">

                            {/* 12 */}
                            <NumberAnimationCounter
                                from={0}
                                to={12}
                                duration={250}
                                delay={0}
                            />
                        </h3>
                        <span>years experience</span></div>
                    <div className="col-md-2 col-sm-12 stats-content">
                        <h3 className="stats-heading">
                            <NumberAnimationCounter
                                from={0}
                                to={750}
                                duration={5}
                                delay={0}
                            />
                        </h3>
                        <span>Projects</span>
                    </div>
                    <div className="col-md-2 col-sm-12 stats-content">
                        <h3 className="stats-heading">
                            <NumberAnimationCounter
                                from={0}
                                to={500}
                                duration={5}
                                delay={0}
                            />
                        </h3>
                        <span>Happy clients </span>
                    </div>
                    <div className="col-md-2 col-sm-12 stats-content">
                        <h3 className="stats-heading">
                            <NumberAnimationCounter
                                from={0}
                                to={70}
                                duration={100}
                                delay={0}
                            />
                        </h3><span>Creative Minds </span>
                    </div>
                    <div className="col-md-2 col-sm-12 stats-content">
                        <h3 className="stats-heading">
                            <NumberAnimationCounter
                                from={0}
                                to={12}
                                duration={250}
                                delay={0}
                                plusSign={false}
                            />
                        </h3><span>Countries &amp; counting</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseOv