import React, { useEffect, useState } from "react";
import { MdOutlineMap } from "react-icons/md";
import { RiMessage2Line } from "react-icons/ri";
import { IoIosLink } from "react-icons/io";
import { useLocation } from "react-router-dom";
import processPdf from "../../pdf/process.pdf";
import sevenElementsPdf from "../../pdf/7elements.pdf";
import explainerVideoScriptsPdf from "../../pdf/explainer-video-scripts.pdf";
import WhatsAppImg from "../../assets-new/images/whatsapp.png";
import { FaInstagram } from "react-icons/fa";
import {
  FaLinkedinIn,
  FaSquareFacebook,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { AppUseContext } from "../AppContext";

const LpFooter = () => {
  const { isEcommerceLandingPage } = AppUseContext();
  const pathname = useLocation();
  const [locationName, setLocationName] = useState("");
  useEffect(() => {
    if (
      pathname.pathname === "/animation-explainer-video-mumbai" ||
      pathname.pathname === "/corporate-video-production-mumbai" ||
      pathname.pathname === "/animation-explainer-video-mumbai/thank-you" ||
      pathname.pathname === "/corporate-video-production-mumbai/thank-you" ||
      pathname.pathname === "/ecommerce-mumbai/thank-you" ||
      pathname.pathname === "/ecommerce-mumbai" ||
      pathname.pathname === "/digital-ads-mumbai"
    ) {
      setLocationName("Andheri East, Mumbai 400059");
    } else if (
      pathname.pathname === "/animation-explainer-video-bangalore" ||
      pathname.pathname === "/corporate-video-production-bangalore" ||
      pathname.pathname === "/animation-explainer-video-bangalore/thank-you" ||
      pathname.pathname === "/corporate-video-production-bangalore/thank-you" ||
      pathname.pathname === "/ecommerce-bangalore/thank-you" ||
      pathname.pathname === "/ecommerce-bangalore" ||
      pathname.pathname === "/digital-ads-bangalore"
    ) {
      setLocationName("Indiranagar, Bangalore 560038");
    } else {
      setLocationName("");
    }
  }, [pathname.pathname]);
  return (
    <>
      <div className="footer lp-footer">
        {/* <!--begin container --> */}
        <div className="container footer-top">
          {/* <!--begin row --> */}
          <div className="row">
            {/* <!--begin col-md-4 --> */}
            <div className="col-md-4 text-center">
              <span className="lp-footer-icons">
                <MdOutlineMap />
              </span>

              <h5>Get In Touch</h5>
              <p>{locationName}</p>

              {/* <!--<p>Andheri East, Mumbai 400059</p>--> */}

              <p className="mail-ele">
                <a href="mailto:sales@orangevideos.in">sales@orangevideos.in</a>
              </p>

              <p>
                {/* {isEcommerceLandingPage ? "+91 8356056796" : */}
                "+91 9867409221"
                {/* // } */}
              </p>
            </div>
            {/* <!--end col-md-4 --> */}

            {/* <!--begin col-md-4 --> */}
            <div className="col-md-4 text-center">
              <span className="lp-footer-icons">
                <RiMessage2Line />
              </span>

              <h5>Social Media</h5>

              <p>You can find us here.</p>

              {/* <!--begin footer_social --> */}
              <ul className="footer_social lp-footer-social-media">
                <li>
                  <a href="https://www.instagram.com/orange_videos/?next=%2F">
                    {/* <i className="fab fa-instagram"></i> */}
                    <FaInstagram />
                  </a>
                </li>
                {/* <!--<li><a href="https://fb.me/orangevideosofficial"><i className="fab fa-facebook-square"></i></a></li>--> */}
                <li>
                  <a href="https://www.facebook.com/orangevideosofficial">
                    {/* <i className="fab fa-facebook-square"></i> */}
                    <FaSquareFacebook />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/Orange_Videos">
                    {/* <i className="fab fa-twitter"></i> */}
                    <FaXTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCTfxtQyIJyvlpwKrvLsRj7w/videos">
                    {/* <i className="fab fa-youtube"></i> */}
                    <FaYoutube />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/13413575/admin/feed/posts/">
                    {/* <i className="fab fa-linkedin"></i> */}
                    <FaLinkedinIn />
                  </a>
                </li>
              </ul>
              {/* <!--end footer_social --> */}
            </div>
            {/* <!--end col-md-4 --> */}

            {/* <!--begin col-md-4 --> */}
            <div className="col-md-4 text-center">
              <span className="lp-footer-icons">
                <IoIosLink />
              </span>

              <h5>Useful Links</h5>
              <a
                href={processPdf}
                rel="noreferrer"
                className="footer-links"
                target="_blank"
              >
                Our Video Production Process
              </a>
              <br />

              <a
                href={sevenElementsPdf}
                rel="noreferrer"
                className="footer-links"
                target="_blank"
              >
                7 Elements Every Video Needs{" "}
              </a>

              <br />
              <a
                href={explainerVideoScriptsPdf}
                rel="noreferrer"
                className="footer-links"
                target="_blank"
              >
                Explainer Video Scripts
              </a>
            </div>
            {/* <!--end col-md-4 --> */}
          </div>
          {/* <!--end row --> */}
        </div>
        {/* <!--end container --> */}

        {/* <!--begin container --> */}
        <div className="container-fluid footer-bottom px-0 copy-right-lp">
          {/* <!--begin row --> */}
          <div className="row no-gutters mx-0">
            {/* <!--begin col-md-12 --> */}
            <div className="col-md-12 text-center">
              <p id="footer-text">
                © Copyright 2024{" "}
                <a
                  href="https://www.orangevideos.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Orange Videos.
                </a>{" "}
                A part of 2020 Media Collective.
              </p>
            </div>
            {/* <!--end col-md-6 --> */}
          </div>
          {/* <!--end row --> */}
        </div>

        {/* whatsapp floating img */}
        <div className="floating_whatsapp whatsapp_landingPages">
          <a href={
            // isEcommerceLandingPage ? "https://api.whatsapp.com/send/?phone=918356056796&text&type=phone_number&app_absent=0" :
            "https://wa.me/919867409221"
            } target="_blank" rel="noreferrer">
            <img src={WhatsAppImg} className="whatsapp_img" alt="" />
          </a>
        </div>

        {/* <Link id="backToTop" to="#" className="showit">
        <i className="fa fa-angle-double-up"></i>
        // 
      </Link> */}

        {/* <!--end container --> */}
      </div>
    </>
  );
};

export default LpFooter;
