import React from "react";

const OvInfo = () => {
  return (
    <>
      <section className="ov-info small-paddings" id="ov_sec">
        {/* <!--begin container --> */}
        <div className="container">
          {/* <!--begin row --> */}
          <div className="row">
            {/* <!--begin col-md-12--> */}
            <div className="col-md-12 text-center">
              <h2 className="black-text sec_title">Orange Videos</h2>

              <p className="black-text">
                We're a video production agency helping businesses and brands
                achieve their goals with video. Elevate your brand with our
                fully customized video production services.
              </p>

              <a href="#home" className="countdown-cta scrool">
                Want a quote for your project, or simply have more questions?{" "}
                <span>Get in Touch.</span>
              </a>
            </div>
            {/* <!--end col-md-12--> */}
          </div>
          {/* <!--end row --> */}
        </div>
        {/* <!--end container --> */}
      </section>
    </>
  );
};

export default OvInfo;
