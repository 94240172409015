import React, { useState } from 'react'
import img1 from "../../assets-new/images/podcast-page-assets/uat-imgs/why-podcasting/why-pod-img-1.svg"
import img2 from "../../assets-new/images/podcast-page-assets/uat-imgs/why-podcasting/why-pod-img-2.svg"
import img3 from "../../assets-new/images/podcast-page-assets/uat-imgs/why-podcasting/why-pod-img-3.svg"
import img4 from "../../assets-new/images/podcast-page-assets/uat-imgs/why-podcasting/why-pod-img-4.svg"
import { FaChevronDown } from "react-icons/fa";

const WhyPodContent = () => {

    const obj = [
        {
            tabName: "Brand Awareness",
            tabContent: "Podcasting enhances brand recognition by sharing valuable insights, expert interviews, and thought leadership. It positions businesses as industry leaders, building trust and credibility while fostering lasting audience connections.",
            tabImg: img1
        },
        {
            tabName: "Targeted Marketing",
            tabContent: "Podcasts attract niche audiences, delivering personalized messages aligned with their interests. Businesses can boost marketing impact through focused content, sponsored segments, and collaborations with relevant influencers.",
            tabImg: img2
        },
        {
            tabName: "Community Building",
            tabContent: "Podcasts create interactive communities through discussions and feedback. Consistent content helps brands foster loyalty, turning listeners into advocates and building meaningful relationships over time.",
            tabImg: img3
        },
        {
            tabName: "Business Connections",
            tabContent: "Podcasts offer networking opportunities with industry leaders and partners. Featuring guests and sharing stories enhances content and strengthens professional networks, opening doors for future collaborations and business growth.",
            tabImg: img4
        },
    ]

    const [changeTabInd, setChangeTabInd] = useState(0)
    const [changeContent, setChangeContent] = useState(obj[0].tabContent)
    const [changeImg, setChangeImg] = useState(obj[0].tabImg)
    const handleTabToggle = ({ content, indexNum, img }) => {
        setChangeContent(content)
        setChangeImg(img)
        setChangeTabInd(indexNum)
    }

    const mobileMediaQuery = window.matchMedia("(max-width: 500px)")
    return (
        <div className='podcast-why-pod-tab'>
            <div className="tab-content">
                {
                    obj.map((data, ind) => {
                        return (
                            <div className={`tab-container ${changeTabInd === ind && "pod-tab-active"}`} key={ind} onClick={() => {
                                handleTabToggle({ content: data.tabContent, img: data.tabImg, indexNum: ind })
                            }}>
                                <div className="tab-name">
                                    <p>
                                        {
                                            data.tabName
                                        }
                                    </p>
                                    <span>
                                        <FaChevronDown />
                                    </span>
                                </div>
                                {
                                    mobileMediaQuery.matches
                                    &&
                                    <>
                                        {
                                            changeTabInd === ind
                                            &&
                                            <div className="tab-content">
                                                <div className="txt-conten">
                                                    {
                                                        changeContent
                                                    }
                                                </div>
                                                <div className="img">
                                                    <img src={changeImg} alt="" />
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        )
                    })
                }
            </div>

            {
                mobileMediaQuery.matches
                    ?
                    null
                    :
                    <div className="tab-content">
                        <div className="txt-conten">
                            {
                                changeContent
                            }
                        </div>
                        <div className="img">
                            <img src={changeImg} alt="" />
                        </div>
                    </div>
            }
        </div>
    )
}

export default WhyPodContent