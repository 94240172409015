import { useEffect } from "react";

const HubspotTrackerScript = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.hs-scripts.com/46497548.js";
        script.async = true; // Load the script asynchronously
        document.body.appendChild(script);

        // script.onload = () => {
        //     console.log("HubSpot tracking script loaded.");
        // };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default HubspotTrackerScript;
