import React from "react";
import { Helmet } from "react-helmet-async";

const MetaData = ({
  pageTitle,
  metaDdescription,
  elseContent = false,
  isContactPage = false,
  isPodcast = false
}) => {
  return (
    <Helmet>

      <meta property="og:title" content="Podcast mumbai test" />
      <meta property="og:description" content={metaDdescription} />
      <title>{pageTitle && pageTitle}</title>
      <meta name="description" content={metaDdescription}></meta>
      {elseContent &&
        `
        <!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Q1BN90WK19"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Q1BN90WK19');

gtag('config', 'AW-11344569007');
</script>
        `}

      {isPodcast
        &&
        `
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W495DCJ');</script>
<!-- End Google Tag Manager -->
        `
      }
    </Helmet>
  );
};

export default MetaData;
