import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { GrNext, GrPrevious } from "react-icons/gr";
import { Autoplay, EffectFade, EffectCoverflow } from "swiper/modules";
import "swiper/css/effect-coverflow";
import img1 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img1.webp"
import img2 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img2.webp"
import img3 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img3.webp"
// import img4 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img4.webp"
import img5 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img5.webp"
import img6 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img6.webp"
import img7 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img7.webp"
import img8 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img8.webp"
import img9 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img9.webp"
import img10 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img10.webp"
// import img11 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img11.webp"
import img12 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img12.webp"
import img13 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img13.webp"
import img14 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img14.webp"
import img15 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img15.webp"
import img16 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img16.webp"
import img17 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img17.png"
import img18 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img18.png"
import img19 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img19.png"
import img20 from "../../assets-new/images/podcast-page-assets/uat-imgs/brand-trust-us/img20.png"
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const BrandTrustUs = () => {
    //   const swiperBtn = useSwiper();
    const carouselObj = [
        // {
        // one: [
        img1,
        img2,
        img3,
        // img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
        // img11,
        img12,
        img13,
        img14,
        img15,
        img16,
        img17,
        img18,
        img19,
        img20,
        // ],
        // two: [
        //     img1,
        //     img2,
        //     img3,
        //     img4,
        //     img5
        // ],
        // three: [
        //     img1,
        //     img2,
        //     img3,
        //     img4,
        //     img5
        // ],
        // four: [
        //     img1,
        //     img2,
        //     img3,
        //     img4,
        //     img5
        // ],
        // one: [
        // ],
        // two: [img1,
        //     img2,
        //     img3,
        //     img4,
        //     img5],
        // three: [img1,
        //     img2,
        //     img3,
        //     img4,
        //     img5],
        // four: [img1,
        //     img2,
        //     img3,
        //     img4,
        //     img5]
        // },
    ];

    const mobileObj = [
        {
            fst: [
                img1,
                img2,
                img3,
                // img4,
                img5,
                img6,
                img7,
                img8,
                img9,
                img10,
            ],
            sec: [
                // img11,
                img12,
                img13,
                img14,
                img15,
                img16,
                img17,
                img18,
                img19,
                img20,
            ]
        }
    ]

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                onClick={onClick}
                className='custom-arrows right-arr'
            >
                <IoIosArrowForward />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className='custom-arrows left-arr'
                onClick={onClick}
            >
                <IoIosArrowBack />
            </div>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                }
            }
        ]
    };

    const mobileMediaQuery = window.matchMedia("(max-width: 500px)")

    // const SwiperButtonNext = ({ children }) => {
    //     const swiper = useSwiper();
    //     return <button onClick={() => swiper.slideNext()}>{children}</button>;
    // };

    // const SwiperButtonPrev = ({ children }) => {
    //     const swiper = useSwiper();
    //     return <button onClick={() => swiper.slidePrev()}>{children}</button>;
    // };
    return (
        <div className={`commonSection podcast-brand-trust`}>
            <div className="container">
                <div className="text-center">
                    {/* {
                        !isPodcast
                            ?
                            <> */}
                    <h2
                        className="sec_title"
                    // style={{
                    //   marginBottom: 15,
                    // }}
                    >
                        Brands that trust us
                    </h2>
                    <p>We have collaborated with esteemed and reputed brands</p>
                    {/* </>
                            :
                            <h2 className="sec_title">Brands that trust us</h2>
                    } */}
                </div>
                <div className="logos-carousel">
                    {/* <SwiperSlide> */}
                    {/* <div className="logos-carousel-container">
                                {carouselObj.map((data, ind) => {
                                    return (
                                        <React.Fragment key={ind}>
                                            <div className="logo-carousel-content">
                                                {data.one.map((img) => (
                                                    <div className="dv-img" key={img}>
                                                        <img src={img} alt="" />
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div> */}
                    {/* </SwiperSlide> */}
                    {
                        mobileMediaQuery.matches
                            ?
                            <>
                                <Slider {...settings}>
                                    <div className="mob-carousel-logo">
                                        {
                                            mobileObj[0].fst.map((srcData, fstInd) => {
                                                return (
                                                    <div className="mob-carousel-container" key={fstInd}>
                                                        <img src={srcData} alt="" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="mob-carousel-logo">
                                        {
                                            mobileObj[0].sec.map((srcData, secInd) => {
                                                return (
                                                    <div className="mob-carousel-container" key={secInd}>
                                                        <img src={srcData} alt="" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Slider>
                            </>
                            :
                            <>
                                <Slider {...settings}>
                                    {
                                        carouselObj.map((content, ind) => {
                                            return (
                                                <React.Fragment key={ind}>
                                                    <div className="logos-carousel-container">
                                                        <div className="logo-carousel-content">
                                                            <div className="dv-img">
                                                                <img src={content} alt={ind} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Slider>
                            </>
                    }
                    {/* <SwiperSlide>
                            <div className="logos-carousel-container">
                                {carouselObj.map((data, ind) => {
                                    return (
                                        <React.Fragment key={ind}>
                                            <div className="logo-carousel-content">
                                                {data.two.map((img) => (
                                                    <div className="dv-img" key={img}>
                                                        <img src={img} alt="" />
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </SwiperSlide>
                        {
                            !isPodcast
                            &&
                            <SwiperSlide>
                                <div className="logos-carousel-container">
                                    {carouselObj.map((data, ind) => {
                                        return (
                                            <React.Fragment key={ind}>
                                                <div className="logo-carousel-content">
                                                    {data.three.map((img) => (
                                                        <div className="dv-img" key={img}>
                                                            <img src={img} alt="" />
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </SwiperSlide>
                        } */}
                </div >
            </div >
        </div >
    );
};

export default BrandTrustUs;
