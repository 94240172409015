import React from "react";
// import Video1 from "../../assets-new/client-videos/video-1.mp4";
// import { FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
// import { GrNext, GrPrevious } from "react-icons/gr";
// import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { RiDoubleQuotesL } from "react-icons/ri";
import { CiUser } from "react-icons/ci";
import img1 from "../../assets-new/images/home-client-carousel/client-section-logo-13.png"
import Img2 from "../../assets-new/images/podcast-page-assets/testimonials/logo-2.png";
import img3 from "../../assets-new/images/home-client-carousel/client-section-logo-5.png"
import img4 from "../../assets-new/images/home-client-carousel/client-section-logo-44.png"
import img5 from "../../assets-new/images/podcast-page-assets/img-1.jpg"
import img6 from "../../assets-new/images/podcast-page-assets/img-2.jpg"
import img7 from "../../assets-new/images/podcast-page-assets/img-3.jpg"
import "swiper/css";
import { GrNext, GrPrevious } from "react-icons/gr";

const Testimonial = () => {
  const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slideNext()}>{children}</button>;
  };

  const SwiperButtonPrev = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slidePrev()}>{children}</button>;
  };

  // const SwiperButtonNext = ({ children }) => {
  //   const swiper = useSwiper();
  //   return <button onClick={() => swiper.slideNext()}>{children}</button>;
  // };

  // const SwiperButtonPrev = ({ children }) => {
  //   const swiper = useSwiper();
  //   return <button onClick={() => swiper.slidePrev()}>{children}</button>;
  // };

  const mobileMediaQuery = window.matchMedia("(max-width: 500px)")
  const testimonialsObj = [
    // {
    //   brandInfo: "Shubhangi Madan",
    //   brandinfo2: "Ennature",
    //   img: Img1,
    //   text: "Thank you so much for being so supportive throughout and closing the project smoothly. Ourteam really liked how project was executed.",
    // },
    // {
    //   brandInfo: "Nishant Shinde",
    //   brandinfo2: "Ansell India",
    //   img: Img2,
    //   text: "Thank you to the entire team of orange videos for executing the project flawlessly within deadlines. It is the dedication, expertise, and passion of teams like yours that make project a resounding success.",
    // },
    {
      brandInfo: "Shubhangi Madan",
      brandinfo2: "Brand Manager,",
      brandName: "India Glycol",
      img: img7,
      breakInfo2: mobileMediaQuery.matches && true,
      text: "Thank you so much for being so supportive throughout and closing this 2d animation video smoothly. Our team really liked how this was executed and am sure we shall have many more projects in pipeline to work ahead.",
    },
    {
      brandInfo: "MD & CEO.",
      brandinfo2: "Alok Steel",
      img: img5,
      breakInfo2: mobileMediaQuery.matches && true,
      text: "I would recommend Orange Videos for their services, they are very professional and they are adaptive, they are customer centric. Putting our thoughts on to the paper and from paper to the video, Orange Videos has done a great job. ",
    },
    {
      brandInfo: "Mansi Khandelwal",
      brandinfo2: "Deputy Director - Marketing & Communications",
      brandName: "Idex Corporation",
      breakInfo2: true,
      img: img4,
      text: "We have done a few videos with Orange Videos, recently we shot a little complex technical video, Orange Videos Team was very proactive to understand the nitty gritty's in order to shoot the engineering product video. We surely plan to continue our collaboration with Orange Videos.",
    },
    {
      breakInfo2: mobileMediaQuery.matches && true,
      brandInfo: "Yannick Brewart",
      brandinfo2: "Asst. Marketing Manager",
      brandName: "Tata Communications",
      img: img1,
      text: "Great turnaround from Orange Videos, considering the stringent deadlines!",
    },
    {
      breakInfo2: mobileMediaQuery.matches && true,
      brandInfo: "Amrita Deb",
      brandinfo2: "Marketing Manager",
      brandName: "Coats",
      img: img6,
      text: "This is with regards to the Down Leakage solution video. It’s really got a lot of appreciation from Global teams. You guys have done a fantastic job! ",
    },
    {
      breakInfo2: mobileMediaQuery.matches && true,
      brandInfo: "Hanja Rishter",
      brandinfo2: "Corporate Comm. Manager",
      brandName: "Hapag Lloyd",
      img: img3,
      text: "All the three projects have been realized with great reliability and professionalism",
    },
  ];

  return (
    <>
      {/* <div className="commonSection testimonial-component">
        <div
          className="container"
          style={{
            paddingBottom: "0 !important",
          }}
        >
          <p className="text-center p-sub-text">Client Testimonials</p>
          <h2 className="sec_title text-center">CLIENT SUCCESS STORIES</h2>
          <div className="testimonials-container">
            {testimonialsObj.map((data, ind) => (
              <div className="testmonial-content" key={ind}>
                <div className="testimonial-box">
                  <div
                  className="star-container"
                  style={{
                    fontSize: 20,
                  }}
                >
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                  <p className="testimonial-text">{data.text}</p>
                  <div className="testimonial-bottom-brand">
                    <p className="persons" style={{ color: "#000" }}>
                      <b>
                        <span>{data.brandInfo}</span>
                        <br />
                        {data.brandinfo2 && data.brandinfo2}
                        <br />
                        {data.brandinfo3 && data.brandinfo3}
                      </b>
                    </p>
                    <span className="testimonial-quote-icon">
                      <FaQuoteRight />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* just to test */}
      {/* <div className="commonSection testmonial-carousel-container-2">
        <div className="container">
          <div className="testmonial-carousel-2">
            <Swiper
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              loop={true}
            >
              <div className="testmonial-2-heading">
                <h2>Client Testimonials</h2>
                <div className="side-btns">
                  <SwiperButtonPrev>
                    <div className="btn-left">
                      <GrPrevious />
                    </div>
                  </SwiperButtonPrev>
                  <SwiperButtonNext>
                    <div className="btn-right">
                      <GrNext />
                    </div>
                  </SwiperButtonNext>
                </div>
              </div>
              {testimonialsObj.map((data, ind) => (
                <SwiperSlide key={ind}>
                  <div className="content">
                    <p>{data.text}</p>
                    <p className="testmonial-2-brand">
                      <b>{data.brandInfo}</b>
                      <br />
                      {data.brandinfo2}
                      <br />
                      {data.brandinfo3}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div> */}

      <div className="commonSection testimonial-3-container podcast-testimonials">
        <div className="container">
          <h2 className="sec_title text-center">Client Testimonials</h2>
          <div className="custom-testimonial-3">
            <div className="swiper-container text-center">
              <Swiper
                navigation={true}
                modules={[Pagination, Navigation]}
                // loop={true}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                autoHeight={true}
                autoplay={true}
              >
                <div
                  className="logo-swiper-bottom-buttons left-btn"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SwiperButtonPrev>
                    <div className="btn-left">
                      <GrPrevious />
                    </div>
                  </SwiperButtonPrev>
                </div>
                {/* <div className="btns">
                  <SwiperButtonPrev>
                    <div className="btn-left">
                      <GrPrevious />
                    </div>
                  </SwiperButtonPrev>
                  <SwiperButtonNext>
                    <div className="btn-right">
                      <GrNext />
                    </div>
                  </SwiperButtonNext>
                </div> */}
                {testimonialsObj.map((data, ind) => (
                  <SwiperSlide key={ind}>
                    <div className="content">
                      <div className="person-info">
                        {data.img ? (
                          <img
                            src={data.img}
                            alt=""
                            style={{
                              width: data.width && data.width,
                            }}
                          />
                        ) : (
                          <CiUser />
                        )}
                        <h5 style={{
                          // display: `${data.breakInfo2 ? "block" : "flex"}`
                        }}>
                          <b>{data.brandInfo} - </b>
                          <>
                            {data.breakInfo2}
                          </>
                          <br />
                          <span>
                            {data.brandinfo2}
                            <br />
                          </span>
                          <br />
                          {data.brandinfo3}
                          <span>
                            {data.brandName}
                          </span>
                        </h5>
                      </div>
                      <p>
                        {/* {
                          !mobileMediaQuery.matches
                          &&
                          <span className="top-quote">
                            <RiDoubleQuotesL />
                          </span>
                        } */}
                        <span className="text-content">
                          {data.text}
                        </span>
                      </p>
                      {/* <div className="quote-bottom">
                        <FaQuoteRight />
                      </div> */}
                    </div>
                  </SwiperSlide>
                ))}
                <div className="logo-swiper-bottom-buttons right-btn">
                  <SwiperButtonNext>
                    <div className="btn-right">
                      <GrNext />
                    </div>
                  </SwiperButtonNext>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="client-testimonial-videos"> */}
      {/* <iframe
          width="300"
          src="https://www.youtube.com/embed/MVncO-G4AM8?si=zFaVRIIZNXgLMC4A&amp?controls=0"
          title="YouTube Shorts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            aspectRatio: "9/16",
            marginRight: 20,
          }}
          />
          <iframe
          width="300"
          src="https://www.youtube.com/embed/XDSFM41JHRQ?si=r8apat0xaZma0vt3?controls=0"
          title="YouTube Shorts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            aspectRatio: "9/16",
            marginRight: 20,
          }}
          />
        <iframe
          width="300"
          src="https://www.youtube.com/embed/K_jO1DbyK3U?si=8RZZ2n36iT3KWOtp?controls=0"
          title="YouTube Shorts"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            aspectRatio: "9/16",
            marginRight: 20,
          }}
        /> */}

      {/* <video
          src={Video1}
          width="300px"
          controls
          style={{
            aspectRatio: "9/16",
          }}
        /> */}
      {/* </div> */}
    </>
  );
};

export default Testimonial;
