import React from "react";
import desktopLogo from "../../assets-new/images/landing-page-img/all-logo.webp";
import mobileLogo1 from "../../assets-new/images/landing-page-img/mobile_logo_1.webp";
import mobileLogo2 from "../../assets-new/images/landing-page-img/mobile_logo_2.webp";

const TheyTrustUs = () => {
  return (
    <>
      <div>
        <section
          className="commonSection section-white section-bottom-border"
          id="our-clients"
        >
          {/* <!--begin container --> */}
          <div className="container">
            {/* <!--begin row --> */}
            <div className="row">
              {/* <!--begin col-md-12--> */}
              <div className="col-md-12 text-center padding-bottom-10">
                <h4 className="sub_title red_color">our clients</h4>
                <h2 className="sec_title">they trust us</h2>

                <p className="sec_desc">Trusted by the best</p>
              </div>
              {/* <!--end col-md-12 --> */}
            </div>
            {/* <!--end row --> */}

            {/* <!--begin row --> */}
            <div className="row hidden-mobile">
              <div className="col-md-12 client-img">
                <img src={desktopLogo} loading="lazy" alt="" width="100%" />
              </div>
            </div>

            {/* <!--end col-md-6 --> */}

            <div className="row display-mobile">
              <div className="col-xs-12 client-img">
                <img src={mobileLogo1} loading="lazy" alt="" width="100%" />
              </div>
            </div>
            {/* <!--end row --> */}

            <div className="row display-mobile">
              <div className="col-xs-12 client-img">
                <img src={mobileLogo2} loading="lazy" alt="" width="100%" />
              </div>
            </div>
            {/* <!--end row --> */}
          </div>
          {/* <!--end container --> */}
        </section>
      </div>
    </>
  );
};

export default TheyTrustUs;
