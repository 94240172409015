import React from "react";
import Img1 from "../../assets-new/images/employees/resized/prateek.webp";
import Img2 from "../../assets-new/images/employees/resized/rachna.webp";
import Img3 from "../../assets-new/images/employees/resized/shreyashi.webp";
import GrpPhoto from "../../assets-new/images/employees/desktop-grp-photo-v1.webp";
import MobileGrpPhoto from "../../assets-new/images/employees/mobile-grp-photo-v1.jpg";
import bgCircle from "../../assets-new/images/employees/bgCircle.svg";
import whyUsImg from "../../assets-new/images/why-us-v2.png";
// import "../styles/why-us-svg.css";
// import { FaCircle } from "react-icons/fa";

const WhyUsSection = () => {
  const mobileMediaQuery = window.matchMedia("(max-width: 800px)");
  return (
    <>
      <div className="commonSection why-us-section">
        <div
          className="container"
          style={{
            paddingRight: 0,
          }}
        >
          {/* <p
          className="sub_title red_color text-center"
          style={{
            marginBottom: 30,
          }}
        >
          We are your one-stop agency for all things video
        </p> */}
          <h2
            className="sec_title text-center"
            style={{
              marginBottom: 60,
            }}
          >
            Why us
          </h2>
          <div className="d-flex justify-content=center">
            <img src={whyUsImg} width="100%" alt="" />
          </div>
          {/* <div className="why-us-content row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img
                alt=""
                width="100%"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <ul>
                <li>750 + video 500 + clients, </li>
                <li>3 offices - Mumbai, Pune, Bangalore</li>
                <li>PAN India operation</li>
                <li>Expanding global footprint</li>
                <li>In house talent pool</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>

      <div className="commonSection team-home">
        <div className="container">
          <div className="employee-container">
            <div className="employee-content">
              <img src={Img1} alt="Prateek" />
              <div className="content">
                <img src={bgCircle} alt="" />
                <p className="fst-p">
                  BIG PRODUCTION HOUSE CAPABILITIES, BOUTIQUE STUDIO ADVANTAGES
                </p>
                <p>
                  <b>
                    Prateek,
                    <br />
                    Head - Production
                  </b>
                </p>
              </div>
            </div>
            <div className="employee-content">
              <img src={Img2} alt="Prateek" />
              <div className="content">
                <img src={bgCircle} alt="" />
                <p className="fst-p">We meet DEADLINES.</p>
                <p>
                  <b>
                    Rachna,
                    <br />
                    Head - Client servicing
                  </b>
                </p>
              </div>
            </div>
            <div className="employee-content">
              <img src={Img3} alt="Prateek" />
              <div className="content">
                <img src={bgCircle} alt="" />
                <p className="fst-p">We exceed EXPECTATIONS</p>
                <p>
                  <b>
                    Shreyashi,
                    <br /> Art Director
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grp-photo-container">
        <img
          src={mobileMediaQuery.matches ? MobileGrpPhoto : GrpPhoto}
          alt=""
          width="100%"
        />
        {/* <div className="grp-content">
              <p className="sec_title">
                We keep people happy by doing the LITTLE THINGS RIGHT and the
                BIG THINGS BETTER
              </p>
            </div> */}
      </div>

      {/* <motion.section
        className="commonSection featured"
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
      >
        <div className="container why-us-section">
          <div className="row">
            <div className="col-lg-5 col-sm-12  col-md-5">
              <div className="features_content">
                <h2 className="sec_title">Why us</h2>
                <div className="singleFeature">
                  <div className="f_count">01</div>
                  <h3>750 + video 500 + clients,</h3>
                </div>
                <div className="singleFeature">
                  <div className="f_count">02</div>
                  <h3>3 offices - Mumbai, Pune, Bangalore</h3>
                </div>
                <div className="singleFeature">
                  <div className="f_count">03</div>
                  <h3>PAN India operation</h3>
                </div>
                <div className="singleFeature">
                  <div className="f_count">04</div>
                  <h3>Expanding global footprint</h3>
                </div>
                <div className="singleFeature">
                  <div className="f_count">05</div>
                  <h3>In house talent pool</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-12 col-md-7 noPaddingRight">
              <div className="features_img">
                <img src="../assets-new/images/WEBP/our-approach.webp" alt="" />
                <div className="img_details">
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.section> */}
    </>
  );
};

export default WhyUsSection;
