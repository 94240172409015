import React from "react";
import { FaInstagram, FaFacebookSquare } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import { CiLinkedin } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
// import FormComponent from "./FormComponent";
import { isIOS } from "react-device-detect";
import HubspotContactForm from "../HubspotContactForm";

const FollowUsSection = () => {
  return (
    <div className="commonSection section-seven">
      <div className="container">
        <div className={`row ${isIOS && "no-padding"}`}>
          {/* <div className="left-follow-us col-lg-5 col-md-6 col-sm-12"> */}
          <h2
            className="sec_title text-center no_sub_heading"
            // style={{
            //   marginBottom: 40,
            // }}
          >
            Get in touch
          </h2>
          {/* </div> */}
          {/* <div className="right-form col-lg-7 col-md-6 col-sm-12"> */}
          {/* <FormComponent /> */}

          <HubspotContactForm formId="c1e5f66f-f658-458b-8580-153c97f9e4be" />
          <div className="follow-social-media-container">
            <a
              target="_blank"
              href="https://www.instagram.com/orange_videos/?next=%2F"
              className="follow-social-media"
              rel="noreferrer"
            >
              <span className="icon">
                <FaInstagram />
              </span>
              {/* <span className="text">Instagram</span> */}
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCTfxtQyIJyvlpwKrvLsRj7w/videos"
              className="follow-social-media"
              rel="noreferrer"
            >
              <span className="icon">
                <FiYoutube />
              </span>
              {/* <span className="text">Youtube</span> */}
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/13413575/admin/feed/posts/"
              className="follow-social-media"
            >
              <span className="icon">
                <CiLinkedin />
              </span>
              {/* <span className="text">Linkedin</span> */}
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/orangevideosofficial"
              className="follow-social-media"
            >
              <span className="icon">
                <FaFacebookSquare />
              </span>
              {/* <span className="text">Facebook</span> */}
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://twitter.com/Orange_Videos"
              className="follow-social-media"
            >
              <span className="icon">
                <FaXTwitter />
              </span>
              {/* <span className="text">Twitter</span> */}
            </a>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default FollowUsSection;
