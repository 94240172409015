import React from "react";
import { Link } from "react-router-dom";
import "../styles/clients.css";
import MetaData from "../MetaData";
import logoImg1 from "../../assets-new/images/client/coloured/1.jpg";
import logoImg2 from "../../assets-new/images/client/coloured/2.jpg";
import logoImg3 from "../../assets-new/images/client/coloured/3.jpg";
import logoImg4 from "../../assets-new/images/client/coloured/4.jpg";
import logoImg5 from "../../assets-new/images/client/coloured/5.jpg";
import logoImg6 from "../../assets-new/images/client/coloured/6.jpg";
import logoImg7 from "../../assets-new/images/client/coloured/7.jpg";
import logoImg8 from "../../assets-new/images/client/coloured/8.jpg";
import logoImg9 from "../../assets-new/images/client/coloured/9.jpg";
import logoImg10 from "../../assets-new/images/client/coloured/10.jpg";
import logoImg11 from "../../assets-new/images/client/coloured/11.jpg";
import logoImg12 from "../../assets-new/images/client/coloured/12.jpg";
import logoImg13 from "../../assets-new/images/client/coloured/13.jpg";
import logoImg14 from "../../assets-new/images/client/coloured/14.jpg";
import logoImg15 from "../../assets-new/images/client/coloured/15.jpg";
import logoImg16 from "../../assets-new/images/client/coloured/16.jpg";
import logoImg17 from "../../assets-new/images/client/coloured/17.jpg";
import logoImg18 from "../../assets-new/images/client/coloured/18.jpg";
import logoImg19 from "../../assets-new/images/client/coloured/19.jpg";
import logoImg20 from "../../assets-new/images/client/coloured/20.jpg";
import logoImg21 from "../../assets-new/images/client/coloured/21.jpg";
import logoImg22 from "../../assets-new/images/client/coloured/22.jpg";
import logoImg23 from "../../assets-new/images/client/coloured/23.jpg";
import logoImg24 from "../../assets-new/images/client/coloured/24.jpg";
import logoImg25 from "../../assets-new/images/client/coloured/25.jpg";
import logoImg26 from "../../assets-new/images/client/coloured/26.jpg";
import logoImg27 from "../../assets-new/images/client/coloured/27.jpg";
import logoImg28 from "../../assets-new/images/client/coloured/28.jpg";
import logoImg29 from "../../assets-new/images/client/coloured/29.jpg";
import logoImg30 from "../../assets-new/images/client/coloured/30.jpg";

const ClientsPage = () => {
  return (
    <>
      <MetaData
        pageTitle="Clients | Corporate Video Production Agency Mumbai - Orange Videos"
        metaDdescription="We are committed to providing our customers with exceptional service while offering our employees the best training."
      />
      {/* section 1 */}
      <section className="pageBanner client-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content text-center">
                <h4>
                  <Link to="/">home</Link> - client
                </h4>
                <h1>Clients</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section 1 */}
      <section className="commonSection featured">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="sub_title red_color">our clients</h4>
              <h2 className="sec_title">they trust us</h2>
              <p className="sec_desc">
                We are committed to providing our customers with exceptional
                service while offering our employees the best training.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg1} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg2} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg3} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg4} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg5} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg6} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg7} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg8} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg9} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg10} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg11} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg12} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg13} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg14} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg15} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg16} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg17} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg18} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg19} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg20} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg21} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg22} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg23} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg24} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg25} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg26} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg27} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg28} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-12 text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg29} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="singleClient">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={logoImg30} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientsPage;
