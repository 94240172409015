import React, { useState } from "react";
import { motion } from "framer-motion";
import headshot1 from "../../assets-new/images/WEBP/headshots/resized/headshot-1.webp";
import headshot2 from "../../assets-new/images/WEBP/headshots/resized/headshot-2.webp";
// import headshot3 from "../../assets-new/images/WEBP/headshots/resized/headshot-3.webp";
// import headshot4 from "../../assets-new/images/WEBP/headshots/resized/headshot-4.webp";
import headshot5 from "../../assets-new/images/WEBP/headshots/resized/headshot-5.webp";
import headshot6 from "../../assets-new/images/WEBP/headshots/resized/headshot-6.webp";
// import product1 from "../../assets-new/images/WEBP/product/p-1.jpg";
// import product7 from "../../assets-new/images/WEBP/product/resized/p-7.webp";
// import product3 from "../../assets-new/images/WEBP/product/resized/p-3.webp";
// import product4 from "../../assets-new/images/WEBP/product/resized/p-4.webp";
// import product5 from "../../assets-new/images/WEBP/product/resized/p-5.webp";
import product8 from "../../assets-new/images/WEBP/product/product-new/product-img1.jpg";
import product9 from "../../assets-new/images/WEBP/product/product-new/product-img2.jpg";
import product10 from "../../assets-new/images/WEBP/product/product-new/product-img3.jpg";
import product11 from "../../assets-new/images/WEBP/product/product-new/product-img4.jpg";
import product12 from "../../assets-new/images/WEBP/product/product-new/product-img5.jpg";
// import product6 from "../../assets-new/images/WEBP/product/p-6.jpg";
import aerial1 from "../../assets-new/images/WEBP/dron/d-1.jpg";
import aerial2 from "../../assets-new/images/WEBP/dron/d-2-v1.jpg";
// import aerial3 from "../../assets-new/images/WEBP/dron/d-3.webp";
import aerial4 from "../../assets-new/images/WEBP/dron/d-4.jpg";
import facility1 from "../../assets-new/images/WEBP/facility/f-1.jpg";
import facility2 from "../../assets-new/images/WEBP/facility/f-2.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const HomeSection7 = () => {
  const [activeTab, setActiveTab] = useState(0);

  const Content1 = () => {
    return (
      <div className="row d-flex align-items-center">
        <div className="col-lg-6 col-sm-12 col-md-7">
          <div className="wh_choose">
            <p>
              Headshots are your visual business card, showcasing your
              personality and professionalism in a single frame. Our headshots
              ensure that you stand out from the crowd with confidence and
              flair.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 col-md-5 grp-img vertical-img-component tabs-right-carousel">
          <Swiper
            slidesPerView={2}
            slidesPerGroup={1}
            spaceBetween={20}
            grabCursor
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            // loop={true}
            pagination={{
              clickable: true,
            }}
          >
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={headshot1} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={headshot2} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={headshot5} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={headshot6} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    );
  };

  const Content2 = () => {
    return (
      <div className="row d-flex align-items-center">
        <div className="col-lg-6 col-sm-12 col-md-7">
          <div className="wh_choose">
            <p>
              Your product's red carpet moment! Our photoshoots highlight every
              detail, from sleek design to irresistible features of the
              products. With expert lighting and styling, we turn your products
              into stars, ready to steal the spotlight on e-commerce platforms,
              social media feeds, and beyond. Say cheese to increased sales and
              endless customer admiration!
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 col-md-5 grp-img tabs-right-carousel">
          <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={20}
            grabCursor
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            // loop={true}
            pagination={{
              clickable: true,
            }}
          >
            {/* <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={product7} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={product3} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={product4} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={product5} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={product8} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={product9} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={product10} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={product11} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={product12} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          {/* <div className="sub-grp">
            <div className="chose_img">
              <img src={product1} alt="" loading="lazy" />
            </div>
            <div className="chose_img">
              <img src={product7} alt="" loading="lazy" />
            </div>
          </div>
          <div className="sub-grp">
            <div className="chose_img">
              <img src={product3} alt="" loading="lazy" />
            </div>
            <div className="chose_img">
              <img src={product4} alt="" loading="lazy" />
            </div>
          </div>
          <div className="sub-grp">
            <div className="chose_img">
              <img src={product5} alt="" loading="lazy" />
            </div>
            <div className="chose_img">
              <img src={product6} alt="" loading="lazy" />
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  const Content3 = () => {
    return (
      <div className="row d-flex align-items-center">
        <div className="col-lg-6 col-sm-12 col-md-7">
          <div className="wh_choose">
            <p>
              Reach new heights with our aerial photography. It's all about
              capturing stunning images from above in a whole new light. Whether
              it's for real estate, tourism, or simply stunning visuals, our
              aerial shots add a touch of magic to any project.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 col-md-5 grp-img tabs-right-carousel">
          <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={20}
            grabCursor
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            // loop={true}
            pagination={{
              clickable: true,
            }}
          >
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={aerial4} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={aerial2} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                <img src={aerial3} alt="" loading="lazy" />
                </div>
                </div>
                </SwiperSlide> */}
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={aerial1} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    );
  };

  const Content4 = () => {
    return (
      <div className="row d-flex align-items-center">
        <div className="col-lg-6 col-sm-12 col-md-7">
          <div className="wh_choose">
            <p>
              From sleek offices to bustling warehouses, we frame every facility
              with flair. Our shots tell tales of innovation and functionality,
              making spaces come alive in a single click.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 col-md-5 grp-img tabs-right-carousel">
          <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={20}
            grabCursor
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            // loop={true}
            pagination={{
              clickable: true,
            }}
          >
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={facility1} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sub-grp">
                <div className="chose_img">
                  <img src={facility2} alt="" loading="lazy" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    );
  };

  const tabs = [
    { title: "Headshots", content: <Content1 /> },
    { title: "Product Photoshoots", content: <Content2 /> },
    { title: "Aerial Photography", content: <Content3 /> },
    { title: "Facility Photography", content: <Content4 /> },
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <section className="commonSection chooseUs gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            {/* <h4 className="sub_title red_color">many reasons</h4> */}
            <h2 className="sec_title">PHOTOSHOOTS </h2>
            <p className="psub_heading">
              Experienced in delivering photoshoots with precision and
              creativity
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className="tabs-container">
              <ul className="tabs-list chooseUs_title">
                {tabs.map((tab, index) => (
                  <li
                    key={index}
                    className={`tabs-list-item ${
                      activeTab === index ? "active" : ""
                    }`}
                    onClick={() => handleTabClick(index)}
                  >
                    <a
                      role="button"
                      href="/"
                      onClick={(e) => e.preventDefault()}
                    >
                      {tab.title}
                    </a>
                  </li>
                ))}
              </ul>
              <div className="tabs-content">
                {tabs.map((tab, index) => (
                  <motion.div
                    key={index}
                    animate={{ opacity: activeTab === index ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ display: activeTab === index ? "block" : "none" }}
                  >
                    {tab.content}
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection7;
