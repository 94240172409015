import React from "react"
// import greyIcon1 from "../../assets-new/images/icons/grey/video-strategy.svg";
import icon1 from "../../assets-new/images/podcast-page-assets/we-will-take-up-section/icon-1.gif"
import icon2 from "../../assets-new/images/podcast-page-assets/we-will-take-up-section/icon-2.gif"
import icon3 from "../../assets-new/images/podcast-page-assets/we-will-take-up-section/icon-3.gif"
import icon4 from "../../assets-new/images/podcast-page-assets/we-will-take-up-section/icon-4.gif"
import icon5 from "../../assets-new/images/podcast-page-assets/we-will-take-up-section/icon-5.gif"
import icon6 from "../../assets-new/images/podcast-page-assets/we-will-take-up-section/icon-6.gif"
import icon7 from "../../assets-new/images/podcast-page-assets/we-will-take-up-section/icon-7.gif"
import icon8 from "../../assets-new/images/podcast-page-assets/we-will-take-up-section/icon-8.gif"
import leftImg from "../../assets-new//images//podcast-page-assets/uat-imgs/end-to-end-production.png"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import { Navigation, Pagination } from "swiper/modules"
import "swiper/css";
import { GrNext, GrPrevious } from "react-icons/gr";

const EndToEndProductionServicesSection = () => {

    const SwiperButtonNext = ({ children }) => {
        const swiper = useSwiper();
        return <button onClick={() => swiper.slideNext()}>{children}</button>;
    };

    const SwiperButtonPrev = ({ children }) => {
        const swiper = useSwiper();
        return <button onClick={() => swiper.slidePrev()}>{children}</button>;
    };

    const contentObj = [
        {
            frontImg: icon1,
            frontHeading: "Ideation and Consultation",
            backContent: "Confused about the topic? We will help you with themes and ideas.",
        },
        {
            frontImg: icon2,
            frontHeading: "IP Creation",
            backContent: "We will develop your podcast’s name, logo, art work, theme music, etc.",
        },
        {
            frontImg: icon3,
            frontHeading: "Content Planning",
            backContent: "List of potential topics and scripts for the entire podcast series",
        },
        {
            frontImg: icon4,
            frontHeading: "Guest Planning",
            backContent: "Listing relevant guests to cover the topics",
        },
        {
            frontImg: icon5,
            frontHeading: "Studio Selection",
            backContent: "Studio / Set up selection in line with podcast’s theme and line of guests.",
        },
        {
            frontImg: icon6,
            frontHeading: "Production and Recording",
            backContent: "4K recording with professional lighting, sound console, podcast mics, etc.",
        },
        {
            frontImg: icon7,
            frontHeading: "Post-production",
            backContent: "Professional audio mastering & noise correction.",
        },
        {
            frontImg: icon8,
            frontHeading: "Distribution",
            backContent: "Publishing podcasts on YouTube, Apple Podcasts, Spotify, Google Podcasts and others.",
        },
    ]

    const mobileMediaQuery = window.matchMedia("(max-width: 500px)")

    return (
        <div className="commonSection end-to-end-pro-container">
            <div className="left-img">
                <img src={leftImg} alt="left img" />
            </div>
            <div className="container end-to-end-production-services">
                <h3 className="sec_title text-center">End-to-end Podcast Production Services</h3>
                <div className="box-container">
                    {/* // <React.Fragment> */}
                    {/* <div className="col-lg-3 col-md-4 col-sm-12 flip-content">
                        <div className="flip-box">
                            <div className="flip-box-inner">
                                <div className="flip-box-front">
                                    <div>
                                        <img src={data.frontImg} alt="flip images" />
                                        <h4>{data.frontHeading}</h4>
                                    </div>
                                </div>
                                <div className="flip-box-back">
                                    <div>
                                        <p>{data.backContent}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {
                        mobileMediaQuery.matches
                            ?
                            <>
                                <Swiper
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    // loop={true}
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    autoHeight={true}
                                    autoplay={true}
                                >
                                    <div
                                        className="logo-swiper-bottom-buttons left-btn"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <SwiperButtonPrev>
                                            <div className="btn-left">
                                                <GrPrevious />
                                            </div>
                                        </SwiperButtonPrev>
                                    </div>
                                    {
                                        contentObj.map((data, ind) => (
                                            <SwiperSlide key={ind}>
                                                <div className="content-container">
                                                    <div className="img-container">
                                                        {/* <img src={data.frontImg} alt="icons" /> */}
                                                        <span className={`num-steps num-steps-${ind + 1}`}>
                                                            <span>
                                                                {ind + 1}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="content">
                                                        <h4>
                                                            {data.frontHeading}
                                                        </h4>
                                                        <p>
                                                            {data.backContent}
                                                        </p>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }
                                    <div className="logo-swiper-bottom-buttons right-btn">
                                        <SwiperButtonNext>
                                            <div className="btn-right">
                                                <GrNext />
                                            </div>
                                        </SwiperButtonNext>
                                    </div>
                                </Swiper>
                            </>
                            :
                            <>
                                {
                                    contentObj.map((data, ind) => (

                                        <div className="content-container" key={ind}>
                                            <div className="img-container">
                                                {/* <img src={data.frontImg} alt="icons" /> */}
                                                <span className={`num-steps num-steps-${ind + 1}`}>
                                                    <span>
                                                        {ind + 1}
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="content">
                                                <h4>
                                                    {data.frontHeading}
                                                </h4>
                                                <p>
                                                    {data.backContent}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                    }

                </div>
                {/* </React.Fragment> */}
            </div>
        </div>
    )
}

export default EndToEndProductionServicesSection;