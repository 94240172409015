import React, { useEffect, useState } from "react"
// import { FaPlay } from "react-icons/fa";
// import { AppUseContext } from "../AppContext";
import VideoPopup from "../VideoPopup";
import { IoMdClose } from "react-icons/io";
import { useLocation } from "react-router-dom";

const Banner = () => {
    // const { toggleVideoPopup } = AppUseContext();
    const [showPopupForm, setPopupForm] = useState(false)

    const handleformPopup = () => {
        setPopupForm(!showPopupForm)
        document.body.style.overflow = !showPopupForm ? "hidden" : "auto"
    }

    const [formId, setFormId] = useState("")

    const pathname = useLocation();

    useEffect(() => {
        if (pathname.pathname === "/podcast-mumbai") {
            setFormId("a7cfea0d-54cc-492f-a32d-87d87055ffca")
        }
        else if (pathname.pathname === "/podcast-bangalore") {
            setFormId("b95f8e61-559b-43aa-a05c-5d87bac6d85d")
        }
    }, [pathname])

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/embed/v2.js";
        document.body.appendChild(script);

        // region: "na1",
        script.addEventListener("load", () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: "46497548",
                    formId: formId,
                    target: "#popupForm",
                });
            }
        });
        // eslint-disable-next-line
    }, [pathname, formId, showPopupForm]);

    const mobileMediaQuery = window.matchMedia("(max-width: 500px)")
    return (
        <>
            {
                showPopupForm
                &&
                <div className="form-popup-container">
                    <div className="form-media">
                        {/* <!--begin register-form-wrapper--> */}
                        <div className="register-form-wrapper">
                            <div className="close" onClick={handleformPopup}>
                                <IoMdClose />
                            </div>
                            {/* <h3 className="mb-2">
                Need a professional photoshoot for Ecommerce?
              </h3> */}

                            <p className="mb-4"><b>Get an estimate within 24 hours</b></p>

                            {/* <!--begin form--> */}
                            <div>
                                {/* <!--begin success message --> */}
                                <p
                                    className="register_success_box"
                                    style={{ display: "none" }}
                                >
                                    Just drop a message. We will get back within 24 hours.
                                </p>
                                {/* <!--end success message --> */}

                                {/* <!--begin register form --> */}

                                <div id="popupForm"></div>
                                {/* <!--end register form --> */}
                            </div>
                            {/* <!--end form--> */}
                        </div>
                        {/* <!--end register-form-wrapper--> */}
                    </div>
                </div>
            }

            <div className="podcast-banner home-section">
                <div className="home-section-overlay"></div>
                <div className="content container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h1>Turn inspiring ideas
                                {
                                    !mobileMediaQuery.matches
                                        ?
                                        <br />
                                        :
                                        " "
                                }
                                into<span> impactful podcasts</span></h1>
                            <p>Professional Podcast Production Agency in Mumbai</p>
                            <a href="/" className="guide-btn" onClick={(e) => {
                                e.preventDefault()
                                handleformPopup()
                            }}>LAUNCH YOUR PODCAST</a>
                            {/* <h1>LAUNCH YOUR VIDEO PODCAST SERIES WITH <span>ORANGE VIDEOS</span></h1>
                            <p>Start your Video Podcast series with us</p> */}
                            {/* <a href="/" className="video-listening-btn" onClick={(e) => {
                                toggleVideoPopup({
                                    videoSrc:
                                        "https://www.youtube.com/embed/JGV05ck7U0g?si=CeF2FuVJbZ8s4yvc",
                                    param: e,
                                });
                            }}>
                                <span>
                                    <FaPlay />
                                </span>
                                Start Listening
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
            <VideoPopup />
        </>
    )
}

export default Banner;