// import axios from "axios";
import React, { useEffect, useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { CiMail } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { AppUseContext } from "../AppContext";

const TestSideForm = ({ hbsptFormId }) => {
  const { hubspotScriptSrc } = AppUseContext();

  // const recaptcha = useRef();

  const pathname = useLocation();

  // const navigate = useNavigate();

  // const [typeWeb, setTypeWeb] = useState("");

  // const [webLocation, setWebLocation] = useState("");

  // const [isLoading, setIsLoading] = useState(false);

  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   company_name: "",
  //   website_url: "",
  // });

  // const [hubspotFormId, setHubspotFormId] = useState("");

  // useEffect(() => {
  //   if (
  //     pathname.pathname === "/animation-explainer-video-bangalore" ||
  //     pathname.pathname === "/animation-explainer-video-bangalore-test"
  //   ) {
  //   } else if (
  //     pathname.pathname === "/animation-explainer-video-mumbai" ||
  //     pathname.pathname === "/animation-explainer-video-mumbai-test"
  //   ) {
  //   } else if (
  //     pathname.pathname === "/animation-explainer-video-pune" ||
  //     pathname.pathname === "/animation-explainer-video-pune-test"
  //   ) {
  //   } else if (
  //     pathname.pathname === "/corporate-video-production-mumbai" ||
  //     pathname.pathname === "/corporate-video-production-mumbai-test"
  //   ) {
  //   } else if (
  //     pathname.pathname === "/corporate-video-production-bangalore" ||
  //     pathname.pathname === "/corporate-video-production-bangalore-test"
  //   ) {
  //   } else if (
  //     pathname.pathname === "/corporate-video-production-pune" ||
  //     pathname.pathname === "/corporate-video-production-pune-test"
  //   ) {
  //   }
  // }, [pathname]);

  // useEffect(() => {
  //   if (
  //     pathname.pathname === "/animation-explainer-video-bangalore" ||
  //     pathname.pathname === "/animation-explainer-video-pune" ||
  //     pathname.pathname === "/animation-explainer-video-mumbai"
  //   ) {
  //     setTypeWeb("Animation");
  //   } else if (
  //     pathname.pathname === "/corporate-video-production-bangalore" ||
  //     pathname.pathname === "/corporate-video-production-pune" ||
  //     pathname.pathname === "/corporate-video-production-mumbai"
  //   ) {
  //     setTypeWeb("Corparate");
  //   }

  //   if (
  //     pathname.pathname === "/animation-explainer-video-bangalore" ||
  //     pathname.pathname === "/corporate-video-production-bangalore"
  //   ) {
  //     setWebLocation("Bangalore");
  //   } else if (
  //     pathname.pathname === "/animation-explainer-video-pune" ||
  //     pathname.pathname === "/corporate-video-production-pune"
  //   ) {
  //     setWebLocation("Pune");
  //   } else if (
  //     pathname.pathname === "/animation-explainer-video-mumbai" ||
  //     pathname.pathname === "/corporate-video-production-mumbai"
  //   ) {
  //     setWebLocation("Mumbai");
  //   }
  // }, [pathname]);
  const [scrollShowForm, setScrollShowForm] = useState(false);
  useEffect(() => {
    const winScroll = () => {
      if (document.documentElement.scrollTop > 800) {
        setScrollShowForm(true);
      } else {
        setScrollShowForm(false);
      }
    };
    window.addEventListener("scroll", winScroll);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = hubspotScriptSrc;
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "46497548",
          // added condition below because if condition is not kept it will give an error
          formId: hbsptFormId,
          target: "#sideHubspotForm",
        });
      }
    });
    // eslint-disable-next-line
  }, [pathname, hbsptFormId]);

  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //     from_website_location: webLocation,
  //     type_website: typeWeb,
  //   });
  // };

  //   useEffect(() => {
  //     console.log(formData);
  //   }, [formData]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const form = new FormData();
  //   Object.keys(formData).forEach((key) => {
  //     form.append(key, formData[key]);
  //   });

  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       "https://lp-side-mail.glitch.me/submit",
  //       form,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     console.log(response.data);
  //     setFormData({
  //       name: "",
  //       email: "",
  //       phone: "",
  //       company_name: "",
  //       website_url: "",
  //     });

  //     navigate(
  //       `/${typeWeb.toLowerCase()}-video-production-${webLocation.toLowerCase()}/thank-you`
  //     );

  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error(
  //       "Error sending email:",
  //       error.response ? error.response.data : error.message
  //     );
  //   }
  // };

  const [activeSideForm, setActiveSideForm] = useState(false);

  // const handlePlaceholder = (e) => {
  //   e.target.placeholder = "";
  // };
  return (
    <>
      {/* {scrollShowForm && ( */}
      <div
        className="side-form-component"
        style={{
          display: scrollShowForm ? "block" : "none",
        }}
      >
        <div
          className={`side-mail-container ${activeSideForm && "activeMailIcon"
            }`}
          onClick={() => setActiveSideForm(!activeSideForm)}
        >
          {activeSideForm ? <IoClose /> : <CiMail />}
        </div>
        <div
          className={`sidebar-contact ${activeSideForm && "active"
            } form-container-parent`}
        >
          <div className="toggle"></div>
          <h5>Send us your Enquiry</h5>
          <div className="scroll">
            <div id="sideHubspotForm"></div>
            {/* <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Name*"
                  name="name"
                  id="name1"
                  onChange={handleChange}
                  required={true}
                  onFocus={handlePlaceholder}
                />
                <input
                  type="email"
                  placeholder="Email Address*"
                  name="email"
                  id="email1"
                  onChange={handleChange}
                  required={true}
                  onFocus={handlePlaceholder}
                />
                <input
                  type="text"
                  placeholder="Mobile No.*"
                  name="phone"
                  id="phone1"
                  onChange={handleChange}
                  required={true}
                  minLength={10}
                  maxLength={10}
                  onFocus={handlePlaceholder}
                />
                <input
                  type="text"
                  placeholder="Company Name*"
                  name="company_name"
                  id="c_name1"
                  onChange={handleChange}
                  required={true}
                  onFocus={handlePlaceholder}
                />
                <input
                  type="text"
                  placeholder="Website URL*"
                  name="website_url"
                  id="website1"
                  onChange={handleChange}
                  required={true}
                  onFocus={handlePlaceholder}
                />
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={recaptchaSiteKey}
                  style={{
                    marginBottom: "20px",
                  }}
                />
                <input
                  type="submit"
                  name="floatSubmit"
                  id="floatSubmit"
                  value={isLoading ? "Submitting..." : "Submit"}
                  className="button w-100"
                  disabled={isLoading}
                />
              </form> */}
            {/* {isLoading && (
                <div className="form-submiting">
                  <div class="loader"></div>
                </div>
              )} */}
          </div>
        </div>
      </div>
      {/* // )} */}
    </>
  );
};
export default TestSideForm;
