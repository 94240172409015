import { useEffect, useState } from "react";
import NavPopup from "./NavPopup";
import { Link, useLocation } from "react-router-dom";
// import { AppUseContext } from "./AppContext";
import LogoImg from "../assets-new/images/logo (1).svg";
import { CiMenuBurger } from "react-icons/ci";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { AppUseContext } from "./AppContext";
import { SiGoogleforms } from "react-icons/si";

const NavBar = () => {
  const pathname = useLocation();
  const { pageErr, isLandingPageVisible, isEcommerceLpVisibleChangeColor, isPodcastPageVisible } =
    AppUseContext();
  // const [checkPath, setCheckPath] = useState(false);
  const [checkLandingPage, setCheckLandingPage] = useState(false);
  const [changeScrollClassName, setChangeScrollClassName] = useState("");
  const [landingScrollClassName, setLandingScrollClassName] = useState("");
  // useEffect(() => {
  //   setIsOnHomepage(false);
  // }, []);
  const [scrollPopupFormCondition, setScrollPopupFormCondition] = useState(false)

  // const [checkPathName, setCheckPathName] = useState("");
  useEffect(() => {
    const winScroll = () => {
      if (document.documentElement.scrollTop > 10) {
        setChangeScrollClassName("fixedHeader animated flipInX");
      } else {
        setChangeScrollClassName("");
      }

      if (document.documentElement.scrollTop > 300) {
        setLandingScrollClassName("opaque");
      } else {
        setLandingScrollClassName("");
      }

      if (document.documentElement.scrollTop > 400) {
        setScrollPopupFormCondition(true)
        // console.log(scrollPopupFormCondition)
      } else {
        setScrollPopupFormCondition(false)
      }
    };
    window.addEventListener("scroll", winScroll);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // setCheckPath(pathname.pathname === "/test");
    setCheckLandingPage(
      pathname.pathname === "/animation-explainer-video-pune" ||
      pathname.pathname === "/animation-explainer-video-mumbai" ||
      pathname.pathname === "/animation-explainer-video-bangalore" ||
      pathname.pathname === "/corporate-video-production-mumbai" ||
      pathname.pathname === "/corporate-video-production-bangalore" ||
      pathname.pathname === "/corporate-video-production-pune" ||
      pathname.pathname === "/animation-explainer-video-bangalore-test" ||
      pathname.pathname === "/animation-explainer-video-bangalore-test" ||
      pathname.pathname === "/animation-explainer-video-bangalore-test"
    );
  }, [pathname.pathname]);


  // useEffect(() => {
  //   const windowScroll = () => {

  //   }

  //   window.addEventListener("scroll", windowScroll)
  // }, [])

  const [showPopup, setShowPopup] = useState(false);

  const handlePopup = () => {
    setShowPopup(!showPopup);

    document.body.style.overflow = !showPopup ? "hidden" : "scroll";
  };

  const mobileMediaQuery = window.matchMedia("(max-width: 800px)");

  const [isMenuActive, setIsMenuActive] = useState(false);

  const lpMenuVariiant = {
    open: { height: "100%", opacity: 1, y: 0 },
    close: { height: "0", opacity: 0, y: -1000 },
  };

  const [podcastPopupForm, setPodcastPopupForm] = useState(false)
  const handlePodcastPopupForm = (e) => {
    e.preventDefault()
    setPodcastPopupForm(!podcastPopupForm)
    document.body.style.overflow = !podcastPopupForm ? "hidden" : "auto"
  }
  return (
    <>
      {
        !isPodcastPageVisible
        &&
        <>
          {!pageErr && (
            <>
              {/* landing page nav bar below */}
              {isLandingPageVisible ? (
                <header className="header landing-header">
                  <nav
                    className={`navbar navbar-default navbar-fixed-top ${isEcommerceLpVisibleChangeColor && "changeToWhite"} ${landingScrollClassName}`}
                  >
                    {/* <!--begin container --> */}
                    <div className="container">
                      {/* <!--begin navbar --> */}
                      <nav className="navbar navbar-expand-lg">
                        {/* <!--begin logo --> */}
                        {/* eslint-disable */}
                        <Link className="navbar-brand" to={pathname.pathname}>
                          <img src={LogoImg} alt="" />
                        </Link>
                        {/* <!--end logo --> */}

                        {/* <!--begin navbar-toggler --> */}

                        {/* <!--end navbar-toggler --> */}

                        {/* <!--begin navbar-collapse --> */}
                        {/* <div
                  className="navbar-collapse collapse"
                  id="navbarCollapse"
                  aria-expanded="false"
                > */}
                        {/* <!--begin navbar-nav --> */}
                        {!mobileMediaQuery.matches && (
                          <ul className="navbar-nav ml-auto">
                            <li
                              className="current"
                              data-to-scrollspy-id="home"
                            // activeClass="active-scrollspy"
                            >
                              <a href="#home">Home</a>
                            </li>

                            {
                              !isEcommerceLpVisibleChangeColor
                              &&
                              <>
                                <li
                                  data-to-scrollspy-id="services"
                                >
                                  <a href="#services">Services</a>
                                </li>
                              </>
                            }

                            <li>
                              <a href="#why-hire-us">Why Hire Us?</a>
                            </li>
                            <li>
                              <a href="#our-work">Our Work</a>
                            </li>

                            {
                              !isEcommerceLpVisibleChangeColor
                              &&
                              <li>
                                <a href="#our-clients">Clients</a>
                              </li>
                            }

                            <li className="discover-link current">
                              <a href="#home" className="contact-now">
                                Contact
                              </a>
                            </li>

                            <li>
                              {/* {isEcommerceLandingPage ? (
                            <a href="tel:+91 8356056796">+91 8356056796</a>
                          ) : ( */}
                              <a href="tel:+91 9867409221">+91 9867409221</a>
                              {/* // )} */}
                            </li>
                          </ul>
                        )}
                        {/* <!--end navbar-nav --> */}
                        {/* </div> */}

                        {/* <!--end navbar-collapse --> */}
                        {mobileMediaQuery.matches && (
                          <button
                            className="navbar-toggler-lp"
                            onClick={() => {
                              setIsMenuActive(!isMenuActive);
                            }}
                          >
                            <CiMenuBurger />
                          </button>
                        )}
                      </nav>
                      {/* <!--end navbar --> */}
                    </div>
                    {/* <!--end container --> */}
                    <AnimatePresence>
                      {isMenuActive && (
                        <motion.ul
                          className="navbar-nav ml-auto ul-for-tablet"
                          variants={lpMenuVariiant}
                          exit="close"
                          initial="close"
                          animate="open"
                          transition={{ duration: 0.5 }}
                        // style={{
                        //   position: "fixed",
                        // }}
                        >
                          <li
                            className="current"
                            data-to-scrollspy-id="home"
                          // activeClass="active-scrollspy"
                          >
                            <a href="#home">Home</a>
                          </li>

                          {
                            !isEcommerceLpVisibleChangeColor
                            &&
                            <>
                              <li
                                data-to-scrollspy-id="services"
                              // activeClass="active-scrollspy"
                              >
                                <a href="#services">Services</a>
                              </li>
                            </>
                          }
                          <li>
                            <a href="#why-hire-us">Why Hire Us?</a>
                          </li>
                          <li>
                            <a href="#our-work">Our Work</a>
                          </li>

                          {
                            !isEcommerceLpVisibleChangeColor
                            &&
                            <li>
                              <a href="#our-clients">Clients</a>
                            </li>
                          }

                          <li className="discover-link current">
                            <a href="#home" className="contact-now">
                              Contact
                            </a>
                          </li>

                          <li>
                            <a href="tel:+91 9867409221">+91 9867409221</a>
                          </li>
                        </motion.ul>
                      )}
                    </AnimatePresence>
                  </nav>
                </header>
              ) : (
                // below nav bar on all pages
                <header
                  className={`header_01 ${changeScrollClassName} ${checkLandingPage && "landing-page-active"} ${pathname.pathname === "/thank-you" && "dk-bg"
                    }`}
                  // style={{
                  //   boxShadow: changeScrollClassName === "" ? "0px 1px #dadada" : "unset",
                  // }}
                  id="header"
                >
                  <div className="container">
                    <div className="row">
                      <div className="mobile-nav tablet-view">
                        <div className="col-lg-2 col-sm-3 col-md-2 left-logo-container">
                          <Link to="/" className="logo-link">
                            <div className="logo">
                              <img src={LogoImg} alt="" />
                            </div>
                          </Link>
                        </div>
                        {
                          !isPodcastPageVisible
                          &&
                          <>
                            {mobileMediaQuery.matches && (
                              <div className="col-lg-2 col-sm-2 col-md-2">
                                <div className="mobile-bar-right">
                                  <a
                                    id="open-overlay-nav"
                                    className="menu hamburger"
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handlePopup();
                                    }}
                                  >
                                    {/* <i className="fa-solid fa-bars mei-menu"></i> */}
                                    <CiMenuBurger />
                                  </a>
                                </div>
                              </div>
                            )}
                          </>
                        }
                      </div>
                      <div
                        className="col-lg-10 col-sm-7 col-md-10"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <nav className="mainmenu text-center">
                          <ul>
                            {/* <!--<li className="menu-item-has-children">-->
                                        <!--    <a href="./">home</a>-->
                                            <!--<ul className="sub-menu">
                                        <!--        <li><a href="index.html">Home 01</a></li>-->
                                        <!--        <li><a href="index2.html">Home 02</a></li>-->
                                        <!--        <li><a href="index3.html">Home 03</a></li>-->
                                        <!--    </ul>-->
                                        <!--</li>--> */}
                            {
                              !isPodcastPageVisible
                              &&
                              <>
                                <li>
                                  <Link to="/about-us">about us</Link>
                                </li>
                                <li>
                                  <Link to="/video-services">services</Link>
                                </li>
                                <li>
                                  <Link to="portfolio">Video Categories</Link>
                                  {/* <!--<ul className="sub-menu">
                                                <li><a href="portfolio.html">Portfolio v1</a></li>
                                                <li><a href="portfolio2.html">Portfolio v2</a></li>
                                                <li><a href="portfolio_detail.html">Portfolio Detail</a></li>
                                            </ul>--> */}
                                </li>
                                <li className="d-inline-flex align-items-center">
                                  <Link to="/careers">
                                    <span>careers </span>
                                  </Link>
                                  {/* <Link className="career-a" to="/careers">
                              <span className="apply-now">Apply</span>{" "}
                            </Link> */}
                                  {/* <!--<ul className="sub-menu">
                                                <li><a href="blog.html">Blog v1</a></li>
                                                <li><a href="blog2.html">Blog v2</a></li>
                                                <li><a href="blog3.html">Blog v3</a></li>
                                                <li><a href="blog_single.html">Blog Single</a></li>
                                            </ul>--> */}
                                </li>
                                <li>
                                  <Link to="/insights">Blog</Link>
                                </li>
                                <li>
                                  <Link
                                    to="tel:9867409221"
                                    style={{
                                      fontWeight: 700,
                                    }}
                                  >
                                    +91 9867409221
                                  </Link>
                                </li>
                              </>
                            }
                            <li
                              style={{
                                paddingRight: 2,
                              }}
                            >

                              {
                                isPodcastPageVisible
                                  ?
                                  <>
                                    {
                                      changeScrollClassName !== ""
                                        ?
                                        <a href="#get-in-touch" className="contact-btn for-podcast" onClick={handlePodcastPopupForm}>
                                          GET IN TOUCH
                                        </a>
                                        :
                                        null
                                    }
                                  </>
                                  :
                                  <Link to="/contact" className="contact-btn">
                                    Contact
                                  </Link>
                              }

                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </header>
              )}

              {/* only podcast popupform */}
              {
                mobileMediaQuery.matches
                &&
                <>
                  {
                    isPodcastPageVisible
                    &&
                    <>
                      {
                        scrollPopupFormCondition
                        &&
                        <a href="/" className="podcast-popup-mobile-form" onClick={handlePodcastPopupForm}>
                          <SiGoogleforms />
                        </a>
                      }
                    </>
                  }
                </>
              }

              {/* menu on mobile dropdown */}
              <NavPopup clickFunc={handlePopup} showPopup={showPopup} handlePodcastPopupForm={handlePodcastPopupForm} podcastPopupForm={podcastPopupForm} />
            </>
          )}
        </>
      }
    </>
  );
};

export default NavBar;
