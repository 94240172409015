import React from 'react'
import { FaPlay } from "react-icons/fa";
import VideoPopup from '../VideoPopup';
import { AppUseContext } from '../AppContext';

function CaseStudies() {
    const { toggleVideoPopup } = AppUseContext()
    const videoObj = [
        {
            imgSrc: "https://img.youtube.com/vi/BLHUYNRv6LY/maxresdefault.jpg",
            iframeVidSrc: "https://www.youtube.com/embed/BLHUYNRv6LY?si=RVMvGp-3nTNM2GaY"
        },
        // {
        //     imgSrc: "https://img.youtube.com/vi/LHizRSVHp4c/maxresdefault.jpg",
        //     iframeVidSrc: "https://www.youtube.com/embed/LHizRSVHp4c?si=DyyQI58snc_JAUXH"
        // },
        {
            imgSrc: "https://img.youtube.com/vi/YAISYCF_jcw/maxresdefault.jpg",
            iframeVidSrc: "https://www.youtube.com/embed/YAISYCF_jcw?si=P2O9lneKHafd2qN7"
        },
    ]
    return (
        <>
            <div className='commonSection podcast-case-studies'>
                <div className="container">
                    <h3 className="sec_title text-center">Case Studies</h3>

                    <div className="box row">
                        {
                            videoObj.map((src, ind) => {
                                return (
                                    <div className='video-container col-lg-6 col-md-6 col-sm-12' key={ind} style={{ marginTop: "10px" }}>
                                        <img src={src.imgSrc} alt="case studies videos" width="100%" />
                                        <div className="icon-container">
                                            <span onClick={(e) => {
                                                toggleVideoPopup({
                                                    videoSrc: src.iframeVidSrc,
                                                    param: e,
                                                });
                                            }}>
                                                <FaPlay />
                                            </span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <VideoPopup />
        </>
    )
}

export default CaseStudies