// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles/contact-us.css";
import { motion } from "framer-motion";
// import emailjs from "@emailjs/browser";
import MetaData from "./MetaData";
// import ReCAPTCHA from "react-google-recaptcha";
// import { AppUseContext } from "./AppContext";
import HubspotContactForm from "./HubspotContactForm";
import HubspotTrackerScript from "./HubshopTrackerScript";
import { AppUseContext } from "./AppContext";

const ContactTest = () => {
  const { setDisableFooter, setIsPodcastPageVisible } = AppUseContext()
  // const [formData, setFormData] = useState({
  //   contact_name: "",
  //   contact_email: "",
  //   contact_phone: "",
  //   contact_org_name: "",
  //   contact_designation: "",
  //   contact_service: "",
  //   contact_budget: "",
  //   contact_deadline: "",
  //   contact_message: "",
  // });

  // const { recaptchaSiteKey } = AppUseContext();

  // const recaptcha = useRef();

  // const [errors, setErrors] = useState({});

  const pathname = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setDisableFooter(false)
    setIsPodcastPageVisible(false)
    // eslint-disable-next-line
  }, [pathname]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: "",
  //   }));
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Validate the form
  //   const validationErrors = {};
  //   Object.keys(formData).forEach((key) => {
  //     if (key !== "contact_phone" && !formData[key]) {
  //       validationErrors[key] = "This field is required";
  //     }
  //   });
  //   setErrors(validationErrors);

  //   // If there are validation errors, don't submit the form
  //   if (Object.keys(validationErrors).length > 0) {
  //     return;
  //   }

  //   // Proceed with sending the email
  //   const templateParams = { ...formData };

  //   // Add your emailjs service ID and template ID
  //   const serviceId = "service_f8m9w0q";
  //   const templateId = "template_84zevss";
  //   const userId = "ERewmc7EkKcK16ruN";

  //   const captchaValue = recaptcha.current.getValue();

  //   if (!captchaValue) {
  //     alert("Please verify recaptcha");
  //   } else {
  //     emailjs.send(serviceId, templateId, templateParams, userId).then(
  //       (response) => {
  //         console.log("Email sent successfully:", response);
  //         // Clear the form after successful submission
  //         setFormData({
  //           contact_name: "",
  //           contact_email: "",
  //           contact_phone: "",
  //           contact_org_name: "",
  //           contact_designation: "",
  //           contact_service: "",
  //           contact_budget: "",
  //           contact_deadline: "",
  //           contact_message: "",
  //         });
  //         navigate("/thank-you");
  //       },
  //       (error) => {
  //         console.error("Error sending email:", error);
  //       }
  //     );
  //   }
  // };

  // const handlePlaceholder = (e) => {
  //   e.target.placeholder = "";
  // };

  return (
    <>
      <HubspotTrackerScript />
      <MetaData
        pageTitle="Contact Us | Explainer Video Production Company Mumbai - Orange Videos"
        metaDdescription="We welcome our associates to come as they are and help them excel in what they love. Our culture is defined by a celebration of the diversity of experiences and work styles."
        isContactPage={true}
      />

      {/* section 1 */}
      <section
        className="pageBanner contact-us-banner"
      // initial="hide"
      // whileInView="view"
      // viewport={{ once: true }}
      // variants={{
      //   view: { opacity: 1, y: 0 },
      //   hide: { opacity: 0, y: 100 },
      // }}
      // transition={{ duration: 1 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content text-center">
                <h4>
                  <Link to="/">home</Link> - contact
                </h4>
                <h1>Contact us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 2 */}
      <motion.section
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
        className="commonSection ContactPage"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="sub_title">Contact with us</h4>
              <h2 className="sec_title">we would love to hear from you!</h2>
              <p className="sec_desc">
                Tell us a bit about your project needs (timeline, budget, etc.)
                and we will get back to you within 24 hours.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-lg-offset-2 col-sm-12 col-md-10 col-md-offset-1">
              <HubspotContactForm
                portalId="46497548"
                formId="c1e5f66f-f658-458b-8580-153c97f9e4be"
                region="na1"
              />
            </div>
          </div>
        </div>
      </motion.section>

      <section className="commonSection client_2 featured grey-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="sec_title">our offices</h2>
              <p className="psub_heading">
                Orange Videos is based in Mumbai, India. Our in-house team is
                made up of creative professionals that work closely with more
                than 100 creatives all around the globe to create out-of-the-box
                video content, customized for your needs.
              </p>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-lg-6 col-sm-6 col-md-3">
              <div className="singleClient_2">
                <h3>Mumbai</h3>
                {/* <!--<p>C-208, 2nd Floor, Building 5,</p>-->
                            <!--<p>Mittal Estate, Andheri-Kurla Road,</p>-->
                            <!--<p>Andheri East, Mumbai 400059</p>--> */}

                <p>Orange Videos Production LLP</p>
                <p>401-A, Town Center 2, Andheri Kurla Road,</p>
                <p>Andheri East, Mumbai 400059</p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-md-3">
              <div className="singleClient_2 h-100">
                {/* <!--<h3>Delhi</h3>-->
                            <!--<p>91 Springboard, Plot No. 23, HSIDC </p>-->
                            <!--<p>Maruti Industrial Area, Sector 18,  </p>-->
                            <!--<p>Gurgaon, Haryana 122015. </p>--> */}
                <h3>Bangalore</h3>
                {/* <!-- <p>Church Street,<br> Bangalore 560001</p> --> */}

                <p>Om Chambers</p>
                <p>648/A-9 Binnamangala 1st Stage </p>
                <p>Indiranagar, Bangalore - 560038</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactTest;
