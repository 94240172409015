// import React, { useEffect, useState } from "react";
// import { IoCloseSharp } from "react-icons/io5";
// import { AnimatePresence, motion } from "framer-motion";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { IoPlayOutline } from "react-icons/io5";
import entropik from "../../assets-new/images/work-showcase/new/img-1.jpg";
import ZeroB from "../../assets-new/images/work-showcase/new/img-2.jpg";
import zeky from "../../assets-new/images/work-showcase/new/img-3.jpg";
import roadSafety from "../../assets-new/images/work-showcase/new/img-4.jpg";
import proxima from "../../assets-new/images/work-showcase/new/img-5.jpg";
import netsmartz from "../../assets-new/images/work-showcase/new/img-6.jpg";

import entropikOld from "../../assets-new/images/work-showcase/entropik.jpg";
import ZeroBOld from "../../assets-new/images/work-showcase/zero-b.jpg";
import zekyOld from "../../assets-new/images/work-showcase/zeky-v1.webp";
import roadSafetyOld from "../../assets-new/images/work-showcase/road-safety-v1.webp";
import proximaOld from "../../assets-new/images/work-showcase/proxima.jpg";
import netsmartzOld from "../../assets-new/images/work-showcase/netsmartz-v1.webp";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { GrNext, GrPrevious } from "react-icons/gr";
import VideoPopup from "../VideoPopup";
import { AppUseContext } from "../AppContext";

const Section5 = () => {
  const { toggleVideoPopup } = AppUseContext();
  const SwiperButtonNext = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slideNext()}>{children}</button>;
  };

  const SwiperButtonPrev = ({ children }) => {
    const swiper = useSwiper();
    return <button onClick={() => swiper.slidePrev()}>{children}</button>;
  };
  // const [showPopup, setShowPopup] = useState(false);

  // const [videoSrc, setVideoSrc] = useState("");

  // useEffect(() => {
  //   document.body.style.overflowY = showPopup ? "hidden" : "auto";
  // }, [showPopup]);

  // const handlePopup = () => {
  //   setShowPopup(!showPopup);
  // };

  const videoObj = [
    {
      id: "34",
      category: "Corporate Videos",
      cateType: "Corporate Videos",
      imgSrc: entropik,
      name: "Corporate Video for Entropik",
      yt: "https://www.youtube.com/embed/-AFrG_qzQ2Y?si=K1Cd1-Io0Gsh12_7",
    },
    {
      category: "Corporate Videos",
      cateType: "Corporate Videos",
      imgSrc: ZeroB,
      name: "DVC for Zero B",
      id: "1",
      yt: "https://www.youtube.com/embed/1dithDlBi8Y?si=nF9daPqVXsGcFZa8",
    },
    {
      category: "animation",
      id: "2",
      cateType: "3d animation",
      imgSrc: zeky,
      name: "Showreel for ZVKY",
      yt: "https://www.youtube.com/embed/jpVgjqfwIHo?si=sJWypdLKh5HJCMmt",
    },
    {
      id: "3",
      category: "photoshoot",
      cateType: "photoshoot",
      imgSrc: roadSafety,
      name: "2D animation for Total Oil",
      yt: "https://www.youtube.com/embed/GNVI00z5qX8?si=-t_xhV1BsUQs_FsE",
    },
    {
      id: "4",
      category: "photoshoot",
      cateType: "photoshoot",
      imgSrc: proxima,
      name: "Explainer video for Proxima",
      yt: "https://www.youtube.com/embed/4g65vMuVf2Q?si=G59pWcPclNpDEXZN",
    },
    {
      id: "5",
      category: "photoshoot",
      cateType: "photoshoot",
      imgSrc: netsmartz,
      name: "Explainer video for Proxima",
      yt: "https://www.youtube.com/embed/_0Ifipi1nTQ?si=kXCaxobs7r_ujBVb",
    },
    {
      id: "6",
      category: "Corporate Videos",
      cateType: "Corporate Videos",
      imgSrc: entropikOld,
      name: "Corporate Video for Entropik",
      yt: "https://www.youtube.com/embed/BLHUYNRv6LY?si=LmhLdgC2qSg21W1m",
    },
    {
      category: "Corporate Videos",
      cateType: "Corporate Videos",
      imgSrc: ZeroBOld,
      name: "DVC for Zero B",
      id: "7",
      yt: "https://www.youtube.com/embed/4mWLLwyyRvQ?si=X8QpJYplicyWay8o",
    },
    {
      category: "animation",
      id: "8",
      cateType: "3d animation",
      imgSrc: zekyOld,
      name: "Showreel for ZVKY",
      yt: "https://www.youtube.com/embed/PZ10HlScXwM?si=3RW9wQy6dibCM130",
    },
    {
      id: "9",
      category: "photoshoot",
      cateType: "photoshoot",
      imgSrc: roadSafetyOld,
      name: "2D animation for Total Oil",
      yt: "https://www.youtube.com/embed/Y1Ke4XbKKio?si=TvQRbCQlaLc4QUMJ",
    },
    {
      id: "10",
      category: "photoshoot",
      cateType: "photoshoot",
      imgSrc: proximaOld,
      name: "Explainer video for Proxima",
      yt: "https://www.youtube.com/embed/PgzmQ8Nlils?si=RNJxlEqJvW8UlXsX",
    },
    {
      id: "11",
      category: "photoshoot",
      cateType: "photoshoot",
      imgSrc: netsmartzOld,
      name: "Explainer video for Proxima",
      yt: "https://www.youtube.com/embed/WP671rxYjTo?si=lbRYtOuR8l7tKZYI",
    },
  ];

  // const videoPopupVariant = {
  //   open: { opacity: 1, y: 0 },
  //   close: { opacity: 0, y: 100 },
  // };
  return (
    <>
      <div className="commonSection our-projects-section">
        <div className="container">
          <div
            className="text-center"
            style={{
              paddingBottom: "0 !important",
            }}
          >
            <h2 className="sec_title">WORK SHOWCASE</h2>
            <p>
              In this video portfolio, you'll find some of the best corporate
              videos we've created over the past couple of years.
              <br />
              Our secret recipe? Passion, a brand-oriented focus, and
              out-of-the-box thinking! Give us a try and see for yourself!
            </p>

            <div className="video-swiper">
              <Swiper
                loop={true}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                spaceBetween={50}
                pagination={false}
                modules={[EffectCoverflow, Pagination]}
                className="work-showcase-swiper"
              >
                <div className="side-btns">
                  <SwiperButtonPrev>
                    <div className="btn-left">
                      <GrPrevious />
                    </div>
                  </SwiperButtonPrev>
                  <SwiperButtonNext>
                    <div className="btn-right">
                      <GrNext />
                    </div>
                  </SwiperButtonNext>
                </div>
                {/* <div
                  style={{
                    padding: "0 30px",
                  }}
                > */}
                {videoObj.map((video, ind) => (
                  <SwiperSlide key={ind}>
                    <div className="video-box">
                      <img src={video.imgSrc} alt={video.name} />
                      <div
                        className="video-play-name-btn"
                        onClick={(e) => {
                          // setVideoSrc(video.yt);
                          // handlePopup();

                          toggleVideoPopup({
                            videoSrc: video.yt,
                            param: e,
                          });
                        }}
                      >
                        <IoPlayOutline />
                        {/* <p>{video.name}</p> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                {/* </div> */}
              </Swiper>
            </div>
          </div>
        </div>
        {/* <div className="videos-container commonSection">
          {videoObj.map((video, ind) => (
            <div
              key={ind}
              className="video-box"
              onClick={() => {
                setVideoSrc(video.yt);
                handlePopup();
              }}
            >
              <img src={video.imgSrc} alt={video.name} />
              <div className="video-play-name-btn">
                <FaRegCirclePlay />
                <p>{video.name}</p>
              </div>
            </div>
          ))}
        </div> */}
      </div>

      {/* <AnimatePresence>
        {showPopup && (
          <motion.div
            className="popup-container"
            variants={videoPopupVariant}
            animate="open"
            exit="close"
            initial="close"
          >
            <div className="pop-content">
              <i className="close-btn" onClick={() => setShowPopup(false)}>
                <IoCloseSharp />
              </i>
              <iframe
                width="800"
                height="500"
                src={videoSrc}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </motion.div>
        )}
      </AnimatePresence> */}

      <VideoPopup />
    </>
  );
};

export default Section5;
