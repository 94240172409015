import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles/404.css"; // You can create a CSS file for your 404 page styles
import { AppUseContext } from "./AppContext";
import LogoImg from "../assets-new/images/logo (1).svg";

const PageNotFound = () => {
  const { setPageErr } = AppUseContext();
  useEffect(() => {
    setPageErr(true);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <img src={LogoImg} alt="logo" />
        <div className="title_404">
          <div>404</div>
          Oops! Page not found!
        </div>
        <div className="content_404">
          Either Something Get Wrong or the Page Doesn't Exist Anymore.
        </div>
        {/* <p></p> */}
        <Link to="/" className="guide-btn">
          Take me home
        </Link>
        <ul className="social">
          {/* <!--<li><a className="twitter" target="_blank" href="https://twitter.com/FutureadyMedia" title="Twitter"> -->
	            	<!--Twitter</a></li>--> */}
          <li>
            <a
              className="facebook"
              target="_blank"
              href="https://www.facebook.com/orangevideosofficial"
              title="Facebook"
              rel="noreferrer"
            >
              {" "}
              Facebook
            </a>
          </li>
          <li>
            <a
              className="instagram"
              target="_blank"
              href="https://www.instagram.com/orange_videos/"
              title="Instagram"
              rel="noreferrer"
            >
              {" "}
              Instagram
            </a>
          </li>
          <li>
            <a
              className="youtube"
              target="_blank"
              href="https://www.youtube.com/channel/UCTfxtQyIJyvlpwKrvLsRj7w/videos"
              title="Youtube"
              rel="noreferrer"
            >
              {" "}
              YouTube
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PageNotFound;
