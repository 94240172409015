import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// import { AnimatePresence, motion } from "framer-motion";
import PageNotFound from "./PageNotFound";
import Home from "./Home/Home";
import OrangeStudio from "./Studio/OrangeStudio";
import AboutPage from "./About/AboutPage";
import PortfolioPage from "./Portfolio/PortfolioPage";
import CareersPage from "./careers/CareersPage";
// import AccountManager from "./careers/JobInfoComponents/AccountManager";
import VideoEditing from "./careers/JobInfoComponents/VideoEditing";
import ApplyForm from "./careers/ApplyForm/ApplyForm";
import Videographers from "./careers/JobInfoComponents/Videographers";
// import Contact from "./Contact";
import ServicePage from "./Services/ServicePage";
import ClientsPage from "./clients/ClientsPage";
import InsightsPage from "./insights/InsightsPage";
import ImportanceOfRacce from "./insights/ImportanceOfRacce";
import VoiceOverAndItsRoleInVideos from "./insights/VoiceOverAndItsRoleInVideos";
import ImportanceOfProductionDesign from "./insights/ImportanceOfProductionDesign";
import TypesOfShotsInLiveShootVideos from "./insights/TypesOfShotsInLiveShootVideos";
import AvBreakdownAProcessNeverToSkip from "./insights/AvBreakdownAProcessNeverToSkip";
import SymboADigitalAdFilm from "./insights/SymboADigitalAdFilm";
import OurCorporateVideoProductionWorkflow from "./insights/OurCorporateVideoProductionWorkflow";
import HowMuchDoesACorporateVideoCost from "./insights/HowMuchDoesACorporateVideoCost";
import FourDsOfVideoProduction from "./insights/4dsOfVideoProduction";
import FiveFactorsThatAffectThePriceOfYourVideo from "./insights/5FactorsThatAffectThePriceOfYourVideo";
import TheImportanceOfVideoContentOnSocialMedia from "./insights/TheImportanceOfVideoContentOnSocialMedia";
import ProsAndConsOf2dAnimations from "./insights/ProsAndConsOf_2dAnimations";
import BenefitsOfUsingStockFootageInVideos from "./insights/BenefitsOfUsingStockFootageInVideos";
import TheImportanceOfStoryboarding from "./insights/TheImportanceOfStoryboarding";
import LightsCameraAction from "./insights/LightsCameraAction";
import StartingAfreshWithANewOffice from "./insights/StartingAfreshWithANewOffice";
import VideoStrategy from "./Services/VideoStrategy";
import ProfessionalCorporateFilmVideoProductionAgencyMumbai from "./Services/ProfessionalCorporateFilmVideoProductionAgencyMumbai";
import ProductVideoMakersMumbai from "./Services/ProductVideoMakersMumbai";
import AerialVideographyPhotography from "./Services/AerialVideographyPhotography";
import TwoThreeAnimationVideoProductionAgencyMumbai from "./Services/2d_3dAnimationVideoProductionAgencyMumbai";
import ExplainerVideoProductionCompanyMumbai from "./Services/ExplainerVideoProductionCompanyMumbai";
import DigitalAdFilmMakingAgencyMumbai from "./Services/DigitalAdFilmMakingAgencyMumbai";
import CorporatePhotoshootsAgencyMumbai from "./Services/CorporatePhotoshootsAgencyMumbai";
import HomeDemo from "./Home/HomeDemo";
// import LandingPage from "./LandingPages/LandingPage";
import FormThankyouPage from "./FormThankyouPage";
import ReimaginingVideosCuratingContentThatMeetsEveryAudiencesNeed from "./insights/ReimaginingVideosCuratingContentThatMeetsEveryAudiencesNeed";
import BridgingTheKnowledgeGapHowToConvertClientBriefsIntoCompellingVideoScripts from "./insights/BridgingTheKnowledgeGapHowToConvertClientBriefsIntoCompellingVideoScripts";
// import CoroporateLandingPages from "./LandingPages/CoroporateLandingPages";
import LpThankYouPage from "./LandingPages/LpThankYouPage";
import DocCheckView from "./DocCheckView";
import { useNavigate } from "react-router-dom";
import ContactTest from "./ContactTest";
import TestLandingPage from "./LandingPages/TestLandingPage";
// import TestCoroporateLandingPages from "./LandingPages/TestCorporateLandingPage";
import EcommercePhotographyLandingPage from "./LandingPages/EcommercePhotographyLandingPage";
import SmartphoneFilmmakingHacks from "./insights/SmartphoneFilmmakingHacks";
import HeartwarmingFathersDayBrandCampaigns from "./insights/HeartwarmingFathersDayBrandCampaigns";
import DigitalAdsLandingPage from "./LandingPages/DigitalAdsLandingPage";
import PowerOfCustomerTestimonialVideos from "./insights/PowerOfCustomerTestimonialVideos";
import Podcast from "./Podccast/Podcast";
import PodcastUat from "./Podcast-UAT/Podcast";
import TestHubspotForm from "./TestHubspotForm";
import TypesofCorporateVideosThatEveryBusinessShouldConsider from "./insights/TypesofCorporateVideosThatEveryBusinessShouldConsider";
import TheJoyandChallengesofMakingASchoolVideo from "./insights/TheJoyandChallengesofMakingASchoolVideo";
// import Corporate from "./Corporate-UAT/Corporate";
// import DemoEcommerceLp from "./LandingPages/DemoEcommerceLp";
// import DemoIosHome from "./Home/DemoIosHome";

// meta tage left from 105

// const PageTransition = ({ children }) => {
//   const location = useLocation();

// const pageTransitionVariants = {
//   initial: {
//     opacity: 0,
//     position: "relative",
//     left: location.action === "POP" ? "0%" : "100%",
//     transition: {
//       ease: "easeInOut",
//       duration: 1,
//     },
//   },
//   animate: {
//     opacity: 1,
//     left: "0%",
//     transition: {
//       ease: "easeInOut",
//       duration: 1,
//     },
//   },
//   exit: {
//     opacity: 0,
//     position: "relative",
//     left: location.action === "POP" ? "0%" : "-100%",
//     transition: {
//       ease: "easeInOut",
//       duration: 0.5,
//     },
//   },
// };

// const pageTransitionVariants = {
//   initial: {
//     opacity: 0,
//     scale: 0.8,
//   },
//   animate: {
//     opacity: 1,
//     scale: 1,
//     transition: {
//       ease: "easeInOut",
//       duration: 0.8,
//     },
//   },
//   exit: {
//     opacity: 0,
//     scale: 0.8,
//     transition: {
//       ease: "easeInOut",
//       duration: 0.5,
//     },
//   },
// };

// const fadeInVariant = {
//   initial: { opacity: 0 },
//   animate: { opacity: 1, transition: { duration: 0.5 } },
//   exit: { opacity: 0 },
// };

// return (
//   <AnimatePresence>
//     <motion.div
//       key={location.pathname}
// initial="initial"
// animate="animate"
// exit="exit"
// variants={pageTransitionVariants}
// style={{
//   position: "relative",
//   minHeight: "100vh",
//   background: "#fff",
//   overflow: "hidden",
// }}
//       >
//         <motion.div
//           variants={fadeInVariant}
//           style={{ width: "100%", height: "100%" }}
//         >
//           {children}
//         </motion.div>
//       </motion.div>
//     </AnimatePresence>
//   );
// };

const routes = [
  { path: "/", element: <Home /> },
  // { path: "/ecommerce-test", element: <DemoEcommerceLp /> },
  { path: "/about-us", element: <AboutPage /> },
  { path: "/video-services", element: <ServicePage /> },
  { path: "/portfolio", element: <PortfolioPage /> },
  { path: "/careers", element: <CareersPage /> },
  // { path: "/account-manager", element: <AccountManager /> },
  { path: "/video-editing", element: <VideoEditing /> },
  { path: "/videographers", element: <Videographers /> },
  // { path: "/account-manager-apply", element: <ApplyForm /> },
  { path: "/video-editing-apply", element: <ApplyForm /> },
  { path: "/videographers-apply", element: <ApplyForm /> },
  // { path: "/contact", element: <Contact /> },
  { path: "/clients", element: <ClientsPage /> },
  { path: "/insights", element: <InsightsPage /> },
  { path: "/studio", element: <OrangeStudio /> },
  {
    path: "/smartphone-filmmaking-hacks-shoot-pro-quality-videos-with-just-your-phone",
    element: <SmartphoneFilmmakingHacks />,
  },
  {
    path: "/power-of-customer-testimonial-videos",
    element: <PowerOfCustomerTestimonialVideos />,
  },
  {
    path: "/types-of-corporate-videos-that-every-business-should-consider",
    element: <TypesofCorporateVideosThatEveryBusinessShouldConsider />
  },
  {
    path: "/the-joy-and-challenges-of-making-a-school-video",
    element: <TheJoyandChallengesofMakingASchoolVideo />
  },
  {
    path: "/5-heartwarming-fathers-day-brand-campaigns",
    element: <HeartwarmingFathersDayBrandCampaigns />,
  },
  {
    path: "/curating-content-that-meets-every-audiences-need",
    element: <ReimaginingVideosCuratingContentThatMeetsEveryAudiencesNeed />,
  },
  {
    path: "/how-to-convert-client-briefs-into-compelling-video-scripts",
    element: (
      <BridgingTheKnowledgeGapHowToConvertClientBriefsIntoCompellingVideoScripts />
    ),
  },
  { path: "/importance-of-recce", element: <ImportanceOfRacce /> },
  {
    path: "/voice-over-and-its-role-in-videos",
    element: <VoiceOverAndItsRoleInVideos />,
  },
  {
    path: "/importance-of-production-design",
    element: <ImportanceOfProductionDesign />,
  },
  {
    path: "/types-of-shots-in-live-shoot-videos",
    element: <TypesOfShotsInLiveShootVideos />,
  },
  {
    path: "/av-breakdown-a-process-never-to-skip",
    element: <AvBreakdownAProcessNeverToSkip />,
  },
  { path: "/symbo-a-digital-ad-film", element: <SymboADigitalAdFilm /> },
  {
    path: "/Our-Corporate-Video-Production-Workflow",
    element: <OurCorporateVideoProductionWorkflow />,
  },
  {
    path: "/How-Much-Does-A-Corporate-Video-Cost",
    element: <HowMuchDoesACorporateVideoCost />,
  },
  { path: "/4ds-of-video-production", element: <FourDsOfVideoProduction /> },
  {
    path: "/5-factors-that-affect-the-price-of-your-video",
    element: <FiveFactorsThatAffectThePriceOfYourVideo />,
  },
  {
    path: "/the-importance-of-video-content-on-social-media",
    element: <TheImportanceOfVideoContentOnSocialMedia />,
  },
  {
    path: "/pros-and-cons-of-2d-animations",
    element: <ProsAndConsOf2dAnimations />,
  },
  {
    path: "/benefits-of-using-stock-footage-in-videos",
    element: <BenefitsOfUsingStockFootageInVideos />,
  },
  {
    path: "/the-importance-of-storyboarding",
    element: <TheImportanceOfStoryboarding />,
  },
  { path: "/lights-camera-action", element: <LightsCameraAction /> },
  { path: "/new-office", element: <StartingAfreshWithANewOffice /> },
  { path: "/video-strategy", element: <VideoStrategy /> },
  {
    path: "/professional-corporate-film-video-production-agency-mumbai",
    element: <ProfessionalCorporateFilmVideoProductionAgencyMumbai />,
  },
  {
    path: "/product-video-makers-mumbai",
    element: <ProductVideoMakersMumbai />,
  },
  {
    path: "/aerial-videography-photography",
    element: <AerialVideographyPhotography />,
  },
  {
    path: "/2d-3d-animation-video-production-agency-mumbai",
    element: <TwoThreeAnimationVideoProductionAgencyMumbai />,
  },
  {
    path: "/explainer-video-production-company-mumbai",
    element: <ExplainerVideoProductionCompanyMumbai />,
  },
  {
    path: "/digital-ad-film-making-agency-mumbai",
    element: <DigitalAdFilmMakingAgencyMumbai />,
  },
  {
    path: "/corporate-photoshoots-agency-mumbai",
    element: <CorporatePhotoshootsAgencyMumbai />,
  },
  // {
  //   path: "/animation-explainer-video-pune",
  //   element: <LandingPage />,
  // },
  // {
  //   path: "/animation-explainer-video-bangalore",
  //   element: <LandingPage />,
  // },
  // {
  //   path: "/animation-explainer-video-mumbai",
  //   element: <LandingPage />,
  // },
  // {
  //   path: "/corporate-video-production-bangalore",
  //   element: <CoroporateLandingPages />,
  // },
  // {
  //   path: "/corporate-video-production-mumbai",
  //   element: <CoroporateLandingPages />,
  // },
  {
    path: "/podcast-mumbai/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/podcast-uat-react",
    element: <PodcastUat />,
  },
  {
    path: "/podcast-bangalore/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/podcast-production-agency-bangalore/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/podcast-production-agency-pune/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/podcast-production-agency-mumbai/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/corporate-video-production-bangalore/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/corporate-video-production-mumbai/thank-you",
    element: <LpThankYouPage />,
  },
  // {
  //   path: "/corporate-video-production-mumbai/thank-you",
  //   element: <LpThankYouPage />,
  // },
  // {
  //   path: "/corporate-video-production-bangalore/thank-you",
  //   element: <LpThankYouPage />,
  // },
  {
    path: "/corporate-video-production-pune/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/animation-explainer-video-bangalore/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/animation-explainer-video-mumbai/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/animation-explainer-video-pune/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/ecommerce-mumbai/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/ecommerce-bangalore/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/digital-ad-films-bangalore/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/digital-ad-films-mumbai/thank-you",
    element: <LpThankYouPage />,
  },
  {
    path: "/digital-ad-films-pune/thank-you",
    element: <LpThankYouPage />,
  },
  // {
  //   path: "/corporate-video-production-pune",
  //   element: <CoroporateLandingPages />,
  // },
  { path: "/test", element: <HomeDemo /> },
  { path: "/thank-you", element: <FormThankyouPage /> },
  { path: "/contact", element: <ContactTest /> },
  {
    path: "/animation-explainer-video-mumbai-uat",
    element: <TestLandingPage />,
  },
  // {
  //   path: "/animation-explainer-video-bangalore",
  //   element: <TestLandingPage />,
  // },
  // {
  //   path: "/animation-explainer-video-pune",
  //   element: <TestLandingPage />,
  // },
  {
    path: "/ecommerce-mumbai",
    element: <EcommercePhotographyLandingPage />,
  },
  {
    path: "/ecommerce-bangalore",
    element: <EcommercePhotographyLandingPage />,
  },
  // {
  //   path: "/corporate-video-production-bangalore",
  //   element: <TestCoroporateLandingPages />,
  // },
  // {
  //   path: "/corporate-video-production-mumbai",
  //   element: <TestCoroporateLandingPages />,
  // },
  {
    path: "/test-ecommerce-bangalore-form",
    element: <TestHubspotForm />,
  },
  {
    path: "/digital-ads-bangalore",
    element: <DigitalAdsLandingPage />,
  },
  {
    path: "/digital-ads-mumbai",
    element: <DigitalAdsLandingPage />,
  },
  // {
  //   path: "/corporate-video-production-pune",
  //   element: <TestCoroporateLandingPages />,
  // },
  // {
  //   path: "/corporate-video-production-uat",
  //   element: <Corporate />,
  // },
  // { path: "/demo-ios-show", element: <DemoIosHome /> },
  { path: "*", element: <PageNotFound /> },
  {
    path: "/podcast-mumbai",
    element: <Podcast />
  },
  {
    path: "/podcast-bangalore",
    element: <Podcast />
  },
];


const AnimatedRoutes = () => {
  const navigate = useNavigate();
  const pathname = useLocation();
  // useEffect(() => {
  //   if (pathname.pathname === "/podcast-uat/") {
  //     window.location.href = "/podcast-uat/index.html"
  //   }
  //   console.log("podcast detected")
  // }, [pathname.pathname])
  useEffect(() => {
    const url = window.location.pathname;

    if (url.startsWith("//")) {
      const changedUrlPath = url.replace("//", "/");
      navigate(changedUrlPath);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pathname.pathname === "/podcast-production-agency-bangalore/" || pathname.pathname === "/podcast-production-agency-bangalore") {
      window.location.href = "/podcast-production-agency-bangalore.html"
    }
    else if (pathname.pathname === "/podcast-production-agency-pune/" || pathname.pathname === "/podcast-production-agency-pune") {
      window.location.href = "/podcast-production-agency-pune.html"
    }
    else if (pathname.pathname === "/podcast-production-agency-mumbai/" || pathname.pathname === "/podcast-production-agency-mumbai") {
      window.location.href = "/podcast-production-agency-mumbai.html"
    }
    else if (pathname.pathname === "/corporate-video-production-mumbai/" || pathname.pathname === "/corporate-video-production-mumbai") {
      window.location.href = "/corporate-video-production-mumbai.html"
    }
    else if (pathname.pathname === "/corporate-video-production-bangalore/" || pathname.pathname === "/corporate-video-production-bangalore") {
      window.location.href = "/corporate-video-production-bangalore.html"
    }
    else if (pathname.pathname === "/corporate-video-production-pune/" || pathname.pathname === "/corporate-video-production-pune") {
      window.location.href = "/corporate-video-production-pune.html"
    }
    else if (pathname.pathname === "/animation-explainer-video-pune/" || pathname.pathname === "/animation-explainer-video-pune") {
      window.location.href = "/animation-explainer-video-pune.html"
    }
    // else if (pathname.pathname === "/animation-explainer-video-mumbai/" || pathname.pathname === "/animation-explainer-video-mumbai") {
    //   window.location.href = "/animation-explainer-video-mumbai.html"
    // }
    else if (pathname.pathname === "/animation-explainer-video-bangalore/" || pathname.pathname === "/animation-explainer-video-bangalore") {
      window.location.href = "/animation-explainer-video-bangalore.html"
    }
  }, [pathname])
  return (
    <>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="uploads/careers/:id" element={<DocCheckView />} />
        {/* <Route path="//uploads/careers/:id" element={<DocCheckView />} /> */}
      </Routes>
    </>
  );
};

export default AnimatedRoutes;
