import React from "react";
import BannerVideo from "../../assets-new/videos/ov-banner_compressed.mp4";
import "../styles/main.scss";
import { Link } from "react-router-dom";
import vidPoster from "../../assets-new/images/homepage-banner-video-poster.jpg";

const HeadingTestSection = () => {
  return (
    <section className="home-heading-container">
      <div className="banner-section">
        <div className="heading-section-right banner_content">
          <h4>Lights, Camera, Story: Crafting Cinematic Magic</h4>
          {/* <h4>Welcome to Orange Videos.</h4> */}
          <h2 style={{ color: "#ff950d" }}>
            {/* corporate
            <br />
            video
            <br />
            production */}
            {/* CREATIVE VIDEO PRODUCTION WITH STRATEGIC MARKETING THINKING. */}
            Welcome to <br /> Orange Videos
          </h2>
          <p>
            We’re a video production agency helping businesses and brands turn
            their vision into reality through dynamic video content.
          </p>
          <Link className="common_btn red_bg" to="/contact">
            <span
              style={{
                position: "relative",
              }}
            >
              Enquire Now
            </span>
          </Link>
        </div>
        <div className="heading-section-left">
          <video
            src={BannerVideo}
            autoPlay
            muted
            loop
            // id="myVideo"
            preload="auto"
            poster={vidPoster}
            width="100%"
          >
            <source src={BannerVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </section>
  );
};

export default HeadingTestSection;
