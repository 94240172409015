import React from "react"
// import aerial4 from "../../assets-new/images/WEBP/dron/d-4.jpg";
import img1 from "../../assets-new/images/podcast-page-assets/our-approach-section/our-approach-img-1.gif"
import img2 from "../../assets-new/images/podcast-page-assets/our-approach-section/new-img/img-2-v1.jpg"
import img3 from "../../assets-new/images/podcast-page-assets/our-approach-section/new-img/img-3-v1.jpg"
import img4 from "../../assets-new/images/podcast-page-assets/our-approach-section/new-img/img-4-v1.jpg"
// import img5 from "../../assets-new/images/podcast-page-assets/our-approach-section/our-approach-img-5.jpg"
// import img3setup1 from "../../assets-new/images/podcast-page-assets/our-approach-section/our-approach-img3-setup-1.jpg"
// import img3setup2 from "../../assets-new/images/podcast-page-assets/our-approach-section/our-approach-img3-setup-2.jpg"
// import img3setup3 from "../../assets-new/images/podcast-page-assets/our-approach-section/our-approach-img3-setup-3.jpg"
// import img3setup4 from "../../assets-new/images/podcast-page-assets/our-approach-section/our-approach-img3-setup-4.jpg"
// import img3setup5 from "../../assets-new/images/podcast-page-assets/our-approach-section/our-approach-img3-setup-5.jpg"
// import img3setup6 from "../../assets-new/images/podcast-page-assets/our-approach-section/our-approach-img3-setup-6.jpg"
// import img3setup7 from "../../assets-new/images/podcast-page-assets/our-approach-section/our-approach-img3-setup-7.jpg"
// import Swiper from "swiper";
import { Swiper } from 'swiper/react';
import { SwiperSlide, useSwiper } from "swiper/react";
// import Slider from "react-slick";
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { GrNext, GrPrevious } from "react-icons/gr";

const MoreOnOurApproach = () => {
    const ContentObj = [
        {
            leftTxt: false,
            imgSrc: img1,
            textContent: {
                heading: "Brand Awareness",
                descriptionTxt: "Podcasting enhances brand recognition by sharing valuable insights, expert interviews, and thought leadership. It positions businesses as industry leaders, building trust and credibility while fostering lasting audience connections.",
            }
        },
        {
            leftTxt: true,
            imgSrc: img2,
            textContent: {
                heading: "Targeted Marketing",
                descriptionTxt: "Podcasts attract niche audiences, delivering personalized messages aligned with their interests.",
                nextDesTxt: "Businesses can boost marketing impact through focused content, sponsored segments, and collaborations with relevant influencers."
            }
        },
        {
            leftTxt: false,
            imgSrc: img3,
            textContent: {
                heading: "Community Building",
                descriptionTxt: "Podcasts create interactive communities through discussions and feedback. Consistent content helps brands foster loyalty, turning listeners into advocates and building meaningful relationships over time.",
            },
            // isCarouselActive: true,
            // carouselObj: [img3setup1, img3setup2, img3setup3, img3setup4, img3setup5, img3setup6, img3setup7]
        },
        {
            leftTxt: true,
            imgSrc: img4,
            textContent: {
                heading: "Business Connections",
                descriptionTxt: "Podcasts offer networking opportunities with industry leaders and partners. Featuring guests and sharing stories enhances content and strengthens professional networks, opening doors for future collaborations and business growth.",
            }
        },
        // {
        //     leftTxt: false,
        //     imgSrc: img5,
        //     textContent: {
        //         heading: "Post-production",
        //         descriptionTxt: "After recording, our editors will polish your podcast by removing pauses, silences, or extraneous sounds. As one of the best podcast production companies in Mumbai, we use equalization, compression, and noise reduction to enhance the audio and make it more listenable and interesting. ",
        //     },
        //     subTextContent: {
        //         heading: "Integrating remotely captured interviews",
        //         descriptionTxt: "Our expert editors ensure various video formats merge seamlessly without disturbing the flow of the podcast."
        //     }
        // },
    ]

    const SwiperButtonNext = ({ children }) => {
        const swiper = useSwiper();
        return <button onClick={() => swiper.slideNext()}>{children}</button>;
    };

    const SwiperButtonPrev = ({ children }) => {
        const swiper = useSwiper();
        return <button onClick={() => swiper.slidePrev()}>{children}</button>;
    };

    return (
        <div className="commonSection more-on-our-approach">
            <div className="container">
                <h3 className="sec_title text-center">Why Podcasting?</h3>
                <br />
                {
                    ContentObj.map((data, ind) => (
                        <React.Fragment key={ind}>
                            {
                                data.leftTxt
                                    ?
                                    <div className="row d-flex align-items-center">
                                        <div className="col-lg-6 col-md-8 col-sm-12">
                                            <h3>
                                                {
                                                    data.textContent.heading
                                                }
                                            </h3>
                                            <p>
                                                {data.textContent.descriptionTxt}
                                            </p>
                                            <p>
                                                {
                                                    data.textContent.nextDesTxt
                                                }
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-12">
                                            <img src={data.imgSrc} alt="left" width="100%" />
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                    :
                                    <div className="row d-flex align-items-center">
                                        <div className="col-lg-6 col-md-4 col-sm-12">
                                            {
                                                data.isCarouselActive
                                                &&
                                                <>
                                                    <Swiper grabCursor={true}
                                                        centeredSlides={true}
                                                        slidesPerView={"auto"}>
                                                        <div className="side-btns left-btn">
                                                            <SwiperButtonPrev>
                                                                <div className="btn-left">
                                                                    <GrPrevious />
                                                                </div>
                                                            </SwiperButtonPrev>
                                                        </div>
                                                        <div className="side-btns right-btn">
                                                            <SwiperButtonNext>
                                                                <div className="btn-right">
                                                                    <GrNext />
                                                                </div>
                                                            </SwiperButtonNext>
                                                        </div>
                                                        {
                                                            data.carouselObj.map((items, ind) => (
                                                                <SwiperSlide key={ind}>
                                                                    <img src={items} alt="sliper imgs" />
                                                                </SwiperSlide>
                                                            ))
                                                        }
                                                    </Swiper>
                                                </>
                                            }
                                            {
                                                data.imgSrc
                                                &&
                                                <img src={data.imgSrc} alt="left" width="100%" />
                                            }
                                            <br />
                                            <br />
                                        </div>
                                        <div className="col-lg-6 col-md-8 col-sm-12">
                                            <h3>
                                                {
                                                    data.textContent.heading
                                                }
                                            </h3>
                                            <p>
                                                {data.textContent.descriptionTxt}
                                            </p>
                                            {
                                                data.subTextContent
                                                &&
                                                <ul>
                                                    <li>
                                                        {data.subTextContent.heading}: {data.subTextContent.descriptionTxt}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                            }
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    )
}

export default MoreOnOurApproach