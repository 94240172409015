import React, { useEffect } from "react";
import "../styles/about.css";
import { Link, useLocation } from "react-router-dom";
import AboutTeams from "./AboutTeams";
import { motion } from "framer-motion";
import MetaData from "../MetaData";
import whyOv1 from "../../assets-new/images/about/aboutus-530x464.jpg";
import whyOv2 from "../../assets-new/images/about/about-us-820x444.jpg";
import proHouseImg from "../../assets-new/images/about/about-us-showreel.jpg";
// import { IoClose } from "react-icons/io5";
import VideoPopup from "../VideoPopup";
import { AppUseContext } from "../AppContext";

const AboutPage = () => {
  // const [showPopup, setShowPopup] = useState(false);

  const { toggleVideoPopup } = AppUseContext();

  // const handleVideoPopup = () => {
  //   setShowPopup(showPopup ? false : true);
  // };

  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // useEffect(() => {
  //   document.body.style = showPopup ? "overflow: hidden" : "overflow: auto";
  // }, [showPopup]);
  return (
    <>
      <MetaData
        pageTitle="About Us | Animated Video Production Agency Mumbai - Orange Videos"
        metaDdescription="We are a Animated Video Production Agency Mumbai helping businesses and brands achieve their goals with video. Elevate your brand with our fully customized video production services"
      />
      {/* section 1 */}
      <section
        // initial="hide"
        // whileInView="view"
        // viewport={{ once: true }}
        // variants={{
        //   view: { opacity: 1, y: 0 },
        //   hide: { opacity: 0, y: 100 },
        // }}
        // transition={{ duration: 1 }}
        className="pageBanner about-us-banner AboutPage"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content text-center">
                <h4>
                  <Link to="/">home</Link> - about
                </h4>
                <h1>about us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 2 */}
      <motion.section
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
        className="commonSection"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-md-6">
              <h4 className="sub_title red_color">get to know us</h4>
              <h2 className="sec_title">
                we know you’re
                <br />
                curious why orange videos?
              </h2>
            </div>
            <div className="col-lg-6 col-sm-6 col-md-6">
              <div className="agency_img1">
                <img src={whyOv1} alt="" />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="agency_img2">
                <img src={whyOv2} alt="" />
              </div>
              <div className="compay_date">
                <h5>established</h5>
                <h2>2016</h2>
              </div>
            </div>
          </div>
        </div>
      </motion.section>

      {/* section 3 */}
      <motion.section
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
        className="commonSection team featured"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="sub_title red_color">meet the team</h4>
              <h2 className="sec_title">video experts</h2>
              <p className="sec_desc">
                We are committed to providing our customers with exceptional
                service while
                <br />
                offering our employees the best training.
              </p>
            </div>
          </div>
        </div>
        <AboutTeams />
      </motion.section>

      {/* section 4 */}
      <motion.section
        initial="hide"
        whileInView="view"
        viewport={{ once: true }}
        variants={{
          view: { opacity: 1, y: 0 },
          hide: { opacity: 0, y: 100 },
        }}
        transition={{ duration: 1 }}
        className="commonSection our_work"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="videoWrap_2">
          <img src={proHouseImg} alt="" />
          <div className="play_video">
            {/* <!--<h4 className="sub_title mb-3" >Over showreel</h4>--> */}
            <div className="dv-popup-btn">
              <a
                className="video_popup radius-btn"
                href="/"
                onClick={(e) => {
                  // e.preventDefault();
                  // handleVideoPopup();
                  toggleVideoPopup({
                    videoSrc:
                      "https://www.youtube.com/embed/JGV05ck7U0g?si=km9l5m9js8Bafxkg",
                    param: e,
                  });
                }}
              >
                <i className="fa fa-play"></i>
              </a>
            </div>

            <h2>Watch Reel</h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6"></div>
            <div className="col-lg-6 col-sm-6">
              <div className="ab_detail_wrap">
                <h4 className="sub_title red_color">our approach</h4>
                <h2 className="sec_title">
                  big production house capabilities, boutique studio advantages
                </h2>
                <p className="sec_desc">
                  We meet{" "}
                  <span style={{ color: "#ff950d" }}>
                    <b>DEADLINES</b>
                  </span>
                  .<br />
                  We exceed{" "}
                  <span style={{ color: "#ff950d" }}>
                    <b>EXPECTATIONS</b>
                  </span>
                  .<br />
                  We keep people happy by doing the{" "}
                  <span style={{ color: "#ff950d" }}>
                    <b>LITTLE THINGS RIGHT</b>
                  </span>{" "}
                  and the{" "}
                  <span style={{ color: "#ff950d" }}>
                    <b>BIG THINGS BETTER</b>
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.section>
      {/* below is the yt video popup */}
      {/* {showPopup ? (
        <div className="popup-vid-cotainer">
          <a href="https://www.youtube.com/watch?v=JGV05ck7U0g"></a>
          <div className="dv-popup-vid">
            <iframe
              width="800"
              height="500"
              src="https://www.youtube.com/embed/JGV05ck7U0g?si=km9l5m9js8Bafxkg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <div className="dv-close">
              <IoClose />
              <i
                className="fa-solid fa-xmark close-ico"
                onClick={handleVideoPopup}
              ></i>
            </div>
          </div>
        </div>
      ) : null} */}

      <VideoPopup />
    </>
  );
};

export default AboutPage;
