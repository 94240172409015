import React, { useState, memo } from "react";
import { motion } from "framer-motion";
// import { FaPlus, FaMinus } from "react-icons/fa6";

const Faqs = () => {
    const faqAccordionObj = [
        {
            accName: "What services do you offer under professional podcast production?",
            content:
                "We offer end-to-end podcast production, including concept ideation, scriptwriting, audio & video recording, editing, sound design, hosting and distribution across popular platforms like YouTube, Spotify, Apple Podcasts and Google Podcasts.",
        },
        {
            accName: "Can you help us with remote podcast recording?",
            content:
                "Yes, we facilitate high-quality remote recording using professional tools, ensuring seamless audio even if guests or hosts are in different locations. We can also integrate Zoom or similar platforms.",
        },
        {
            accName: "Do you provide video podcast production?",
            content: "Absolutely! We offer both audio and video podcast production. Our video podcasts include multi-camera setups, lighting, and post-production editing to enhance visual engagement.",
        },
        {
            accName: "How long does it take to produce an episode?",
            content: "Typically, it takes 5-7 working days from recording to final delivery, depending on the complexity. However, for urgent needs, we can expedite the process to meet tight deadlines.",
        },
        {
            accName: "Can you help us develop a podcast strategy?",
            content:
                "Yes, we provide strategic guidance, including content planning, episode themes, audience targeting, and marketing strategies to help grow your podcast audience effectively.",
        },
        {
            accName: "Do you offer podcast marketing and promotion?",
            content:
                "Yes, we assist with social media campaigns, teaser videos, episode snippets, SEO optimization, and collaborations with influencers to ensure your podcast reaches a wider audience.",
        },
        {
            accName: "What kind of equipment do you use for podcast production?",
            content:
                "We use industry-standard microphones, audio interfaces, soundproofing tools and software such as Adobe Audition and Pro Tools to ensure crystal-clear sound quality.",
        },
        {
            accName: "Can you assist with podcast branding and artwork?",
            content:
                "Yes, we provide custom podcast artwork, intros, outros, music and branding that align with your brand identity and resonate with your target audience.",
        },
        {
            accName: "What is your pricing structure for podcast production?",
            content:
                "Our pricing depends on the scope of the project, such as the number of episodes, complexity of editing and additional services like video production. Contact us for a customized quote.",
        },
        {
            accName: "Do you offer podcast hosting services?",
            content:
                "Yes, we provide hosting solutions and help set up your podcast on platforms like Spotify, Apple Podcasts, and Google Podcasts. We also assist with analytics to track your podcast’s performance.",
        },
    ];

    const [toggleAcc, setToggleAcc] = useState(0);
    const handleAccToggle = (index) => {
        setToggleAcc(index);
    };

    const accAnimate = {
        open: {
            opacity: 1,
            height: "100%",
            top: 0,
        },
        close: {
            opacity: 0,
            height: 0,
            top: -10,
        },
    };

    return (
        <div className="commonSection gray-bg" id="faqs">
            <div className="container">
                <h2 className="text-center mb-5 sec_title">FAQs</h2>

                <div className="faq-accordion-component">
                    {faqAccordionObj.map((items, ind) => (
                        <FaqItem
                            key={ind}
                            items={items}
                            ind={ind}
                            toggleAcc={toggleAcc}
                            handleAccToggle={handleAccToggle}
                            accAnimate={accAnimate}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const FaqItem = memo(({ items, ind, toggleAcc, handleAccToggle, accAnimate }) => {
    return (
        <div
            className="faq-container"
            style={{
                transition: "all 0.5s ease-in-out",
            }}
        >
            <div
                className={`faq-acc-title ${ind === toggleAcc && `acc-active`}`}
                onClick={() => handleAccToggle(ind)}
            >
                {items.accName}
                <span>{ind === toggleAcc ? "-" : "+"}</span>
            </div>
            {ind === toggleAcc && (
                <motion.div
                    className="faq-acc-content"
                    animate="open"
                    variants={accAnimate}
                    exit="close"
                    initial="close"
                    transition={{ duration: 1, type: "spring", bounce: 0.25 }}
                    style={{
                        position: "relative",
                        borderBottom: '1px solid #e5e5e5',
                        overflow: "hidden",
                        clear: "both",
                    }}
                >
                    {items.content}
                </motion.div>
            )}
        </div>
    );
});

export default Faqs;
