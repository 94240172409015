import React from "react";
import { motion } from "framer-motion";
import icon1 from "../../assets-new/images/icons/homepage-section-2/video-strategy.png";
import icon2 from "../../assets-new/images/icons/homepage-section-2/production.png";
import icon3 from "../../assets-new/images/icons/homepage-section-2/promotion-distribution.png";

const Section2 = () => {
  const variants = {
    view: { opacity: 1, y: 0 },
    hide: { opacity: 0, y: 100 },
  };
  return (
    <section className="commonSection featured home-custom-section-2 section-2-parallax-container">
      <div className="container">
        {/* <div className="row"> */}
        {/* <div className="col-lg-7 col-sm-12  col-md-7"> */}
        <div className="features_content row justify-content-center align-items-center d-flex">
          {/* <h4 className="sub_title red_color">our approach</h4> */}
          <motion.div
            initial="hide"
            whileInView="view"
            viewport={{ once: true }}
            variants={variants}
            transition={{ duration: 1 }}
            className="col-lg-4 col-md-3 col-sm-12"
          >
            <h2 className="partners-sec sec_title">We are your partners in</h2>
          </motion.div>
          <div className="icons-with-text col-lg-8 col-md-9 col-sm-12">
            <motion.div
              initial="hide"
              whileInView="view"
              viewport={{ once: true }}
              variants={variants}
              transition={{ duration: 1, delay: 0.5 }}
              className="box-content"
            >
              <div className="box-img-container">
                <img src={icon1} alt="Video strategy" />
              </div>
              <h3>Video strategy</h3>
            </motion.div>
            <motion.div
              initial="hide"
              whileInView="view"
              viewport={{ once: true }}
              variants={variants}
              transition={{ duration: 1, delay: 1 }}
              className="box-content"
            >
              <div className="box-img-container">
                <img src={icon2} alt="Production" />
              </div>
              <h3>End to end Video Production</h3>
            </motion.div>
            <motion.div
              initial="hide"
              whileInView="view"
              viewport={{ once: true }}
              variants={variants}
              transition={{ duration: 1, delay: 1.5 }}
              className="box-content"
            >
              <div className="box-img-container">
                <img src={icon3} alt="Promotion & Distribution" />
              </div>
              <h3>Promotion & Distribution</h3>
            </motion.div>
          </div>
          {/* <div className="singleFeature section-2">
            <div className="f_count">01</div>
            <h3>Video strategy</h3>
          </div>
          <div className="singleFeature section-2">
            <div className="f_count">02</div>
            <h3>Production</h3>
          </div>
          <div className="singleFeature section-2">
            <div className="f_count">03</div>
            <h3>Promotion & Distribution</h3>
          </div> */}
        </div>
        {/* </div> */}
        {/* <div className="col-lg-5 col-sm-12 col-md-5 noPaddingRight"> */}
        {/* <div className="features_img">
          <img src="../assets-new/images/WEBP/our-approach.webp" alt="" />
        </div> */}
        {/* </div> */}
      </div>
    </section>
  );
};

export default Section2;
