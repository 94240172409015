import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { storage } from "../Firebase/firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { AppUseContext } from "./AppContext";

const DocCheckView = () => {
  const { id } = useParams();

  const { setPageErr } = AppUseContext();

  const [url, setUrl] = useState("");

  const navigate = useNavigate();

  const loadDocument = async () => {
    const storageRef = ref(storage, `careers/${id}`);
    try {
      const url = await getDownloadURL(storageRef);
      window.location.href = url;
      setUrl(url);
      console.log("Fetched Document URL:", url);
    } catch (error) {
      console.error("Error fetching document:", error);
      navigate("/404");
    }
  };

  // const docs = [
  //   {
  //     uri: "https://firebasestorage.googleapis.com/v0/b/ov-db-72b1c.appspot.com/o/careers%2FPawana_Shetty-4-1.pdf?alt=media&token=04236f49-eca3-4e82-be84-2413ead962eb",
  //   },
  // ];

  useEffect(() => {
    loadDocument();
    setPageErr(true);
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {/* {url && ( */}
      <div className="doc-loader-container">
        <div className="spinner center">
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
        </div>
      </div>
      {url && (
        <>
          {/* <DocViewer
            documents={[
              {
                uri: "https://firebasestorage.googleapis.com/v0/b/ov-db-72b1c.appspot.com/o/careers%2FPawana_Shetty-4-1.pdf?alt=media&token=04236f49-eca3-4e82-be84-2413ead962eb",
              },
            ]}
          />
          <p>Fetched Document URL: {url}</p> */}
        </>
      )}
      {/* // )} */}
      {/* <p>{url}</p> */}
    </>
  );
};

export default DocCheckView;
