import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets-new/images/logo (1).svg";
import WhatsAppImg from "../assets-new/images/whatsapp.png";
import { AppUseContext } from "./AppContext";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";

const Footer = () => {
  const { pageErr } = AppUseContext();
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const { isLandingPageVisible } = AppUseContext();

  // const pathname = useLocation();

  const [changeScrollClassName, setChangeScrollClassName] = useState("");

  // const [isLandingPage, setIsLandingpage] = useState(false);

  // useEffect(() => {
  //   setIsLandingpage(
  //     pathname.pathname === "/animation-explainer-video-pune" ||
  //       pathname.pathname === "/animation-explainer-video-mumbai" ||
  //       pathname.pathname === "/animation-explainer-video-bangalore" ||
  //       pathname.pathname === "/corporate-video-production-mumbai" ||
  //       pathname.pathname === "/corporate-video-production-bangalore" ||
  //       pathname.pathname === "/corporate-video-production-pune"
  //   );
  // }, [pathname]);

  useEffect(() => {
    const winScroll = () => {
      if (document.documentElement.scrollTop > 100) {
        setChangeScrollClassName("showit");
      } else {
        setChangeScrollClassName("");
      }
    };
    window.addEventListener("scroll", winScroll);
  }, []);
  return (
    <>
      {!pageErr && (
        <>
          {!isLandingPageVisible && (
            <>
              <footer className="footer_1">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <aside className="widget aboutwidget">
                        <Link to="/">
                          <img src={Logo} alt="logo" />
                        </Link>
                        <p>
                          {/* We're a video production agency helping businesses and brands
                  achieve their goals with video. Elevate your brand with our
                  fully customized video production services. */}
                          Transform your brand's presence with our expert video
                          production services dedicated to delivering impactful
                          results and crafted to meet your unique needs and
                          goals.
                        </p>
                      </aside>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <aside className="widget social_widget">
                        <h3 className="widget_title">Main</h3>
                        <ul>
                          <li>
                            {/* <a
                      href="https://www.instagram.com/orange_videos/?next=%2F"
                      target="_blank"
                      rel="noreferrer"
                    > */}
                            {/* <i className="fa-brands fa-instagram"></i>
                    // Instagram */}
                            {/* </a> */}
                            <Link to="/">Home</Link>
                          </li>
                          <li>
                            <Link to="/about-us">About</Link>
                            {/* <a
                      href="https://www.facebook.com/orangevideosofficial"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-facebook"></i>
                      // Facebook
                    </a> */}
                          </li>
                          <li>
                            <Link to="/portfolio">Portfolio</Link>
                            {/* <a
                      href="https://twitter.com/Orange_Videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-x-twitter"></i>
                      // Twitter
                    </a> */}
                          </li>
                          <li>
                            <Link to="/careers">Careers</Link>
                            {/* <a
                      href="https://www.youtube.com/channel/UCTfxtQyIJyvlpwKrvLsRj7w/videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-youtube"></i>
                      // YouTube
                    </a> */}
                          </li>
                          <li>
                            <Link to="/insights">Blog</Link>
                            {/* <a
                      href="https://www.linkedin.com/company/13413575/admin/feed/posts/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                      // LinkedIn
                    </a> */}
                          </li>
                        </ul>
                      </aside>
                    </div>

                    <div className="col-sm-6 col-md-3">
                      <aside className="widget social_widget">
                        <h3 className="widget_title">Services</h3>
                        <ul>
                          <li>
                            {/* <a
                      href="https://www.instagram.com/orange_videos/?next=%2F"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-instagram"></i>
                      Instagram
                    </a> */}
                            <Link to="/video-strategy">Video Strategy</Link>
                          </li>
                          <li>
                            {/* <a
                      href="https://www.facebook.com/orangevideosofficial"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-facebook"></i>
                      Facebook
                    </a> */}
                            <Link to="/professional-corporate-film-video-production-agency-mumbai">
                              Corporate Videos
                            </Link>
                          </li>
                          <li>
                            {/* <a
                      href="https://twitter.com/Orange_Videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-x-twitter"></i>
                      Twitter
                    </a> */}
                            <Link to="/product-video-makers-mumbai">
                              Product Videos
                            </Link>
                          </li>
                          <li>
                            {/* <a
                      href="https://www.youtube.com/channel/UCTfxtQyIJyvlpwKrvLsRj7w/videos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-youtube"></i>
                      YouTube
                    </a> */}
                            <Link to="/aerial-videography-photography">
                              Aerial Videography and Photography
                            </Link>
                          </li>
                          <li>
                            {/* <a
                      href="https://www.linkedin.com/company/13413575/admin/feed/posts/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                      LinkedIn
                    </a> */}
                            <Link to="/2d-3d-animation-video-production-agency-mumbai">
                              2D and 3D Animation
                            </Link>
                          </li>
                          <li>
                            <Link to="/explainer-video-production-company-mumbai">
                              Explainer Videos
                            </Link>
                          </li>
                          <li>
                            <Link to="/digital-ad-film-making-agency-mumbai">
                              Digital Ad films
                            </Link>
                          </li>
                          <li>
                            <Link to="/corporate-photoshoots-agency-mumbai">
                              Photoshoots
                            </Link>
                          </li>
                        </ul>
                      </aside>
                    </div>

                    <div className="col-sm-4 col-md-3">
                      <aside className="widget aboutwidget">
                        <h3
                          className="widget_title"
                          style={{
                            marginTop: 8,
                          }}
                        >
                          contact
                        </h3>
                        {/* <!--<p>-->
                            <!--    C-208, Building no.5, Mittal Estate, Andheri-Kurla Road<br>-->
                            <!--    Andheri (E), Mumbai 400059-->
                            <!--</p>-->
                          <!-- <p style="margin-bottom: 20px !important">610, Peninsula Plaza,<br/>Fun Republic Road, Off Veera Desai Road,<br/>Andheri West, Mumbai 400053.</p> --> */}
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "#ff950d",
                          }}
                        >
                          Mumbai
                        </p>
                        <p style={{ marginBottom: "20px !important" }}>
                          Orange Videos Production LLP
                          <br />
                          401-A, Town Center 2, Andheri Kurla Road,
                          Andheri East, Mumbai 400059
                        </p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "#ff950d",
                            marginBbottom: "5px !important",
                          }}
                        >
                          Bangalore
                        </p>
                        <p style={{ marginBottom: "10px !important" }}>
                          Om Chambers, 648/A-9 Binnamangala 1st
                          Stage,Indiranagar, Bangalore – 560038
                        </p>
                        <p>
                          Phone: <a href="tel:98674 09221">98674 09221</a>
                        </p>
                        <p>
                          Email:{" "}
                          <a href="mailto:sales@orangevideos.in">
                            sales@orangevideos.in
                          </a>
                        </p>
                      </aside>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderTop: "1px solid",
                    }}
                  >
                    <div className="col-lg-6 col-sm-12 col-md-6 text-left mobile-center">
                      <div className="copyright">© copyright 2024 </div>
                    </div>
                    <div
                      className="col-lg-6 col-sm-12 col-md-6 text-right mobile-center collective-media-container copyright"
                      style={{
                        justifyContent: "flex-end",
                        padding: 0,
                      }}
                    >
                      <div className="copyright">
                        A PART OF 2020 MEDIA COLLECTIVE
                      </div>
                      <ul>
                        <li>
                          <a
                            href="https://www.instagram.com/orange_videos/?next=%2F"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {/* <i className="fa-brands fa-instagram"></i> */}
                            <FaInstagram />
                            {/* Instagram */}
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/orangevideosofficial"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {/* <i className="fa-brands fa-facebook"></i> */}
                            <FaFacebook />
                            {/* Facebook */}
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/Orange_Videos"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {/* <i className="fa-brands fa-x-twitter"></i> */}
                            <FaXTwitter />
                            {/* Twitter */}
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/channel/UCTfxtQyIJyvlpwKrvLsRj7w/videos"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {/* <i className="fa-brands fa-youtube"></i> */}
                            <FaYoutube />
                            {/* YouTube */}
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/13413575/admin/feed/posts/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {/* <i className="fa-brands fa-linkedin"></i> */}
                            <FaLinkedinIn />
                            {/* LinkedIn */}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
              {/* whatsapp floating img */}
              <div className="floating_whatsapp">
                <a
                  href="https://wa.me/919867409221"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={WhatsAppImg} className="whatsapp_img" alt="" />
                </a>
              </div>

              {/* <Link id="backToTop" to="#" className="showit">
        <i className="fa fa-angle-double-up"></i>
        // 
      </Link> */}

              <span
                id="backToTop"
                to="#"
                className={changeScrollClassName}
                onClick={handleScrollToTop}
              >
                <i className="fa fa-angle-double-up"></i>
                {/*  */}
              </span>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Footer;
