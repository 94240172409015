import React from 'react'
import Logo from "../../assets-new/images/logo (1).svg";
import { MdEmail } from 'react-icons/md';
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

export default function PodcastFooter() {
    return (
        <footer className='footer_1 podcast-footer'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className='widget aboutwidget'>
                            <div className="footer-fst-content">
                                <img src={Logo} alt="logo" />
                            </div>
                            <p>Orange Videos helps businesses & brands tell impactful stories via podcasts. We are experts in creating, growing & launching successful podcasts.</p>
                            <a href="mailto:sales@orangevideos.in"><MdEmail /> sales@orangevideos.in</a>
                            <a href="tel:+91 9867409221"><BsFillTelephoneFill /> +91 9867409221</a>
                            <a href="https://wa.me/919867409221"><IoLogoWhatsapp /> +91 9867409221</a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className='widget aboutwidget'>
                            <div className="footer-fst-content">
                                <h4 className='widget_title'>Mumbai HQ</h4>
                            </div>
                            <p>
                                Orange Videos Production LLP
                                401-A, Town Center 2, Andheri Kurla Road, Andheri East, Mumbai 400059
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className='widget aboutwidget'>
                            <div className="footer-fst-content">
                                <h4 className='widget_title'>Bangalore</h4>
                            </div>
                            <p>Orange Videos Production LLP
                                Om Chambers, 648/A-9 Binnamangala, 1st Stage, Indiranagar, Bangalore 560038
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    className="row"
                    style={{
                        borderTop: "1px solid",
                    }}
                >
                    <div className="col-lg-6 col-sm-12 col-md-6 text-left mobile-center">
                        <div className="copyright">© copyright 2024 </div>
                    </div>
                    <div
                        className="col-lg-6 col-sm-12 col-md-6 text-right mobile-center collective-media-container copyright"
                        style={{
                            justifyContent: "flex-end",
                            padding: 0,
                        }}
                    >
                        <div className="copyright">
                            A PART OF 2020 MEDIA COLLECTIVE
                        </div>
                        <ul>
                            <li>
                                <a
                                    href="https://www.instagram.com/orange_videos/?next=%2F"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {/* <i className="fa-brands fa-instagram"></i> */}
                                    <FaInstagram />
                                    {/* Instagram */}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/orangevideosofficial"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {/* <i className="fa-brands fa-facebook"></i> */}
                                    <FaFacebook />
                                    {/* Facebook */}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/Orange_Videos"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {/* <i className="fa-brands fa-x-twitter"></i> */}
                                    <FaXTwitter />
                                    {/* Twitter */}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/channel/UCTfxtQyIJyvlpwKrvLsRj7w/videos"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {/* <i className="fa-brands fa-youtube"></i> */}
                                    <FaYoutube />
                                    {/* YouTube */}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/13413575/admin/feed/posts/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {/* <i className="fa-brands fa-linkedin"></i> */}
                                    <FaLinkedinIn />
                                    {/* LinkedIn */}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
